/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type { AccountResponse } from './models/AccountResponse';
export { AddressFilterRequest } from './models/AddressFilterRequest';
export type { AddressRequest } from './models/AddressRequest';
export type { AddressResponse } from './models/AddressResponse';
export type { AddressSuggestionResponse } from './models/AddressSuggestionResponse';
export { ApishipDeliveryRequest } from './models/ApishipDeliveryRequest';
export { ApishipDeliveryServiceRequest } from './models/ApishipDeliveryServiceRequest';
export { ApishipDeliveryServicesGroupedByKeyResponse } from './models/ApishipDeliveryServicesGroupedByKeyResponse';
export type { ApishipDeliveryServicesWithMinimalCostDeliveryToDoor } from './models/ApishipDeliveryServicesWithMinimalCostDeliveryToDoor';
export type { ApishipPickUpPointsResponse } from './models/ApishipPickUpPointsResponse';
export type { ApishipRequest } from './models/ApishipRequest';
export type { ApishipResponse } from './models/ApishipResponse';
export type { ApishipShopInfoRequest } from './models/ApishipShopInfoRequest';
export type { ApishipShopInfoResponse } from './models/ApishipShopInfoResponse';
export type { ApishipTariffResponse } from './models/ApishipTariffResponse';
export type { AtolError } from './models/AtolError';
export { AtolInfoRequest } from './models/AtolInfoRequest';
export { AtolInfoResponse } from './models/AtolInfoResponse';
export type { AtolPayloadResult } from './models/AtolPayloadResult';
export type { AtolResultCheck } from './models/AtolResultCheck';
export type { BasketItemRequest } from './models/BasketItemRequest';
export type { BasketResponse } from './models/BasketResponse';
export type { BasketSettingsResponse } from './models/BasketSettingsResponse';
export type { BotTokenRequest } from './models/BotTokenRequest';
export type { CalculateRequest } from './models/CalculateRequest';
export type { CalculateResponse } from './models/CalculateResponse';
export { CategoryImportResponse } from './models/CategoryImportResponse';
export type { CategoryResponse } from './models/CategoryResponse';
export type { CategoryTreeResponse } from './models/CategoryTreeResponse';
export type { CloudPaymentResponse } from './models/CloudPaymentResponse';
export type { ColorResponse } from './models/ColorResponse';
export type { ColorResponseWithAmount } from './models/ColorResponseWithAmount';
export { CurrencyTypeResponse } from './models/CurrencyTypeResponse';
export type { CustomerResponse } from './models/CustomerResponse';
export type { DateFilterRequest } from './models/DateFilterRequest';
export type { DeliveryInfoResponse } from './models/DeliveryInfoResponse';
export { DeliveryOptionRequest } from './models/DeliveryOptionRequest';
export type { DictionaryCodeValueResponse } from './models/DictionaryCodeValueResponse';
export type { DigitalGoodsCreateRequest } from './models/DigitalGoodsCreateRequest';
export type { DigitalGoodsPhotoResponse } from './models/DigitalGoodsPhotoResponse';
export type { DigitalGoodsResponse } from './models/DigitalGoodsResponse';
export type { Error } from './models/Error';
export type { ExceptionMessage } from './models/ExceptionMessage';
export { FileRequest } from './models/FileRequest';
export type { FileResponse } from './models/FileResponse';
export type { FileUploadResponse } from './models/FileUploadResponse';
export type { FlagRequest } from './models/FlagRequest';
export type { MetricResponse } from './models/MetricResponse';
export type { MultiValueMapStringString } from './models/MultiValueMapStringString';
export type { NewPasswordRequest } from './models/NewPasswordRequest';
export type { NewsletterPhotoRequest } from './models/NewsletterPhotoRequest';
export type { NewsletterRequest } from './models/NewsletterRequest';
export type { NewsletterResponse } from './models/NewsletterResponse';
export { NewsletterStatus } from './models/NewsletterStatus';
export { NewsletterUserGroup } from './models/NewsletterUserGroup';
export { NewsletterUserGroupResponse } from './models/NewsletterUserGroupResponse';
export { OfferImportErrorResponse } from './models/OfferImportErrorResponse';
export { OfferImportResponse } from './models/OfferImportResponse';
export { OrderCreatedResponse } from './models/OrderCreatedResponse';
export { OrderingResponse } from './models/OrderingResponse';
export type { OrderItemResponse } from './models/OrderItemResponse';
export { OrderRequest } from './models/OrderRequest';
export { OrderResponse } from './models/OrderResponse';
export { OrderShortInfoResponse } from './models/OrderShortInfoResponse';
export { PaymentCustomDtoRequest } from './models/PaymentCustomDtoRequest';
export { PaymentCustomDtoResponse } from './models/PaymentCustomDtoResponse';
export type { PaymentInfoResponse } from './models/PaymentInfoResponse';
export { PaymentTbankDtoRequest } from './models/PaymentTbankDtoRequest';
export type { PaymentTbankDtoResponse } from './models/PaymentTbankDtoResponse';
export { PaymentTelegramDtoRequest } from './models/PaymentTelegramDtoRequest';
export type { PaymentTelegramDtoResponse } from './models/PaymentTelegramDtoResponse';
export type { PhotoCreateRequest } from './models/PhotoCreateRequest';
export type { PickPointsRequestParam } from './models/PickPointsRequestParam';
export type { PickUpPointRequest } from './models/PickUpPointRequest';
export type { PickUpPointResponse } from './models/PickUpPointResponse';
export { PlanFeatureResponse } from './models/PlanFeatureResponse';
export type { PlanResponse } from './models/PlanResponse';
export type { PositionCreateRequest } from './models/PositionCreateRequest';
export type { PositionResponse } from './models/PositionResponse';
export type { PrivilegeResponse } from './models/PrivilegeResponse';
export type { ProductCreateRequest } from './models/ProductCreateRequest';
export { ProductDimensionsTypeResponse } from './models/ProductDimensionsTypeResponse';
export type { ProductForCatalogResponse } from './models/ProductForCatalogResponse';
export type { ProductForCatalogWebAppResponse } from './models/ProductForCatalogWebAppResponse';
export { ProductImportResponse } from './models/ProductImportResponse';
export { ProductImportShortResponse } from './models/ProductImportShortResponse';
export type { ProductInfoResponse } from './models/ProductInfoResponse';
export type { ProductItemResponse } from './models/ProductItemResponse';
export type { ProductPhotoResponse } from './models/ProductPhotoResponse';
export type { ProductResponse } from './models/ProductResponse';
export { ProductTypeResponse } from './models/ProductTypeResponse';
export { PromocodeRequest } from './models/PromocodeRequest';
export { PromocodeResponse } from './models/PromocodeResponse';
export type { ReceiverRequest } from './models/ReceiverRequest';
export type { ReceiverResponse } from './models/ReceiverResponse';
export type { RecoveryPasswordRequest } from './models/RecoveryPasswordRequest';
export type { ResponseErrorMessage } from './models/ResponseErrorMessage';
export type { RetailInfoRequest } from './models/RetailInfoRequest';
export type { RetailInfoResponse } from './models/RetailInfoResponse';
export type { RoleResponse } from './models/RoleResponse';
export type { SearchRequest } from './models/SearchRequest';
export { ShopCreateRequest } from './models/ShopCreateRequest';
export type { ShopEditRequest } from './models/ShopEditRequest';
export type { ShopInfoResponse } from './models/ShopInfoResponse';
export { ShopOrderFieldRequest } from './models/ShopOrderFieldRequest';
export { ShopOrderFieldResponse } from './models/ShopOrderFieldResponse';
export { ShopResponse } from './models/ShopResponse';
export { ShopStockUpdateRequest } from './models/ShopStockUpdateRequest';
export type { ShopWelcomeRequest } from './models/ShopWelcomeRequest';
export type { ShopWelcomeTextResponse } from './models/ShopWelcomeTextResponse';
export type { SignInRequest } from './models/SignInRequest';
export type { SignUpRequest } from './models/SignUpRequest';
export type { SizeResponse } from './models/SizeResponse';
export type { SizeTypeResponse } from './models/SizeTypeResponse';
export { StateShopRequest } from './models/StateShopRequest';
export type { SubscriptionConfirmedRequest } from './models/SubscriptionConfirmedRequest';
export type { SubscriptionConfirmedResponse } from './models/SubscriptionConfirmedResponse';
export { SubscriptionFeatureResponse } from './models/SubscriptionFeatureResponse';
export { SubscriptionPaymentHistoryResponse } from './models/SubscriptionPaymentHistoryResponse';
export type { SubscriptionRequest } from './models/SubscriptionRequest';
export { SubscriptionResponse } from './models/SubscriptionResponse';
export type { TinkoffWebhookRequest } from './models/TinkoffWebhookRequest';
export type { TokenCoupleDto } from './models/TokenCoupleDto';
export type { TokenCoupleResponse } from './models/TokenCoupleResponse';
export type { TokenResponse } from './models/TokenResponse';
export type { UpdateNewsletterStatusRequest } from './models/UpdateNewsletterStatusRequest';
export type { ValidateDataRequest } from './models/ValidateDataRequest';
export { VendorCodeCreateRequest } from './models/VendorCodeCreateRequest';
export type { VendorCodePhotoResponse } from './models/VendorCodePhotoResponse';
export type { VendorCodeResponse } from './models/VendorCodeResponse';
export type { WelcomeInfoRequest } from './models/WelcomeInfoRequest';
export type { WelcomeInfoResponse } from './models/WelcomeInfoResponse';
