import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorModel } from '../form-error.model';

@Component({
	standalone: true,
	selector: '[tkCommonError]',
	templateUrl: './common-error.component.html',
	styleUrls: ['./common-error.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	inputs: ['errors'],
	imports: [CommonModule],
})
export class CommonErrorComponent extends FormErrorModel {}
