<ng-container *transloco="let t">
	<tg-info-plate>{{ t('delivery.select_delivery') }}</tg-info-plate>
	<div class="item">
		<tk-checkbox
			id="deliveryCheckbox"
			[(ngModel)]="deliveryEnabled"
			(ngModelChange)="toggle(DeliveryOption.DELIVERY, $event)"
			><span class="item__checkbox-text">{{ t('delivery.shipping') }}</span>
		</tk-checkbox>
		<p class="item__text">{{ t('delivery.delivery_text') }}</p>
		<ng-container *ngIf="deliveryEnabled">
			<form [formGroup]="deliveryForm" (ngSubmit)="saveDelivery()">
				<div class="block">
					<h5>Стоимость доставки</h5>
					<tk-form-field>
						<tk-label>Сумма</tk-label>
						<input type="number" min="0" tkInput tkInputTrim formControlName="deliveryCost" />
						<tk-error
							*ngIf="errors(deliveryForm, 'deliveryCost') as errorField"
							tkCommonError
							[errors]="errorField"
						></tk-error>
					</tk-form-field>
				</div>
				<div class="block">
					<h5>Стоимость заказа, от которой доставка будет бесплатной</h5>
					<tk-form-field>
						<tk-label>Сумма</tk-label>
						<input
							type="number"
							min="0"
							tkInput
							tkInputTrim
							formControlName="minOrderCostForFreeDelivery"
						/>
						<tk-error
							*ngIf="errors(deliveryForm, 'minOrderCostForFreeDelivery') as errorField"
							tkCommonError
							[errors]="errorField"
						></tk-error>
					</tk-form-field>
				</div>
				<div class="block">
					<h5>Срок доставки</h5>
					<tk-form-field>
						<tk-label>Срок доставки</tk-label>
						<input type="text" tkInput tkInputTrim formControlName="deliveryPeriod" />
						<tk-error
							*ngIf="errors(deliveryForm, 'deliveryPeriod') as errorField"
							tkCommonError
							[errors]="errorField"
						></tk-error>
					</tk-form-field>
				</div>
				<button [disabled]="!deliveryForm.valid" tkButton type="submit" class="submit">Сохранить</button>
			</form>
		</ng-container>
	</div>
	<ng-container *ngIf="shopService.shopMoneySign | async">
		<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
			<div class="item">
				<tk-checkbox [ngModel]="pickupEnabled" (ngModelChange)="toggle(DeliveryOption.PICK_UP, $event)"
					><span class="item__checkbox-text">Самовывоз</span>
				</tk-checkbox>
				<p class="item__text">
					{{ t('delivery.pickup_text') }}
				</p>
			</div>
			<ng-container *ngIf="pickupEnabled">
				<button class="add-button" (click)="addPickupPoint()">
					<tk-icon icon="plus"></tk-icon> {{ t('delivery.add_pickup') }}
				</button>

				<div *ngIf="pickupPoints?.length" class="points">
					<div *ngFor="let point of pickupPoints" class="point">
						<div class="point__header">
							<div class="point__name">
								{{ point.pickUpPointName }}
							</div>
							<tk-button-group>
								<button class="point__remove" (click)="remove(point)">
									<tk-icon icon="trash"></tk-icon>
								</button>
								<button class="point__edit" (click)="edit(point)">
									<tk-icon icon="edit"></tk-icon>
								</button>
							</tk-button-group>
						</div>

						<div class="point__address-title">Адрес</div>
						<div class="point__address-text">{{ point.address.addressShort }}</div>
					</div>
				</div>
			</ng-container>
			<div class="item">
				<tk-checkbox [ngModel]="apishipEnabled" (ngModelChange)="toggle(DeliveryOption.APISHIP, $event)"
					><span class="item__checkbox-text">apiShip</span>
				</tk-checkbox>
				<p class="item__text">
					Подключите CDEK, Почту России, Boxberry или другую службу доставки из 40+ доступных. Для интеграции
					вы должны иметь аккаунт в <a href="https://apiship.ru/" target="_blank">ApiShip.</a>
				</p>
			</div>

			<ng-container *ngIf="apishipEnabled">
				<tg-shop-delivery-apiship></tg-shop-delivery-apiship>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
