import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EmailValidators, PasswordValidators, errors, validatePasswords } from '@utils/validations';
import { ToastService } from '@services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'tk-sign-up-form',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.sass', '../common.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LoadingService],
})
export class SignUpFormComponent {
	@ViewChild('password') public passwordRef?: ElementRef;
	queryParams: any = null;

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private toast: ToastService,
		private router: Router,
		public loading$: LoadingService,
		private route: ActivatedRoute,
	) {
		if (this.authService.token) {
			this.router.navigate(['/']);
		}

		this.queryParams = encodeURI(JSON.stringify(this.route.snapshot.queryParams));
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	form: UntypedFormGroup = new UntypedFormGroup(
		{
			'email': new UntypedFormControl(null, [Validators.required, ...EmailValidators]),
			'password': new UntypedFormControl(null, [Validators.required, ...PasswordValidators]),
			'passwordRepeat': new UntypedFormControl(null, [Validators.required, ...PasswordValidators]),
		},
		{
			validators: validatePasswords(),
		},
	);

	submitForm = () => {
		this.loading$.next(true);

		this.authService.signUp(this.form.value, this.queryParams).subscribe({
			next: res => {
				this.toast.success('Регистрация прошла успешно');
				this.authService.authorize(res);
				// @ts-ignore
				ym(environment.metricId, 'reachGoal', 'sign_up');
				// @ts-ignore
				ym(environment.metricId, 'reachGoal', 'sign_in');
				this.router.navigate(['/']);
				this.loading$.next(false);
			},
			error: err => {
				switch (err.status) {
					case 400:
						this.toast.error(
							'Пользователь с таким email уже зарегистрирован. Перейдите в форму авторизации',
						);
						break;
					case 404:
						this.toast.error('Что-то пошло не так');
						break;
					default:
						this.toast.error(err.error?.message);
				}
				this.loading$.next(false);
			},
		});
	};

	get isDisabled(): boolean {
		return this.form.invalid;
	}
}
