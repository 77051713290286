import {
	AbstractControl,
	FormControl,
	FormGroup,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';

export const EmailRegExp = /^\S+@\S+\.\S+$/;
export const NumbersRegExp = /\d/;

export const EmailValidators = [Validators.email, Validators.pattern(EmailRegExp), Validators.maxLength(254)];

export const PasswordSpecialSymbols = /^[a-z\d!@#$%^&*()_+=;:,.\/?|`~ \[\]{}]+$/i;

export const maxFilesize = 5 * 1024 * 1024;
export const fileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

const getField = (form: FormGroup, field: string): FormGroup | undefined => {
	const [first, ...rest] = field.split('.');
	if (!rest.length) {
		return form.get(first) as FormGroup;
	}
	return getField(form.get(first) as FormGroup, rest.join('.'));
};

export const errors = (form: UntypedFormGroup, field: string, onInit = false): ValidationErrors | null | undefined => {
	const control = getField(form, field);
	const showOnInit = onInit ? true : control?.touched || control?.dirty;

	return showOnInit ? control?.errors : null;
};

export const CheckIfErrorEmpty = (error: ValidationErrors | null) => {
	return error && Object.keys(error).length ? error : null;
};

export const validatePasswords = (
	passField: string = 'password',
	confirmPasswordField: string = 'passwordRepeat',
	emailField: string = 'email',
): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const email: AbstractControl | null = control?.get(emailField);
		const password: AbstractControl | null = control?.get(passField);
		const confirmPassword: AbstractControl | null = control?.get(confirmPasswordField);

		const passwordErrors = password?.errors ?? null;
		const confirmPasswordErrors = confirmPassword?.errors ?? null;

		if (email?.value && password?.value && email?.value === password?.value) {
			password?.setErrors({ ...passwordErrors, duplicateEmail: true }, { emitEvent: false });
			return { duplicateEmail: true };
		}

		if (password?.value !== confirmPassword?.value) {
			confirmPassword?.setErrors({ ...confirmPasswordErrors, notSame: true }, { emitEvent: false });
			return { notSame: true };
		}

		delete passwordErrors?.['duplicateEmail'];
		delete confirmPasswordErrors?.['notSame'];
		password?.setErrors(CheckIfErrorEmpty(passwordErrors), { emitEvent: false });
		confirmPassword?.setErrors(CheckIfErrorEmpty(confirmPasswordErrors), { emitEvent: false });

		return null;
	};
};

export const validateStrongPassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	const { value } = control;

	if (!value) {
		return null;
	}

	const hasNumber = /(\D*\d){2,}/.test(value);
	const hasUpper = /[A-Z]/.test(value);
	const hasLower = /[a-z]/.test(value);
	const hasRussianLetter = /[а-яё]/i.test(value);
	const hasSpace = / /.test(value);
	const hasWrongSymbols = PasswordSpecialSymbols.test(value);

	if (hasRussianLetter) return { hasRussianLetter: true };
	if (!hasNumber) return { withoutNumber: true };
	if (!hasUpper) return { withoutUpper: true };
	if (!hasLower) return { withoutLower: true };
	if (hasSpace) return { hasSpace: true };
	if (!hasWrongSymbols) return { invalidCharacters: true };

	return null;
};

export const PasswordValidators = [
	Validators.required,
	Validators.minLength(8),
	Validators.maxLength(32),
	validateStrongPassword,
];

export const noWhitespaceValidator = (control: FormControl) => {
	const isWhitespace = (control.value || '').trim().length === 0;
	const isValid = !isWhitespace;
	return isValid ? null : { 'required': true };
};

export const stepValidator = (control: FormControl) => {
	const isValid = (control.value?.toString().split('.')[1] || '').length < 4;
	return isValid ? null : { 'step': true };
};

export const isINNIndividual = (value: number): boolean => {
	const valueToString = value ? value.toString() : '';
	const getN = (index: number): number => parseInt(valueToString[index]);
	if (valueToString.length === 12) {
		const dgt11 =
			((7 * getN(0) +
				2 * getN(1) +
				4 * getN(2) +
				10 * getN(3) +
				3 * getN(4) +
				5 * getN(5) +
				9 * getN(6) +
				4 * getN(7) +
				6 * getN(8) +
				8 * getN(9)) %
				11) %
			10;

		const dgt12 =
			((3 * getN(0) +
				7 * getN(1) +
				2 * getN(2) +
				4 * getN(3) +
				10 * getN(4) +
				3 * getN(5) +
				5 * getN(6) +
				9 * getN(7) +
				4 * getN(8) +
				6 * getN(9) +
				8 * getN(10)) %
				11) %
			10;

		return getN(10) === dgt11 && getN(11) === dgt12;
	}
	return false;
};

export const isINNLegalEntity = (value: number): boolean => {
	const valueToString = value ? value.toString() : '';
	const getN = (index: number): number => parseInt(valueToString[index]);
	if (valueToString.length === 10) {
		const dgt10 =
			((2 * getN(0) +
				4 * getN(1) +
				10 * getN(2) +
				3 * getN(3) +
				5 * getN(4) +
				9 * getN(5) +
				4 * getN(6) +
				6 * getN(7) +
				8 * getN(8)) %
				11) %
			10;
		return getN(9) === dgt10;
	}
	return false;
};

export const isValidINN = (control: FormControl) => {
	const inn = control.value;
	return isINNLegalEntity(inn) || isINNIndividual(inn) ? null : { 'inn': true };
};

export const dotValidator = (control: FormControl) => {
	if (!control.value) {
		return null;
	}
	const str = typeof control.value === 'number' ? control.value.toString() : control.value;
	const hasDot = str ? str.includes('.') : false;
	return hasDot ? { 'dot': true } : null;
};

export const retailUrlValidator = (control: FormControl) => {
	const start = control.value.startsWith('https://');
	const end = control.value.endsWith('.retailcrm.ru');
	const hasSpace = / /.test(control.value.trim());
	const isValid = start && end && !hasSpace;
	return isValid ? null : { 'retailUrl': true };
};

export const validateImage = (type: string, size: number, multiple: boolean = false): boolean => {
	return size < maxFilesize && fileTypes.some(item => item === type);
};
