import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ViewEncapsulation,
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import {
	ColorResponse,
	PositionResponse,
	ProductDimensionsTypeResponse,
	SizeResponse,
	SizeTypeResponse,
	CategoryResponse,
} from '@models/generated/api';
import { ProductService } from '@services/product.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { Dialog } from '@angular/cdk/dialog';
import { ProductSelectTypeComponent } from '@components/modals/product-select-type/product-select-type.component';
import { ProductSelectColorsComponent } from '@components/modals/product-select-colors/product-select-colors.component';
import { ImageService } from '@services/image.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { dotValidator, errors, maxFilesize, noWhitespaceValidator, validateImage } from '@utils/validations';
import { TranslocoService } from '@ngneat/transloco';
import { ShopService } from '@services/shop.service';
import { DictionariesService } from '@services/dictionaries.service';
import { CategoriesAPIService } from '@app/services/categories/api';
import { share, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';

const numberSizeMask = 999999999999999999;

interface FormStep {
	name: string;
	title: string;
	canOpen: boolean;
	index: number;
}

interface ColorData extends ColorResponse {
	colorId?: string;
	price?: number;
	discount?: number;
	amount?: number;
	positions?: {
		sizeId: string;
		name: string;
		amount: number;
	}[];
	photos?: {
		photoId: string;
		title: boolean;
	}[];
	title?: boolean;
	vendorCodeValue?: string;
}

@Component({
	selector: 'tg-edit-product-page',
	templateUrl: './edit-product.page.html',
	styleUrls: ['./edit-product.page.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
	encapsulation: ViewEncapsulation.None,
})
export class EditProductPageComponent implements AfterContentInit {
	shopId: string | null = null;
	productId: string | null = null;
	form!: FormGroup;
	categories$ = new Observable<CategoryResponse[]>();

	steps: FormStep[] = [
		{
			name: 'info',
			title: 'Информация',
			canOpen: true,
			index: 0,
		},
		{
			name: 'detail',
			title: 'Характеристики',
			canOpen: false,
			index: 1,
		},
		{
			name: 'photo',
			title: 'Фото',
			canOpen: false,
			index: 2,
		},
	];
	activeStep: FormStep = this.steps[0];

	typeId$ = new BehaviorSubject<SizeTypeResponse>(null);
	typeSizeList$ = new BehaviorSubject<SizeResponse[]>([]);
	sizeTypeRemoved: boolean = false;

	selectedColors = new BehaviorSubject<ColorResponse[] | null>(null);
	activeColor: ColorResponse | null = null;
	colorData: ColorData[] = [];

	defaultData: ColorData = {
		positions: [],
		price: null,
		discount: null,
		vendorCodeValue: null,
		amount: null,
		photos: [],
	};

	isDetail: boolean = false;
	titlePhotoId: string | null = null;

	editData: any;
	selectedSizes: PositionResponse[] | null = null;
	dimensionList: ProductDimensionsTypeResponse[];

	hideTypeToast: boolean = false;

	constructor(
		private fb: FormBuilder,
		private dialog: Dialog,
		private productService: ProductService,
		private categoriesService: CategoriesAPIService,
		private toast: ToastService,
		private imageService: ImageService,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router,
		public loading$: LoadingService,
		private translocoService: TranslocoService,
		public shopService: ShopService,
		private dictionariesService: DictionariesService,
	) {
		this.shopId = this.route.snapshot.paramMap.get('id');
		this.productId = this.route.snapshot.paramMap.get('productId');

		this.categories$ = this.categoriesService.get(this.shopId, '').pipe(
			share(),
			tap(data => {
				if (data.length === 0) {
					this.form.get('categoryId')?.disable();
				} else {
					this.form.get('categoryId')?.enable();
				}
			}),
		);

		this.form = this.fb.group({
			productName: ['', [Validators.required, Validators.maxLength(150), noWhitespaceValidator]],
			categoryId: ['', []],
			shortDescription: ['', [Validators.required, Validators.maxLength(200), noWhitespaceValidator]],
			fullDescription: ['', [Validators.required, Validators.maxLength(3000), noWhitespaceValidator]],
			priority: ['', [Validators.max(100), dotValidator]],
			sizeTypeId: [null, []],
			sizes: [null, []],
			price: ['', [Validators.required, Validators.min(0), Validators.max(numberSizeMask)]],
			discount: [null, [Validators.min(0), Validators.max(100), dotValidator]],
			vendorCodeValue: ['', [Validators.required, noWhitespaceValidator]],
			amount: [
				'',
				[
					Validators.required,
					Validators.maxLength(18),
					Validators.min(0),
					Validators.max(numberSizeMask),
					dotValidator,
				],
			],
			dimensionType: new FormControl(null),
			length: new FormControl(null, [Validators.maxLength(8), Validators.min(0), dotValidator]),
			width: new FormControl(null, [Validators.maxLength(8), Validators.min(0), dotValidator]),
			height: new FormControl(null, [Validators.maxLength(8), Validators.min(0), dotValidator]),
			weight: new FormControl(null, [Validators.maxLength(8), Validators.min(0), dotValidator]),
			thankYouText: new FormControl(null, [Validators.maxLength(4096)]),
		});

		this.typeId$.subscribe({
			next: data => {
				data &&
					this.productService.getTypeSizes(data.id).subscribe({
						next: data => {
							this.typeSizeList$.next(data);
						},
					});

				data && !this.hideTypeToast && this.toast.success('Тип размера успешно добавлен');
				this.hideTypeToast = false;
				this.updateAmountFields();
			},
		});

		this.typeSizeList$.subscribe({
			next: data => {
				if (!this.sizeTypeRemoved && this.editData) {
					this.form.setValue({
						...this.form.value,
						productName: this.editData.productName,
						shortDescription: this.editData.shortDescription,
						fullDescription: this.editData.fullDescription,
						priority: this.editData.priority,
						sizes: this.editData.vendorCodes[0].positions.map((item: any) => {
							const size: any = this.findSizeById(item.size?.id ?? item.sizeId);
							size.id = item.size?.id ?? item.sizeId;
							size.amount = item.amount;
							size.name = item.size?.sizeValue ?? item.name;
							return size;
						}),
					});

					this.colorData.length = 0;
					this.fillEditData();
					this.getDetailData();
					this.validateSteps();
				}
			},
		});

		this.selectedColors.subscribe({
			next: data => {
				if (data) {
					this.updateAmountFields();
					this.setDetailData();
					!this.activeColor && this.setActiveColor(data[0]);
					this.getDetailData();
					this.setTitleColor(true);
				}
			},
		});

		this.translocoService.selectTranslateObject('create').subscribe(t => {
			this.steps[0].title = t.info;
			this.steps[1].title = t.details;
			this.steps[2].title = t.photo;
			this.cdr.markForCheck();
		});

		if (!shopService.shopMoneySign.value || !shopService.shopInfo.value) {
			this.shopService.getShop(this.shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
					this.shopService.shopInfo.next(res);
					this.cdr.markForCheck();
				},
			});
		}

		dictionariesService.getProductDimensions().subscribe({
			next: res => {
				this.dimensionList = res;
			},
		});
	}

	ngAfterContentInit() {
		this.productService.getProductData(this.shopId, this.productId).subscribe({
			next: data => {
				this.editData = data;

				if (data.category?.id) {
					this.form.patchValue({
						categoryId: data.category.id,
					});
				}

				if (!!data.digitalGoods) {
					this.form.patchValue({
						...this.form.value,
						productName: data.productName,
						shortDescription: data.shortDescription,
						fullDescription: data.fullDescription,
						priority: data.priority,
						amount: data.digitalGoods.amount,
						discount: data.digitalGoods.discount,
						thankYouText: data.digitalGoods.thankYouText,
						vendorCodeValue: data.digitalGoods.vendorCodeValue,
						price: data.digitalGoods.price,
					});

					this.titlePhotoId = data.digitalGoods.photos.filter(photo => photo.isTitle)[0]?.file?.id;
					this.defaultData = {
						photos: data.digitalGoods.photos
							.filter(photo => !photo.isTitle)
							.map(photo => {
								photo.isTitle && (this.titlePhotoId = photo.file.id);
								return {
									...photo,
									photoId: photo.file.id,
									title: photo.isTitle,
								};
							}),
						amount: data.digitalGoods.amount ?? null,
						positions: [],
					};
					this.validateSteps();
					return;
				}

				this.selectedSizes = data.vendorCodes[0].positions;

				if (data.length || data.width || data.height) {
					if (this.dimensionList) {
						this.form.patchValue({
							...this.form.value,
							dimensionType: this.checkDimensionType(this.dimensionList)
								? this.checkDimensionType(this.dimensionList).productDimensionType
								: 'Другое',
							length: data.length,
							width: data.width,
							height: data.height,
						});
					} else {
						this.dictionariesService.getProductDimensions().subscribe({
							next: res => {
								this.dimensionList = res;
								this.form.patchValue({
									...this.form.value,
									dimensionType: this.checkDimensionType(this.dimensionList)
										? this.checkDimensionType(this.dimensionList).productDimensionType
										: 'Другое',
									length: data.length,
									width: data.width,
									height: data.height,
								});
							},
						});
					}
				}

				if (data.vendorCodes.length === 1 && data.vendorCodes[0].color.colorName === 'По умолчанию') {
					this.defaultData = this.editData.vendorCodes[0];
				} else {
					this.selectedColors.next(data.vendorCodes.map(item => item.color));
				}

				if (data.weight) {
					this.form.patchValue({
						weight: data.weight,
					});
				}

				if (data.sizeType && data.vendorCodes[0].positions.length > 0) {
					this.hideTypeToast = true;
					this.typeId$.next(data.sizeType);
				} else {
					this.form.patchValue({
						...this.form.value,
						productName: data.productName,
						shortDescription: data.shortDescription,
						fullDescription: data.fullDescription,
						priority: data.priority,
					});
					this.fillEditData();
					this.validateSteps();
					this.getDetailData();
				}
			},
		});
	}

	get preview() {
		let result = this.form.controls['thankYouText'].value;

		result = result?.replace(/\*(.*?)\*/g, '<b>$1</b>');
		result = result?.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
		result = result?.replace(/\_(.*?)\_/g, '<i>$1</i>');
		result = result?.replace(/\~(.*?)\~/g, '<del>$1</del>');

		return result;
	}

	fillEditData() {
		if (
			this.editData?.vendorCodes.length === 1 &&
			this.editData?.vendorCodes[0].color.colorName === 'По умолчанию'
		) {
			const item = this.editData.vendorCodes[0];
			this.titlePhotoId = item.photos.filter(photo => photo.isTitle)[0]?.file?.id;
			this.defaultData = {
				...item,
				photos: item.photos
					.filter(photo => !photo.isTitle)
					.map(photo => {
						photo.isTitle && (this.titlePhotoId = photo.file.id);
						return {
							...photo,
							photoId: photo.file.id,
							title: photo.isTitle,
						};
					}),
				amount: item.positions[0]?.amount ?? null,
			};
		} else {
			this.colorData = [];
			this.editData?.vendorCodes.forEach((item: any) => {
				this.colorData.push({
					...item,
					colorId: item.color.id,
					amount: item.positions.length === 1 ? item.positions[0].amount : null,
					positions: item.positions.map((pos: any) => {
						return {
							...pos,
							sizeId: pos.size?.id,
							name: pos.size?.sizeValue,
						};
					}),
					photos: item.photos.map(photo => {
						return {
							...photo,
							photoId: photo.file.id,
							title: photo.isTitle,
						};
					}),
				} as ColorData);
			});

			this.selectedColors.value && (this.activeColor = this.selectedColors.value[0]);
		}
	}

	checkDimensionType(list) {
		return (
			list.find(
				item =>
					item.length === this.editData.length &&
					item.width === this.editData.width &&
					item.height === this.editData.height,
			) || null
		);
	}

	findSizeById(id: string) {
		return this.typeSizeList$.value.find((i: any) => i.id === id || i.sizeId === id);
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	setDetailData() {
		this.detailData = {
			...this.detailData,
			price: this.getField('price').value,
			discount: this.getField('discount').value,
			vendorCodeValue: this.getField('vendorCodeValue').value,
			amount: this.getField('amount').value,
		};
	}

	getDetailData() {
		this.detailData &&
			this.form.patchValue({
				...this.form.value,
				price: this.detailData.price,
				discount: this.detailData.discount,
				vendorCodeValue: this.detailData.vendorCodeValue,
				amount: this.detailData.amount,
			});
	}

	updateAmountFields(create?: boolean): void {
		if (!(this.selectedColors && this.selectedColors.value?.length > 0)) {
			this.selectedColors.next(null);
			this.createPositionFields(this.defaultData, this.form.value.sizes);
			return;
		}
		if (create) {
			if (this.form.value.sizes?.length === 0) {
				this.form.setValue({ ...this.form.value, sizes: null });
				return;
			}
			if (
				this.form.value.sizes?.length !== this.colorData[0].positions.length ||
				(this.colorData[0].positions.length === 1 &&
					(this.colorData[0].positions[0] as any).size.id !== this.form.get('sizes').value)
			) {
				this.colorData.forEach(color => this.createPositionFields(color, this.form.value.sizes));
			}
			return;
		}

		this.selectedColors.value.forEach(color => {
			if (!this.colorData?.some(item => item.colorId === color.id)) {
				const data = {
					colorId: color.id,
					positions: [],
					photos: [],
					price: null,
					discount: null,
					vendorCodeValue: null,
					amount: null,
					title: false,
				};

				this.createPositionFields(data, this.form.value.sizes);
				this.colorData.push(data);
			}
		});

		this.colorData &&
			this.colorData.map((color, index) => {
				if (!this.selectedColors.value.some(item => item.id === color.colorId)) {
					this.colorData.splice(index, 1);
				}
			});
	}

	createPositionFields(object: ColorData, selectedSizes: { id: string }[]) {
		if (selectedSizes) {
			selectedSizes.forEach(size => {
				if (!object.positions.some(pos => pos.sizeId === size?.id)) {
					object.positions.push({
						sizeId: size?.id,
						amount: 0,
						name: this.findSizeById(size?.id)?.sizeValue,
					});
				}
				object.positions.forEach((pos: any, index) => {
					if (!selectedSizes.some(item => item.id === pos.sizeId || item.id === pos.size?.id)) {
						object.positions.splice(index, 1);
					}
				});
			});
		} else {
			if (this.selectedSizes) {
				this.selectedSizes = this.selectedSizes.filter(
					(value: any, index, self) => index === self.findIndex((t: any) => t.size?.id === value.size?.id),
				);

				this.selectedSizes = this.selectedSizes.map((s: any) => {
					return {
						sizeId: s?.size?.id ?? s.sizeId,
						amount: s?.amount,
						name: s?.size?.sizeValue,
					};
				});
			}

			this.selectedSizes?.forEach((size: any) => {
				if (
					!object.positions?.some(
						(pos: any) =>
							pos.sizeId === size?.id || pos.sizeId === size.size?.id || pos.sizeId === size?.sizeId,
					)
				) {
					object.positions?.push({
						sizeId: size?.size?.id ?? size?.sizeId,
						amount: size?.amount,
						name: size?.name ?? this.findSizeById(size?.id)?.sizeValue,
					});
				}

				object.positions?.forEach((pos: any, index) => {
					if (!this.selectedSizes?.some((item: any) => item.sizeId === pos.size?.id ?? pos.sizeId)) {
						object.positions.splice(index, 1);
					}
				});
			});
			object.positions = object.positions.map((i: any) => {
				return {
					sizeId: i?.size?.id ?? i?.sizeId,
					amount: i?.amount,
					name: i?.size?.sizeValue ?? i?.name ?? this.findSizeById(i.id)?.sizeValue,
				};
			});
		}
		if (selectedSizes?.length === 0) {
			object.positions = [];
		}

		return object;
	}

	//steps
	setStep(step: FormStep): void {
		this.activeStep = step;
	}

	getStep(index: number): FormStep {
		return this.steps[index];
	}

	nextStep(): void {
		this.setDetailData();
		this.validateSteps();

		this.activeStep?.index === 2 && this.submit();
		const index = this.editData?.isImported ? 2 : 1;

		if (!this.getStep(this.activeStep.index + index)?.canOpen) {
			this.showErrors();
			return;
		}
		this.form.markAsUntouched();
		this.activeStep = this.steps[this.activeStep.index + index];
	}

	prevStep(): void {
		const index = this.editData?.isImported ? 2 : 1;
		this.activeStep = this.steps[this.activeStep.index - index];
	}

	//steps/

	toggleDetail() {
		this.isDetail = !this.isDetail;
	}

	//type
	selectType(): void {
		this.dialog.open(ProductSelectTypeComponent).closed.subscribe({
			next: data => {
				data && this.typeId$.next(data as SizeTypeResponse);
			},
		});
	}

	removeSelectedType(): void {
		this.sizeTypeRemoved = true;
		this.typeId$.next(null);
		this.form.setValue({ ...this.form.value, sizes: null });
		this.updateAmountFields();
	}

	//type/

	//color
	selectColors(selectedColors: ColorResponse[]): void {
		this.dialog
			.open(ProductSelectColorsComponent, {
				data: {
					selectedColors: selectedColors,
				},
			})
			.closed.subscribe({
				next: (data: ColorResponse[]) => {
					data && this.selectedColors.next(data);
				},
			});
	}

	findColorById(id: string) {
		return this.colorData.find(color => color.colorId === id);
	}

	get detailData(): ColorData {
		if (!this.activeColor) {
			return this.defaultData;
		}
		return this.colorData[this.activeColorIndex];
	}

	set detailData(data: ColorData) {
		if (!this.activeColor) {
			this.defaultData = data;
			return;
		}
		this.colorData[this.activeColorIndex] = data;
	}

	get activeColorIndex(): number {
		return this.colorData.findIndex(item => item.colorId === this.activeColor.id);
	}

	setActiveColor(color: ColorResponse): void {
		this.setDetailData();
		this.activeColor = color;
		this.getDetailData();
	}

	setTitleColor(check: boolean = false): void {
		!(check && this.colorData.some(item => item.title === true)) &&
			(this.colorData = this.colorData.map(item => {
				return item.colorId === this.activeColor.id ? { ...item, title: true } : { ...item, title: false };
			}));
	}

	isTitleColor() {
		return this.detailData?.title;
	}

	removeColor() {
		this.selectedColors.next(this.selectedColors.value.filter(item => item.id !== this.activeColor.id));
		this.colorData?.filter(item => item.colorId !== this.activeColor.id);
		if (this.selectedColors.value === null) {
			this.activeColor = null;
			this.getDetailData();
			return;
		}
		this.activeColor = this.selectedColors.value[0];
		this.setTitleColor(true);
		this.getDetailData();
	}

	//color/

	changePositionAmount(event: Event, pos: any) {
		const val = (event.target as HTMLInputElement).value !== '' ? +(event.target as HTMLInputElement).value : null;

		this.activeColor
			? (this.colorData
					.find(item => item.colorId === this.activeColor.id)
					.positions.find(position => position.sizeId === pos.sizeId).amount = val)
			: (this.defaultData.positions.find(position => position.sizeId === pos.sizeId).amount = val);
	}

	filterByTitle(photos, title: boolean) {
		return photos.filter(photo => photo.title === title);
	}

	isSizesExists() {
		return this.form.value?.sizes !== null;
	}

	//photo
	uploadTitlePhoto({ target }: any) {
		const files = target.files;

		if (!validateImage(files[0].type, files[0].size)) {
			this.toast.error(
				files[0].size > maxFilesize
					? 'Объём файла не должен превышать 5 MB'
					: 'Допустимый формат: PNG, JPEG, JPG, GIF, WEBP',
			);
			return;
		}
		this.imageService.uploadImage(files[0], 'PRODUCT_PHOTO').subscribe({
			next: data => {
				this.titlePhotoId = data.id;
				target.value = '';
				this.cdr.markForCheck();
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	deleteTitlePhoto() {
		this.titlePhotoId = null;
	}

	uploadCommonPhoto({ target }: any, isColor: boolean, targetObj: ColorData['photos']): void {
		const files = target.files;

		let skipErr: boolean = false;
		Object.keys(files).forEach((key, index) => {
			if (!validateImage(files[key].type, files[key].size)) {
				if (files.length > 1) {
					!skipErr &&
						this.toast.error('Не все фото загружены. Проверьте размер или формат загружаемых файлов');
					skipErr = true;
					return;
				}
				this.toast.error(
					files[key].size > maxFilesize
						? 'Объём файла не должен превышать 5 MB'
						: 'Допустимый формат: PNG, JPEG, JPG, GIF, WEBP',
				);
				return;
			}
			this.imageService.uploadImage(files[key], 'PRODUCT_PHOTO').subscribe({
				next: data => {
					if (targetObj?.length < 10) {
						targetObj.push({
							photoId: data.id,
							title: isColor && !targetObj.some(item => item.title === true),
						});
					}
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
				complete: () => {
					target.value = '';
					this.cdr.markForCheck();
				},
			});
		});
	}

	deleteDefaultPhoto(id: string) {
		this.defaultData.photos = this.defaultData.photos!.filter(photo => photo.photoId !== id);
		this.cdr.markForCheck();
	}

	deleteColorPhoto(colorId: string, id: string) {
		this.findColorById(colorId).photos = this.findColorById(colorId).photos!.filter(photo => photo.photoId !== id);
		this.cdr.markForCheck();
	}

	//photo/

	//validation
	getField(name: string): AbstractControl {
		return this.form?.get(name);
	}

	validateSteps() {
		this.steps.forEach(item => {
			switch (item.index) {
				case 0:
					item.canOpen = true;
					return true;
				case 1: {
					if (this.editData?.isImported) {
						item.canOpen = false;
						return false;
					}
					item.canOpen =
						this.getField('productName')?.valid &&
						this.getField('shortDescription')?.valid &&
						this.getField('fullDescription')?.valid &&
						this.getField('thankYouText')?.valid &&
						this.getField('priority')?.valid;
					return item.canOpen;
				}
				case 2: {
					item.canOpen = true;
					if (this.editData?.isImported) {
						item.canOpen =
							this.getField('productName')?.valid &&
							this.getField('shortDescription')?.valid &&
							this.getField('fullDescription')?.valid &&
							this.getField('thankYouText')?.valid &&
							this.getField('priority')?.valid;
						return item.canOpen;
					}
					if (this.activeColor) {
						let highlighted: boolean = false;
						this.colorData.forEach(color => {
							if (highlighted) {
								return false;
							}

							const hasAllSizes = color.positions.every(pos => {
								return pos.amount >= 0 && pos.amount !== null;
							});

							if (this.isSizesExists() && !hasAllSizes) {
								item.canOpen = false;
								this.setActiveColor(this.selectedColors.value.find(item => item.id === color.colorId));
								highlighted = true;
								return false;
							}

							if (
								color.price === null ||
								color.discount > 100 ||
								color.vendorCodeValue === null ||
								color.vendorCodeValue === '' ||
								(color.amount === null && !this.isSizesExists())
							) {
								this.setActiveColor(this.selectedColors.value.find(item => item.id === color.colorId));
								highlighted = true;
								item.canOpen = false;
								return false;
							}
							return true;
						});
					} else {
						item.canOpen = true;
						const hasAllSizes = this.defaultData.positions.every(pos => {
							return pos.amount >= 0 && pos.amount !== null;
						});

						if (this.isSizesExists() && !hasAllSizes) {
							item.canOpen = false;
							return false;
						}

						item.canOpen =
							this.getField('price')?.valid &&
							this.getField('vendorCodeValue')?.valid &&
							this.getField('discount')?.valid &&
							(this.isSizesExists() || this.getField('amount')?.valid);
					}

					if (item.canOpen && this.getField('dimensionType')?.value === 'Другое') {
						item.canOpen =
							this.getField('width')?.valid &&
							this.getField('height')?.valid &&
							this.getField('length')?.valid;
					}
					if (item.canOpen) {
						item.canOpen = this.getField('weight')?.valid;
					}
					return false;
				}

				default:
					return true;
			}
		});
	}

	showErrors(): void {
		this.activeStep?.index !== 2 && this.toast.error('Заполните обязательные поля');
		this.form.markAllAsTouched();
	}

	submit(): void {
		if (
			(!this.activeColor && !this.titlePhotoId) ||
			(this.activeColor && !this.colorData.every(obj => obj.photos.some(item => item.title)))
		) {
			this.toast.error(
				this.activeColor ? 'Загрузите титульное фото для каждого цвета' : 'Загрузите титульное фото',
			);
			return;
		}

		this.loading$.next(true);

		const productData: any = {
			...(this.form.value.categoryId ? { categoryId: this.form.value.categoryId } : {}),
			productName: this.form.value.productName,
			fullDescription: this.form.value.fullDescription,
			shortDescription: this.form.value.shortDescription,
			priority: this.form.value.priority,
			vendorCodes: [],
		};

		if (this.selectedColors.value?.length > 0) {
			this.colorData.forEach((color: ColorData) => {
				const pos = color.positions;
				productData.vendorCodes.push({
					...color,
					positions:
						!this.form?.value?.sizes?.length ||
						pos.length === 0 ||
						(pos.length === 1 && (pos[0] as any)?.name === 'Без размера') ||
						(pos[0] as any)?.size?.sizeValue === 'Без размера'
							? [{ amount: color.amount }]
							: pos,
				});
			});
		} else {
			const pos = this.defaultData.positions;

			productData.vendorCodes.push({
				...this.defaultData,
				positions:
					!this.form?.value?.sizes?.length ||
					pos.length === 0 ||
					(pos.length === 1 && (pos[0] as any)?.name === 'Без размера') ||
					(pos[0] as any)?.size?.sizeValue === 'Без размера'
						? [{ amount: this.defaultData.amount }]
						: pos,
				photos: [
					...this.defaultData.photos,
					{
						photoId: this.titlePhotoId,
						title: true,
					},
				],
				title: true,
			});
		}

		if (this.shopService.isDigitalShop()) {
			productData.digitalGoods = {
				...this.defaultData,
				thankYouText: this.form.value.thankYouText,
				photos: [
					...this.defaultData.photos,
					{
						photoId: this.titlePhotoId,
						title: true,
					},
				],
			};

			delete productData.digitalGoods.positions;
			delete productData.vendorCodes;

			if (productData.digitalGoods.discount === null) {
				delete productData.digitalGoods.discount;
			}
		} else {
			productData.width = this.form.value.width;
			productData.height = this.form.value.height;
			productData.length = this.form.value.length;
			productData.weight = this.form.value.weight;
		}

		if (this.form.value.dimensionType && this.form.value.dimensionType !== 'Другое') {
			const dimensionsType = this.dimensionList.find(
				item => item.productDimensionType === this.form.value.dimensionType,
			);
			productData.width = dimensionsType.width;
			productData.height = dimensionsType.height;
			productData.length = dimensionsType.length;
		}

		this.productService
			.editProduct(productData, this.shopId, this.productId)
			.subscribe({
				next: () => {
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'item_created');
					this.router.navigate(['/shop/' + this.shopId]);
					this.toast.success('Товар успешно изменен!');
				},
				error: err => this.toast.error(err.status == 400 ? err.error : null),
			})
			.add(() => {
				this.loading$.next(false);
			});
	}

	onCategorySelected(item: CategoryResponse) {
		this.form.patchValue({
			categoryId: item.categoryId,
		});
	}
}
