<ng-container *transloco="let t">
	<ng-container *tgLet="breadcrumbs$ | async as breadcrumbs">
		<a
			*ngFor="let breadcrumb of breadcrumbs; let i = index; let isLast = last"
			class="link"
			routerLinkActive="link--active"
			[routerLinkActiveOptions]="{ exact: !isLast || exactMatch }"
			[routerLink]="breadcrumb.url"
			[ngSwitch]="breadcrumb.label"
		>
			<ng-container *ngSwitchCase="'Мои магазины'">{{ t('main.my_stores') }}</ng-container>
			<ng-container *ngSwitchCase="'Настройка магазина'">{{ t('delivery.store_settings') }}</ng-container>
			<ng-container *ngSwitchCase="'Товар'">{{ t('main.product') }}</ng-container>
			<ng-container *ngSwitchCase="'Заказ'">{{ t('order_history.order_short') }}</ng-container>
			<ng-container *ngSwitchCase="'Создание товара'">{{ t('create.create') }}</ng-container>
			<ng-container *ngSwitchDefault>{{ breadcrumb.label }}</ng-container>

			<ng-container *ngIf="i + 1 < (breadcrumbs?.length ?? false)"> / </ng-container>
		</a>
	</ng-container>
</ng-container>
