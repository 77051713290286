<ng-container *transloco="let t; read: 'categories'">
	<div class="head">
		<div class="th">
			{{ t('category_table_name') }}
		</div>
	</div>
	<tg-categories-tree-view-row
		*ngFor="let category of categories"
		[category]="category"
		[depth]="0"
		(onEdit)="onEdit.emit($event)"
		(onCreateSubCategory)="onCreateSubCategory.emit($event)"
		(onDelete)="onDelete.emit($event)"
		(onChildrenToggle)="onChildrenToggle.emit($event)"
	>
	</tg-categories-tree-view-row>
</ng-container>
