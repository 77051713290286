import { Pipe, PipeTransform } from '@angular/core';
import { AddressResponse } from '@models/generated/api';

@Pipe({
	name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
	transform(address?: AddressResponse): string {
		if (!address) {
			return '';
		}
		return [
			address.country ? `${address.country}` : null,
			address.subject ? `${address.subject}` : null,
			address.city ? `г. ${address.city}` : null,
			address.street ? `ул. ${address.street}` : null,
			address.house ? `дом ${address.house}` : null,
			address.building ? `к${address.building}` : null,
			address.entrance ? `${address.entrance} подъезд` : null,
			address.flat ? `кв ${address.flat}` : null,
			address.comment ? `${address.comment}` : null,
		]
			.filter(Boolean)
			.join(', ');
	}
}
