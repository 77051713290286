<ng-container *transloco="let t">
	<div class="beautiful-link">
		<button
			tkButton
			size="m"
			type="button"
			theme="link"
			class="beautiful-link__info"
			[tkTooltip]="t('main.beautiful_link_hint')"
			tkTooltipPlacement="bottom"
		>
			<tk-icon icon="info"></tk-icon>
		</button>

		<ng-container *ngIf="!link">
			<button tkButton size="m" type="button" theme="secondary" (click)="addLink.emit()">
				<tk-icon icon="plus"></tk-icon>
				{{ t('main.beautiful_link') }}
			</button>
		</ng-container>

		<ng-container *ngIf="link">
			<div class="beautiful-link__container">
				<span class="beautiful-link__text">{{ link }}</span>
				<div class="beautiful-link__actions">
					<button tkButton size="m" type="button" theme="link" (click)="copyLink()">
						<tk-icon icon="copy"></tk-icon>
					</button>
					<button tkButton size="m" type="button" theme="link" (click)="editLink.emit()">
						<tk-icon icon="edit"></tk-icon>
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</ng-container>
