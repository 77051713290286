<ng-container *transloco="let t; read: 'auth'">
	<form class="form" [formGroup]="form" (submit)="submitForm()">
		<h2 class="form__title">
			<ng-container *ngIf="!sendingRepeat">{{ t('reset_pass') }}</ng-container>
			<ng-container *ngIf="sendingRepeat">{{ t('check_mail') }}</ng-container>
		</h2>

		<ng-container *ngIf="!sendingRepeat">
			<p class="text">{{ t('reset_text') }}</p>

			<tk-form-field>
				<tk-label>{{ t('email') }}</tk-label>

				<input formControlName="email" tkInput />
				<tk-error *ngIf="errors(form, 'email') as errorField" tkEmailError [errors]="errorField"></tk-error>
			</tk-form-field>

			<tk-button-group class="tk-button-group">
				<button
					*ngIf="!(loading$ | async)"
					tkButton
					size="xl"
					theme="primary"
					type="submit"
					[disabled]="isDisabled"
				>
					{{ t('send') }}
				</button>
				<ng-container *ngIf="loading$ | async">
					<ng-container *ngTemplateOutlet="loader"></ng-container>
				</ng-container>
			</tk-button-group>
		</ng-container>

		<ng-container *ngIf="sendingRepeat">
			<p class="text">
				<!--				Чтобы создать новый пароль, перейдите по ссылке в письме, мы отправили его на {{ mail }}-->
				{{ t('reset_pass_text_1', { email: mail }) }}
			</p>

			<p *ngIf="!repeatButton" class="text">
				<!--				Если вы не получили письмо, нажмите «Отправить повторно» через {{ timeLeft }} или напишите нам на почту-->
				{{ t('reset_pass_text_2', { sec: timeLeft }) }}
			</p>

			<tk-button-group *ngIf="repeatButton" class="tk-button-group">
				<button
					*ngIf="!(loading$ | async)"
					tkButton
					size="xl"
					theme="primary"
					type="submit"
					[disabled]="isDisabled"
				>
					{{ t('send_again') }}
				</button>
				<ng-container *ngIf="loading$ | async">
					<ng-container *ngTemplateOutlet="loader"></ng-container>
				</ng-container>
			</tk-button-group>
		</ng-container>

		<div class="sign-in">
			{{ t('have_an_acc') }} <a routerLink="/sign-in">{{ t('have_sign_in') }}</a>
		</div>
	</form>

	<ng-template #loader>
		<tk-loader width="FULL"></tk-loader>
	</ng-template>
</ng-container>
