import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { SubscriptionService } from '@services/subscription.service';
import { PlanResponse, TokenCoupleResponse } from '@models/generated/api';
import { ToastService } from '@services/toast.service';
import { LoadingService } from '@services/loading.service';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { takeWhile, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { FormControl } from '@angular/forms';

const yearGoals = {
	1: 'sub_1year_start',
	2: 'sub_1year_business',
	3: 'sub_1year_premium',
};

const monthGoals = {
	1: 'sub_1mon_start',
	2: 'sub_1mon_business',
	3: 'sub_1mon_premium',
};

@Component({
	selector: 'tg-home',
	templateUrl: './tarifs.component.html',
	styleUrls: ['./tarifs.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LoadingService],
})
export class TarifsComponent implements OnDestroy {
	alive: boolean = true;
	yearSwitch: boolean = true;
	isRenew: boolean = false;
	policy: boolean = true;
	plans: PlanResponse[] = [];
	promocodeControl = new FormControl();
	isPromocodeAvailable: boolean = false;
	isPromocodeActive: boolean = false;

	constructor(
		private dialog: Dialog,
		private subscriptionService: SubscriptionService,
		private cdr: ChangeDetectorRef,
		private toast: ToastService,
		public loading$: LoadingService,
		private authService: AuthService,
		private router: Router,
		private auth: AuthService,
	) {
		if (!this.authService.token) {
			this.router.navigateByUrl('/sign-in?back=tarifs');
			return;
		}

		this.refresh();

		subscriptionService.isSubscriptionPromocodeAvailable().subscribe({
			next: res => {
				if (res) {
					this.isPromocodeAvailable = res;
					this.cdr.markForCheck();
				}
			},
		});
	}

	refresh() {
		this.subscriptionService
			.getSubscriptionPlans()
			.pipe(takeWhile(() => this.alive))
			.subscribe({
				next: res => {
					this.plans = res;
					this.cdr.markForCheck();
				},
			});
	}

	selectPlan(plan: PlanResponse) {
		this.loading$.next(true);

		// @ts-ignore
		ym(environment.metricId, 'reachGoal', 'open_payment');

		const data: any = {
			planId: plan.id,
			subscriptionSum: this.yearSwitch ? plan.totalPrice.toFixed(2) : plan.basePrice.toFixed(2),
			isAnnual: this.yearSwitch,
			isRecurrent: this.isRenew,
		};
		if (this.promocodeControl.value) {
			data.promocode = this.promocodeControl.value;
			data.subscriptionSum = this.yearSwitch ? plan.totalPrice.toFixed(2) : plan.realPriceForMonth.toFixed(2);
		}
		this.subscriptionService
			.createSubscription(data)
			.pipe(takeWhile(() => this.alive))
			.subscribe({
				next: res => {
					this.pay(plan, res.invoiceId, res.subscriptionId);
				},
				error: () => {
					this.toast.error('Что-то пошло не так');
				},
			})
			.add(() => {
				this.loading$.next(false);
			});
	}

	pay = (plan: PlanResponse, invoiceId: string, subscriptionId: string) => {
		// @ts-ignore
		let widget = new cp.CloudPayments();

		const planReceiptName =
			'Неисключительное право использования сервиса TGShop. Тариф «' +
			plan.planName +
			'» на 1 ' +
			(this.yearSwitch ? 'год.' : 'месяц.');

		var receipt = {
			Items: [
				//товарные позиции
				{
					label: planReceiptName, //наименование товара
					price: this.promocodeControl.value ? plan.realPriceForMonth : plan.basePrice, //цена
					quantity: 1.0, //количество
					amount: this.yearSwitch
						? plan.totalPrice
						: this.promocodeControl.value
						? plan.realPriceForMonth
						: plan.basePrice, //сумма
					vat: null, //ставка НДС
					method: 4, // тег-1214 признак способа расчета - признак способа расчета
					object: 1, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
				},
			],
			calculationPlace: 'https://admin.tgshop.io', //место осуществления расчёта, по умолчанию берется значение из кассы
			taxationSystem: 1, //система налогообложения;
			email: this.authService.getUserEmail(), //e-mail покупателя, если нужно отправить письмо с чеком
			phone: '', //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
			isBso: false, //чек является бланком строгой отчетности
			amounts: {
				electronic: this.yearSwitch
					? plan.totalPrice
					: this.promocodeControl.value
					? plan.realPriceForMonth
					: plan.basePrice, // Сумма оплаты электронными деньгами
				advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
				credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
				provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
			},
		};

		const data: any = {};
		data.CloudPayments = {
			CustomerReceipt: receipt,
		};

		if (this.isRenew) {
			data.CloudPayments = {
				...data.CloudPayments,
				recurrent: {
					interval: 'Month',
					period: this.yearSwitch ? 12 : 1,
					customerReceipt: receipt,
				},
			};
		}

		if (this.isPromocodeActive) {
			data.CloudPayments = {
				...data.CloudPayments,
				promocode: this.promocodeControl.value,
			};
		}

		widget.pay(
			'auth',
			{
				publicId: environment.cloudpaymentsId,
				description: 'Оплата подписки TGShop',
				amount: this.yearSwitch ? plan.totalPrice : plan.realPriceForMonth,
				currency: 'RUB',
				accountId: this.authService.getUserEmail(),
				invoiceId: invoiceId,
				email: this.authService.getUserEmail(),
				skin: 'classic',
				data,
			},
			{
				onSuccess: options => {
					this.toast.success('Успешная оплата');
					this.subscriptionService
						.updateSubscription({
							subscriptionId,
							isConfirmed: true,
						})
						.pipe(takeWhile(() => this.alive))
						.subscribe({
							next: () => {
								this.auth
									.refresh({
										accessToken: this.auth.token,
										refreshToken: this.auth.refreshToken,
									})
									.pipe(takeWhile(() => this.alive))
									.subscribe({
										next: (tokens: TokenCoupleResponse) => {
											this.auth.authorize(tokens);
											// @ts-ignore
											ym(
												environment.metricId,
												'reachGoal',
												this.yearSwitch ? yearGoals[plan.position] : monthGoals[plan.position],
												{
													order_price: this.yearSwitch
														? plan.totalPrice
														: this.promocodeControl.value
														? plan.realPriceForMonth
														: plan.basePrice,
												},
											);

											// @ts-ignore
											ym(environment.metricId, 'reachGoal', 'purchase_success', {
												order_price: this.yearSwitch
													? plan.totalPrice
													: this.promocodeControl.value
													? plan.realPriceForMonth
													: plan.basePrice,
											});
											this.router.navigate(['/subscriptions']);
										},
										error: err => {
											this.auth.logout();
											this.router.navigate(['/sign-in']);
											return throwError(err);
										},
									});
							},
							error: err => {},
						});
				},
				onFail: (reason, options) => {
					this.toast.error(reason);
					this.subscriptionService
						.updateSubscription({
							subscriptionId,
							isConfirmed: false,
						})
						.pipe(takeWhile(() => this.alive))
						.subscribe({
							next: () => {},
						});
				},
				onComplete: (paymentResult, options) => {},
			},
		);
	};

	selectPromocode() {
		this.subscriptionService
			.getSubscriptionPlans(this.promocodeControl.value)
			.pipe(takeWhile(() => this.alive))
			.subscribe({
				next: res => {
					this.plans = res;
					this.isPromocodeActive = true;
					this.isRenew = false;
					this.cdr.markForCheck();
					this.toast.success('Промокод успешно применён');
				},
				error: err => {
					this.toast.error(err.error.message);
				},
			});
	}

	removePromocode() {
		this.refresh();
		this.promocodeControl.setValue('');
		this.isPromocodeActive = false;
	}

	setYearSwitch(isYear: boolean) {
		this.yearSwitch = isYear;
	}

	ngOnDestroy() {
		this.alive = false;
	}
}
