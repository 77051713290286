<ng-container *transloco="let t; read: 'main'">
	<div class="header">
		<tg-breadcrumbs [exactMatch]="false"></tg-breadcrumbs>

		<div class="header__actions">
			<button class="info" tkButton theme="dashed" (click)="openGuide()">
				<tk-icon icon="info-clear"></tk-icon>
				Как создать магазин
			</button>
			<a tkButton theme="secondary" routerLink="./settings" [relativeTo]="route" tkTooltip="Настройки магазина">
				<tk-icon icon="settings"></tk-icon>
			</a>
			<button tkButton theme="secondary" (click)="deleteShop()" tkTooltip="Удалить магазин">
				<tk-icon icon="trash"></tk-icon>
			</button>
			<button tkButton theme="secondary" (click)="editShop()" tkTooltip="Редактировать магазин">
				<tk-icon icon="edit"></tk-icon>
			</button>
			<button tkButton theme="secondary" (click)="copyShopLink()" tkTooltip="Копировать ссылку на магазин">
				<tk-icon icon="link-copy" class="link-copy"></tk-icon>
			</button>
		</div>
	</div>

	<div class="info-block">
		<div class="info-block__row">
			<div class="column">
				<div class="info-block__name">
					{{ shop?.shopName }}
				</div>
				<div class="info-block__desc">
					{{ shop?.botUserName }}
				</div>
			</div>
			<div class="column">
				<tg-shop-beautiful-link
					[link]="shop?.shortLink"
					(addLink)="openBeautifulLinkModal()"
					(editLink)="openBeautifulLinkModal(shop?.shortLink)"
				></tg-shop-beautiful-link>
			</div>
		</div>

		<div class="info-block__row">
			<tg-shop-status-change [formControl]="statusControl"></tg-shop-status-change>

			<div class="info-block__row">
				<div class="column">
					<div class="label">Тип магазина</div>
					<div class="value">{{ shop?.productType === 'DIGITAL' ? 'Цифровой' : 'Не цифровой' }}</div>
				</div>
				<div class="column">
					<div class="label">{{ t('creation_date') }}</div>
					<div class="value">{{ shop?.createdAt | tkDateFormat : 'dd.MM.yyyy' }}</div>
				</div>
				<div class="column">
					<div class="label">{{ t('owner') }}</div>
					<div class="value">
						<a [href]="'https://t.me/' + shop?.authorTelegramUserName">
							@{{ shop?.authorTelegramUserName ? shop.authorTelegramUserName : '-' }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ng-container *transloco="let tm; read: 'menu'">
		<tg-tabs>
			<a [routerLink]="['./']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
				{{ tm('category') }}
			</a>
			<a [routerLink]="['./', 'products']" routerLinkActive="active">
				{{ tm('products') }}
			</a>

			<a [routerLink]="['./', 'history']" routerLinkActive="active"> {{ tm('orders') }} </a>
			<a [routerLink]="['./', 'analytics']" routerLinkActive="active"> {{ tm('analytics') }} </a>
			<ng-container *ngIf="shop?.productType === 'GOODS'">
				<a [routerLink]="['./', 'import']" routerLinkActive="active"> {{ tm('import') }} </a>
			</ng-container>
		</tg-tabs>
	</ng-container>

	<ng-template #loader>
		<tk-loader width="FULL"></tk-loader>
	</ng-template>

	<router-outlet></router-outlet>
</ng-container>
