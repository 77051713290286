<ng-container *transloco="let t; read: 'main'">
	<a [routerLink]="['/shop', shop.id]" class="card">
		<div class="card__title">
			{{ shop.shopName }}

			<div class="card__actions">
				<button class="delete" (click)="deleteShop($event)">
					<tk-icon icon="trash"></tk-icon>
				</button>

				<button class="edit" (click)="editShop($event)">
					<tk-icon icon="edit"></tk-icon>
				</button>
			</div>
		</div>

		<div class="card__info card__column">
			<div class="card__label">{{ t('creation_date') }}</div>
			<div class="card__text">{{ shop.createdAt | tkDateFormat : 'dd.MM.yyyy' }}</div>
		</div>

		<div class="card__info card__row card__row--justify card__row--end">
			<div class="card__column">
				<div class="card__label">{{ t('owner') }}</div>
				<div class="card__text">@{{ shop.authorTelegramUserName ? shop.authorTelegramUserName : '-' }}</div>
			</div>
			<div class="card__column">
				<tg-shop-status-change [canEdit]="false" [ngModel]="shop.shopState"></tg-shop-status-change>
			</div>
		</div>
	</a>
</ng-container>
