<div class="container" *transloco="let t; read: 'auth'">
	<div class="header">
		<div class="logo">
			<tk-icon icon="logo"></tk-icon>
		</div>
		<div #origin class="lang" (click)="openLang()">
			<span>{{lang}}</span>
			<tk-icon icon="arrow-down"></tk-icon>
			<ng-template
				cdkConnectedOverlay
				cdkConnectedOverlayHasBackdrop
				[cdkConnectedOverlayOrigin]="origin"
				[cdkConnectedOverlayOpen]="menuIsOpen"
				(backdropClick)="closeLang()"
				cdkConnectedOverlayBackdropClass="backdrop--transparent"
				[cdkConnectedOverlayPositions]="positions"
			>
				<div class="panel">
					<button (click)="setLang('ru')">Русский</button>
					<button (click)="setLang('en')">English</button>
					<button (click)="setLang('uz')">Oʻzbek</button>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="content">
		<ng-container [ngSwitch]="pageForm">
			<ng-container *ngSwitchCase="'sign-up'">
				<tk-sign-up-form></tk-sign-up-form>
			</ng-container>

			<ng-container *ngSwitchCase="'sign-in'">
				<tk-sign-in-form></tk-sign-in-form>
			</ng-container>

			<ng-container *ngSwitchCase="'forgot-pass'">
				<tk-forgot-pass-form></tk-forgot-pass-form>
			</ng-container>

			<ng-container *ngSwitchCase="'reset-pass'">
				<tk-reset-pass-form></tk-reset-pass-form>
			</ng-container>
		</ng-container>

		<div class="info">
			<div class="info__title">{{ t('hello_head') }}</div>
			<div class="info__text">{{ t('hello_text') }}</div>
			<div class="info-image"></div>
			<img src="assets/images/login-img.jpg" alt />
		</div>
	</div>
	<div class="footer">
		<div class="copyright">©TGShop, {{ currentYear }}</div>
		<ng-container></ng-container>
		<div>
			<a href="/assets/_TGShop_Пользовательское_соглашение_на_сайт_27_07_23.docx" target="_blank"
				>{{ t('service_terms') }}</a
			>
			<a href="/assets/TGShop_Политика_конфиденциальности_27_07_23.docx" target="_blank">{{ t('privacy') }}</a>
		</div>
	</div>
</div>
