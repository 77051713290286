<tg-dialog-header>
	<ng-container> История оплат </ng-container>
</tg-dialog-header>
<div class="container">
	<ng-container *ngIf="!(pos$ | async).content?.length">История платежей пуста</ng-container>
	<ng-container *ngIf="(pos$ | async).content?.length">
		<cdk-table class="table" [dataSource]="(pos$ | async).content">
			<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

			<ng-container cdkColumnDef="subscriptionName">
				<cdk-header-cell *cdkHeaderCellDef> Тариф </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.subscriptionName ?? '-' }}
				</cdk-cell>
			</ng-container>

			<ng-container cdkColumnDef="paymentDate">
				<cdk-header-cell *cdkHeaderCellDef> Дата платежа </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ (row.paymentDate | date : 'dd.MM.yyyy HH:mm') ?? '-' }}
				</cdk-cell>
			</ng-container>

			<ng-container cdkColumnDef="paymentSum">
				<cdk-header-cell *cdkHeaderCellDef> Сумма платежа, руб </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.paymentSum ?? '-' }}
				</cdk-cell>
			</ng-container>

			<ng-container cdkColumnDef="paymentStatus">
				<cdk-header-cell *cdkHeaderCellDef> Статус </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ paymentStatusName[row.paymentStatus] ?? '-' }}
				</cdk-cell>
			</ng-container>

			<cdk-row *cdkRowDef="let row; columns: columns"></cdk-row>
		</cdk-table>
	</ng-container>
	<tk-paging-controls
		[pages]="pages(pos$ | async)"
		[page]="currentSettings.page ?? 0"
		(amountChange)="setAmount($event)"
		(pageChange)="changePage($event)"
	></tk-paging-controls>
</div>
