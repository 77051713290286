import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { OfferImportResponse, ProductImportShortResponse } from '@models/generated/api';

@Component({
	selector: 'tg-shop-create',
	templateUrl: './import-detail.component.html',
	styleUrls: ['./import-detail.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDetailComponent {
	jobStateEnum = ProductImportShortResponse.jobState;
	offerStateEnum = OfferImportResponse.importState;
	productErrors = [];
	categoryErrors = [];

	constructor(@Inject(DIALOG_DATA) public data: any) {
		if (!this.data) {
			return;
		}
		this.productErrors = data.offers.filter(pos => pos.errors.length > 0);
		this.categoryErrors = data.categories.filter(
			cat =>
				cat.importState === this.offerStateEnum.INVALID ||
				cat.importState === this.offerStateEnum.WARN ||
				cat.importState === this.offerStateEnum.FAILED,
		);
	}
}
