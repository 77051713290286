/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Платеж
 */
export type SubscriptionPaymentHistoryResponse = {
	subscriptionId?: string;
	planId?: string;
	subscriptionName?: string;
	paymentDate?: string;
	paymentSum?: number;
	paymentStatus?: SubscriptionPaymentHistoryResponse.paymentStatus;
};

export namespace SubscriptionPaymentHistoryResponse {
	export enum paymentStatus {
		NEW = 'NEW',
		DONE = 'DONE',
		EXPIRED = 'EXPIRED',
		AWAIT_CONFIRM = 'AWAIT_CONFIRM',
		CONFIRM_FAILED = 'CONFIRM_FAILED',
		FAILED = 'FAILED',
	}
}
