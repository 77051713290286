import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tg-shop-beautiful-link',
	templateUrl: './shop-beautiful-link.component.html',
	styleUrls: ['./shop-beautiful-link.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopBeautifulLinkComponent {
	@Input() link: string | null = null;
	@Output() addLink = new EventEmitter<void>();
	@Output() editLink = new EventEmitter<void>();

	constructor(private clipboard: Clipboard, private toast: ToastService) {}

	copyLink(): void {
		if (this.link) {
			this.clipboard.copy(this.link);
			this.toast.success('Ссылка скопирована');
		}
	}
}
