import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TkIconModule } from '@technokratos/components-angular/icon';
import { TkFormFieldModule } from '@technokratos/components-angular/form-field';
import { TkSelectModule } from '@technokratos/components-angular/select';
import { FormsModule } from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import { ScrollIntoView } from '@utils/scrollIntoView';
import { AdaptiveDirective } from '@directives/adaptive.directive';

@Component({
	selector: 'tk-paging-controls',
	templateUrl: './paging-controls.component.html',
	styleUrls: ['./paging-controls.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class PagingControlsComponent {
	@Input() page: number | null = 0;
	@Input() pages: number[] = [];
	@Input() amount?: number = 10;

	@Output() pageChange = new EventEmitter<number>();
	@Output() amountChange = new EventEmitter<number>();

	get currentPage(): number {
		return this.page === null ? 1 : this.page;
	}

	get lastStep(): boolean {
		return !!(this.pages.length - 1) && (this.page ?? 0) + 1 !== this.pages[this.pages.length - 1];
	}

	get showPagination(): boolean {
		return this.pages?.length > 1;
	}

	emitAmount(amount: number): void {
		this.amountChange.emit(amount);
	}

	emitStep(step: number): void {
		this.pageChange.emit(step);
		ScrollIntoView('header-row');
	}
}
