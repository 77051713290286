import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';
import { ShopService } from '@services/shop.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OrderResponse, ShopResponse } from '@models/generated/api';
import deliveryType = OrderResponse.deliveryType;
import { FormControl } from '@angular/forms';
import { OrdersService } from '@services/orders.service';
import productType = ShopResponse.productType;

@Component({
	selector: 'tg-order-detail',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class OrderDetailComponent {
	columns;
	orderData$ = new BehaviorSubject<OrderResponse | null>(null);
	deliveryType = deliveryType;
	searchControl = new FormControl();
	shopName: string | null;

	infoTabActive: boolean = false;

	constructor(public shopService: ShopService, private route: ActivatedRoute, public ordersService: OrdersService) {
		this.shopName = this.route.snapshot.data['shop'].shopName;
		const shopId = this.route.snapshot.paramMap.get('id');
		const productId = this.route.snapshot.paramMap.get('orderId');

		this.ordersService.getOrder(shopId, productId).subscribe({
			next: data => {
				this.orderData$.next(data);
			},
		});

		if (!shopService.shopMoneySign.value) {
			this.shopService.getShop(shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
					this.shopService.shopInfo.next(res);
					this.columns = ['photo', 'name', 'vendorCode', 'amount', 'price', 'cost'];
					if (res.productType === productType.GOODS) {
						this.columns = ['photo', 'name', 'vendorCode', 'size', 'color', 'amount', 'price', 'cost'];
					}
				},
			});
		} else {
			this.columns = ['photo', 'name', 'vendorCode', 'amount', 'price', 'cost'];
			if (this.shopService.shopInfo.value.productType === productType.GOODS) {
				this.columns = ['photo', 'name', 'vendorCode', 'size', 'color', 'amount', 'price', 'cost'];
			}
		}
	}

	changeTab(tab: string) {
		this.infoTabActive = tab === 'info';
	}
}
