/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Ошибка при импорте товара
 */
export type OfferImportErrorResponse = {
	/**
	 * Текст ошибки
	 */
	message?: string;
	/**
	 * Тип ошибки
	 */
	errorType?: OfferImportErrorResponse.errorType;
	/**
	 * Тип ошибки
	 */
	errorTypeDescription?: string;
};

export namespace OfferImportErrorResponse {
	/**
	 * Тип ошибки
	 */
	export enum errorType {
		WARN = 'WARN',
		VALIDATION_ERROR = 'VALIDATION_ERROR',
		SAVING_ERROR = 'SAVING_ERROR',
	}
}
