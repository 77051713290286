import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs';
import { DeliveryOptionRequest, PickUpPointResponse, ShopResponse } from '@models/generated/api';
import { ShopDeliveryOptions } from '@models/ShopDeliveryOptions.interface';
import { Dialog } from '@angular/cdk/dialog';
import { ShopPickupPointComponent } from '@pages/shop-detail/shop-settings/shop-settings-delivery/shop-pickup-point/shop-pickup-point.component';
import { ToastService } from '@services/toast.service';
import deliveryOption = DeliveryOptionRequest.deliveryOption;
import productType = ShopResponse.productType;
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { errors } from '@app/utils/validations';

@Component({
	selector: 'tg-shop-settings-delivery',
	templateUrl: './shop-settings-delivery.component.html',
	styleUrls: ['./shop-settings-delivery.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsDeliveryComponent {
	options?: ShopDeliveryOptions;
	pickupPoints?: PickUpPointResponse[];
	private shopId;
	DeliveryOption = deliveryOption;

	@ViewChild('deliveryCheckbox') deliveryCheckbox: ElementRef;

	deliveryForm: FormGroup = new FormGroup({
		'deliveryCost': new FormControl(null, [Validators.required]),
		'deliveryPeriod': new FormControl(null, [Validators.required]),
		'minOrderCostForFreeDelivery': new FormControl(null, [Validators.required]),
	});

	constructor(
		private route: ActivatedRoute,
		public shopService: ShopService,
		private cdr: ChangeDetectorRef,
		private dialog: Dialog,
		private toast: ToastService,
		private router: Router,
	) {
		this.route.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
			});

		if (!shopService.shopMoneySign.value) {
			this.shopService.getShop(this.shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
					this.shopService.shopInfo.next(res);
					if (res.productType === productType.DIGITAL) {
						this.router.navigate(['/shop/' + this.shopId + '/settings/payment']);
					}
				},
			});
		} else {
			if (this.shopService.shopInfo.value.productType === productType.DIGITAL) {
				this.router.navigate(['/shop/' + this.shopId + '/settings/payment']);
			}
		}
	}

	refresh() {
		this.shopService.getShopDeliveryOptions(this.shopId).subscribe((res: any) => {
			this.options = res;
			if (this.pickupEnabled) {
				this.shopService.getShopPickupPoints(this.shopId).subscribe(points => {
					this.pickupPoints = points;
					this.cdr.markForCheck();
				});
			}
			if (res.deliveryCost) {
				this.deliveryForm.setValue({
					deliveryCost: res.deliveryCost,
					deliveryPeriod: res.deliveryPeriod,
					minOrderCostForFreeDelivery: res.minOrderCostForFreeDelivery,
				});
			}
			this.cdr.markForCheck();
		});
	}

	get deliveryEnabled(): boolean {
		return this.options?.deliveryOptions.includes(deliveryOption.DELIVERY);
	}

	set deliveryEnabled(val) {
		if (val) {
			this.options?.deliveryOptions.push(deliveryOption.DELIVERY);
		} else {
			this.options?.deliveryOptions.filter(item => item !== deliveryOption.DELIVERY);
		}
	}

	get pickupEnabled(): boolean {
		return this.options?.deliveryOptions.includes(deliveryOption.PICK_UP);
	}

	get apishipEnabled(): boolean {
		return this.options?.deliveryOptions.includes(deliveryOption.APISHIP);
	}

	toggle(option: deliveryOption, value: boolean): void {
		this.shopService.updateShopDeliveryOption(this.shopId, option, value).subscribe({
			next: () => {
				this.refresh();
			},
			error: error => {
				if (
					error.status === 400 &&
					error.error.message === 'You cannot add pick up delivery type, cause you already have apiship'
				) {
					(document.getElementById('deliveryCheckbox') as HTMLInputElement).checked = false;
					this.toast.error('Нельзя подключить тип Доставка, когда выбран тип apiShip');
					this.refresh();
				}
				this.cdr.markForCheck();
			},
		});
	}

	addPickupPoint() {
		this.dialog
			.open(ShopPickupPointComponent, { data: { shopId: this.shopId } })
			.closed.pipe(filter(Boolean))
			.subscribe(() => this.refresh());
	}

	remove(point: PickUpPointResponse) {
		this.shopService.removeShopPickUpPoint(this.shopId, point.id).subscribe(() => {
			this.refresh();
			this.toast.success('Пункт выдачи удален');
		});
	}

	edit(point: PickUpPointResponse) {
		this.dialog
			.open(ShopPickupPointComponent, { data: { shopId: this.shopId, point } })
			.closed.pipe(filter(Boolean))
			.subscribe(() => this.refresh());
	}

	errors(form: FormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	saveDelivery() {
		this.shopService.setDeliveryData(this.shopId, this.DeliveryOption.DELIVERY, this.deliveryForm.value).subscribe({
			next: () => {
				this.refresh();
				this.toast.success('Настройки доставки успешно сохранены');
			},
			error: error => {
				this.toast.error(error.error.message);
				this.cdr.markForCheck();
			},
		});
	}
}
