import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor(private toastr: ToastrService) {}

	success(message: string): ActiveToast<any> {
		return this.toastr.success(message);
	}

	error(message?: Object | string | HttpErrorResponse, config?: Partial<IndividualConfig>): ActiveToast<any> {
		return this.toastr.error(this.getError(message), undefined, config);
	}

	getError(error?: any | string): string {
		if (error == null) {
			return 'При отправке запроса произошла ошибка, попробуйте повторить запрос позднее';
		}

		if (typeof error === 'string') {
			return error;
		}

		if (error.constructor.name === 'HttpErrorResponse') {
			if ((error as HttpErrorResponse)?.status === 400) {
				return 'Проверьте правильность заполнения полей';
			}

			if ((error as HttpErrorResponse)?.status === 503) {
				return 'Сервис временно не доступен';
			}
		}

		if (error?.message != null) {
			return error?.message;
		}

		if (Array.isArray(error?.['errors'])) {
			return error?.['errors'].map(value => value.detailMessage).join('\n');
		}

		return 'При отправке запроса произошла ошибка, попробуйте повторить запрос позднее';
	}
}
