<ng-container *transloco="let t">
	<ng-container *ngIf="loading.value">
		<div class="import-loader">
			<div class="import-loader__container">
				<tk-loader width="FULL"></tk-loader>
				<h3>Файл загружается</h3>
				<p>Обычно это занимает не больше минуты.</p>
			</div>
		</div>
	</ng-container>

	<div class="actions">
		<tk-button-group>
			<a
				href="https://tgshop.io/blog/instrukciya-import-tovarov"
				target="_blank"
				tkButton
				theme="secondary"
				size="xl"
			>
				<tk-icon icon="download"></tk-icon>
				{{ t('import.instruction') }}
			</a>

			<input
				type="file"
				accept=".xml, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				id="fileDropRef"
				hidden
				(change)="uploadFile($event)"
			/>
			<label for="fileDropRef">
				<a tkButton theme="secondary" size="xl">
					<tk-icon icon="plus"></tk-icon>
					{{ t('import.import') }}
				</a>
			</label>
		</tk-button-group>
	</div>

	<div
		*ngFor="let product of import$.value.content!"
		class="file"
		(click)="openDetail(product.id)"
		[class.file--status-alert]="product.jobState === jobStateEnum.FINISHED_WARN"
		[class.file--status-error]="
			product.jobState === jobStateEnum.FINISHED_ALL_ERROR ||
			product.jobState === jobStateEnum.FAILED ||
			product.jobState === jobStateEnum.FINISHED_ERROR
		"
	>
		{{ product.fileName }}
		<div>
			<ng-container *ngIf="!product.isRunning">
				<span>{{ t('import.downloaded') }}: {{ product.importDate + 'Z' | date : 'dd.MM.yyyy HH:mm' }}</span>
				<span *ngIf="product.successCount > 0">
					{{ t('import.quantity') }}: {{ product.successCount }} {{ t('import.of') }}
					{{ product.totalCount }}
					{{ t('import.quantity_end') === 'import.quantity_end' ? null : t('import.quantity_end') }}
				</span>

				<span
					class="tag"
					*ngIf="
						product.jobState === jobStateEnum.FINISHED_ERROR ||
						product.jobState === jobStateEnum.FINISHED_WARN ||
						product.jobState === jobStateEnum.FINISHED_ALL_ERROR ||
						product.jobState === jobStateEnum.FAILED
					"
				>
					<tk-icon icon="danger"></tk-icon>
					{{ product.jobStateDescription }}
				</span>
			</ng-container>

			<span *ngIf="product.isRunning" class="loading">
				<img src="assets/images/loader.svg" alt />
			</span>
		</div>
	</div>

	<tk-paging-controls
		[pages]="pages(import$ | async)"
		[page]="currentSettings.page ?? 0"
		(amountChange)="setAmount($event)"
		(pageChange)="changePage($event)"
	></tk-paging-controls>
</ng-container>
