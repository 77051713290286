/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма сохранения файла
 */
export type FileRequest = {
	file: Blob;
	/**
	 * Тип файла
	 */
	fileType?: FileRequest.fileType;
};

export namespace FileRequest {
	/**
	 * Тип файла
	 */
	export enum fileType {
		PRODUCT_PHOTO = 'PRODUCT_PHOTO',
		NEWSLETTER_PHOTO = 'NEWSLETTER_PHOTO',
		WELCOME_PHOTO = 'WELCOME_PHOTO',
		FEED_FILE = 'FEED_FILE',
	}
}
