import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductResponse, ShopResponse } from '@models/generated/api';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { ToastService } from '@services/toast.service';
import { BehaviorSubject, filter, Observable, switchMap, takeUntil } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { ProductService } from '@services/product.service';
import { FormControl } from '@angular/forms';
import { PaginatedResponse, PaginationModel } from '@models/pagination.model';
import { PaginationService } from '@services/pagination.service';
import { Dialog } from '@angular/cdk/dialog';
import { DestroyService } from '@services/destroy.service';
import { ProductDeleteComponent } from '@components/modals/product-delete/product-delete.component';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent extends PaginationModel {
	shop$: Observable<ShopResponse>;
	columns = ['select', 'photo', 'productName', 'amount', 'priority', 'visibility', 'actions'];

	menuIsOpen: string = '';
	selection = new SelectionModel<ProductResponse[]>(true, []);

	productList$ = new BehaviorSubject<PaginatedResponse<ProductResponse[]>>({});
	shopId: string | null = null;

	search: boolean = false;
	searchControl = new FormControl();

	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'top',
			overlayX: 'center',
			overlayY: 'bottom',
			offsetY: 10,
		},
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private shopService: ShopService,
		private productService: ProductService,
		private toast: ToastService,
		private clipboard: Clipboard,
		private pagination: PaginationService,
		private dialog: Dialog,
		private destroy$: DestroyService,
	) {
		super();

		this.shopId = this.route.snapshot.paramMap.get('id');
		this.shop$ = this.shopService.shopInfo.asObservable();

		if (
			this.pagination.currentProductPage.value !== this.currentSettings.page &&
			this.pagination.currentShopId.value === this.shopId
		) {
			this.currentSettings.page = this.pagination.currentProductPage.value;
		}

		this.refresh();
	}

	refresh() {
		this.shopService.getProducts(this.shopId, this.currentSettings, this.searchControl.value).subscribe({
			next: (res: any) => {
				this.pagination.currentProductPage.next(this.currentSettings.page);
				this.pagination.currentShopId.next(this.shopId);

				this.productList$.next(res);
				this.selection.clear();
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	onChangeSearch() {
		if (this.searchControl.value?.length < 3) {
			if (this.search) {
				this.search = false;
				this.refresh();
			}
			return;
		} else {
			this.search = true;
		}
		if (this.search) {
			this.refresh();
		}
	}

	openMenu(id: string, event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.menuIsOpen = id;
	}

	toggleVisibility = (id: string, event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();

		this.productService.changeProductVisibility(this.shopId, id).subscribe({
			next: res => {
				if (res) {
					this.toast.success('Товары открыты и теперь доступны пользователю');
				} else {
					this.toast.success('Товары скрыты и не будут показываться пользователю');
				}
				this.refresh();
			},
			error: err => {
				this.toast.error('Произошла ошибка');
			},
		});
	};

	trackByFn(index: number, item: any) {
		return item.productId;
	}

	closeMenu() {
		this.menuIsOpen = '';
	}

	copyLink(productId: string, shortLink?: string) {
		this.clipboard.copy(`${shortLink || ''}?startapp=productId` + productId);
		this.toast.success('Ссылка на товар скопирована');
		this.closeMenu();
	}

	copyProduct(product) {
		this.router.navigate(['/shop/' + this.shopId + '/create-product'], { queryParams: { product: product } });
	}

	deleteProduct(productId: string) {
		this.productService.deleteProduct(this.shopId, productId).subscribe({
			next: () => {
				this.toast.success('Товар успешно удалён');
				if (this.productList$.value.content.length === 1 && this.currentSettings.page !== 0) {
					this.currentSettings.page = this.currentSettings.page - 1;
				}
				this.refresh();
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	editProduct(productId: string) {
		this.router.navigate(['/shop/' + this.shopId + '/products/' + productId + '/edit-product']);
	}

	multipleDelete() {
		const list = this.selection.selected.map((item: any) => item.productId);

		this.dialog
			.open(ProductDeleteComponent)
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(Boolean),
				switchMap(() => this.productService.deleteProducts(this.shopId, list)),
			)
			.subscribe({
				next: () => {
					this.toast.success('Товары успешно удалены');
					if (
						this.selection.selected.length === this.productList$.value.content.length &&
						this.currentSettings.page !== 0
					) {
						this.currentSettings.page = this.currentSettings.page - 1;
					}
					this.selection.clear();
					this.refresh();
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
			});
	}

	multipleHide(isVisible: boolean) {
		const list = this.selection.selected.map((item: any) => item.productId);

		this.productService.changeProductListVisibility(this.shopId, { isVisible, productIds: list }).subscribe({
			next: res => {
				this.toast.success(
					isVisible
						? 'Товары открыты и теперь доступны пользователю'
						: 'Товары скрыты и не будут показываться пользователю',
				);
				this.refresh();
			},
			error: err => {
				this.toast.error('Произошла ошибка');
			},
		});
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.productList$.getValue()?.content.length;
		return numSelected === numRows;
	}

	toggleAllRows() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}
		this.selection.select(...this.productList$.getValue().content);
	}

	select(row) {
		this.selection.toggle(row);
	}

	openProduct(product) {
		this.router.navigate(['/shop/' + this.shopId + '/products/' + product.productId]);
	}

	override changePage(page: number): void {
		super.changePage(page);
		this.refresh();
	}

	clickCreateBtn = () => {
		// @ts-ignore
		ym(environment.metricId, 'reachGoal', 'click_create_item');
	};
	protected readonly environment = environment;
}
