/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Тип товаров
 */
export type ProductTypeResponse = {
	/**
	 * Тип товаров код
	 */
	productType?: ProductTypeResponse.productType;
	/**
	 * Тип товаров локализованное название
	 */
	productTypeDescription?: string;
};

export namespace ProductTypeResponse {
	/**
	 * Тип товаров код
	 */
	export enum productType {
		GOODS = 'GOODS',
		DIGITAL = 'DIGITAL',
	}
}
