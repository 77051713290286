import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.authService.subscriptionInfo().status) {
			return this.router.navigate(['/shop']);
		}
		return true;
	}
}
