import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TkDatepickerModule } from '@technokratos/components-angular/datepicker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TkIconModule } from '@technokratos/components-angular/icon';
import { AuthComponent } from '@pages/auth/auth.page';
import { TkFormFieldModule } from '@technokratos/components-angular/form-field';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { TkInputModule } from '@technokratos/components-angular/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EyeComponent } from '@components/ui/eye/eye.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UrlInterceptorService } from '@services/url-interceptor.service';
import { ShopListComponent } from '@pages/shop-list/shop-list.component';
import { PageComponent } from '@components/ui/page/page.component';
import { MainComponent } from '@components/ui/main/main.component';
import { NavPanelComponent } from '@components/ui/nav-panel/nav-panel.component';
import { PageTitleComponent } from '@components/ui/page-title/page-title.component';
import { LetDirective } from '@directives/let.directive';
import { LocationBackDirective } from '@directives/location-back.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { ShopCreateComponent } from '@components/modals/shop-create/shop-create.component';
import { DialogModule } from '@angular/cdk/dialog';
import { DialogCloseDirective } from '@directives/dialog-close.directive';
import { DialogHeaderComponent } from '@components/modals/dialog-header/dialog-header.component';
import { DialogBodyComponent } from '@components/modals/dialog-body/dialog-body.component';
import { DialogFooterComponent } from '@components/modals/dialog-footer/dialog-footer.component';
import { SignUpFormComponent } from '@pages/auth/forms/sign-up/sign-up.component';
import { PasswordErrorComponent } from '@components/ui/errors/password-error/password-error.component';
import { EmailErrorComponent } from '@components/ui/errors/email-error/email-error.component';
import { SignInFormComponent } from '@pages/auth/forms/sign-in/sign-in.component';
import { ForgotPassFormComponent } from '@pages/auth/forms/forgot-pass/forgot-pass.component';
import { ResetPassFormComponent } from '@pages/auth/forms/reset-pass/reset-pass.component';
import { AuthInterceptorService } from '@services/auth-interceptor.service';
import { ShopCardComponent } from '@pages/shop-list/shop-card/shop-card.component';
import { ShopDeleteComponent } from '@components/modals/shop-delete/shop-delete.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from '@components/ui/toast/toast.component';
import { ShopDetailComponent } from '@pages/shop-detail/shop-detail.component';
import { BreadcrumbsComponent } from '@components/ui/breadcrumbs/breadcrumbs.component';
import { HomeComponent } from '@pages/home/home.component';
import { ShopStatusChangeComponent } from '@components/forms/shop-status-change/shop-status-change.component';
import { ProductsComponent } from '@pages/shop-detail/products/products.component';
import { CdkTableModule } from '@angular/cdk/table';
import { GetTitlePhotoPipe } from '@pipes/get-title-photo.pipe';
import { ReduceAmountPipe } from '@pipes/reduce-amount.pipe';
import { GetFilePipe } from '@pipes/get-file.pipe';
import { TkCheckboxModule } from '@technokratos/components-angular/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from '@components/ui/loader/loader.component';
import { CreateProductPageComponent } from '@pages/shop-detail/create-product/create-product.page';
import { TkSelectModule } from '@technokratos/components-angular/select';
import { ProductSelectTypeComponent } from '@components/modals/product-select-type/product-select-type.component';
import { ProductSelectColorsComponent } from '@components/modals/product-select-colors/product-select-colors.component';
import { ProductDetailComponent } from '@pages/shop-detail/product-detail.component/product-detail.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TkTooltipModule } from '@technokratos/components-angular/tooltip';
import { ShopSettingsComponent } from './pages/shop-detail/shop-settings/shop-settings.component';
import { ShopSettingsDeliveryComponent } from './pages/shop-detail/shop-settings/shop-settings-delivery/shop-settings-delivery.component';
import { ShopSettingsPaymentComponent } from './pages/shop-detail/shop-settings/shop-settings-payment/shop-settings-payment.component';
import { TabsComponent } from './components/ui/tabs/tabs.component';
import { ShopPickupPointComponent } from './pages/shop-detail/shop-settings/shop-settings-delivery/shop-pickup-point/shop-pickup-point.component';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { SearchPipe } from '@pipes/search.pipe';
import { ProductDeleteComponent } from '@components/modals/product-delete/product-delete.component';
import { InfoPlateComponent } from './components/ui/info-plate/info-plate.component';
import { OrderHistoryComponent } from '@pages/shop-detail/order-history/order-history.component';
import { OrderDetailComponent } from '@pages/shop-detail/order-detail/order-detail.component';
import { AnalyticsComponent } from '@pages/shop-detail/analytics/analytics.component';
import { DatePeriodComponent } from '@components/forms/date-period/date-period.component';
import { CommonErrorComponent } from '@components/ui/errors/common-error/common-error.component';
import { InputTrimDirective } from '@directives/input-trim.directive';
import { OnlynumberDirective } from '@directives/apply-numbers.directive';
import { PagingControlsComponent } from '@components/forms/paging-controls/paging-controls.component';
import { AdaptiveDirective } from '@directives/adaptive.directive';
import { ImportComponent } from '@pages/shop-detail/import/import.component';
import { ImportDetailComponent } from '@components/modals/import-detail/import-detail.component';
import { TkAutocompleteModule } from '@technokratos/components-angular/autocomplete';
import { PaymentAtolComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-atol/payment-atol.component';
import { PaymentUkassaComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-ukassa/payment-ukassa.component';
import { ShopSettingsCrmComponent } from '@pages/shop-detail/shop-settings/shop-settings-retail/shop-settings-crm.component';
import { ShopDeliveryApishipComponent } from '@pages/shop-detail/shop-settings/shop-settings-delivery/shop-delivery-apiship/shop-delivery-apiship.component';
import { TranslocoRootModule } from './transloco-root.module';
import { TkRadioModule } from '@technokratos/components-angular/radio';
import { NgxMaskModule } from 'ngx-mask';
import { StartVideoComponent } from '@components/modals/start-video/start-video.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TarifsComponent } from '@pages/user/tarifs/tarifs.component';
import { SubscriptionsComponent } from '@pages/user/subscriptions/subscriptions.component';
import { SubscribeHistoryComponent } from '@components/modals/subscribe-history/subscribe-history.component';
import { ProviderPointComponent } from '@components/modals/provider-point/provider-point.component';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import { ShopSettingsNewsletterComponent } from '@pages/shop-detail/shop-settings/shop-settings-newsletter/shop-settings-newsletter.component';
import { NewsletterCreateComponent } from '@components/modals/newsletter-create/newsletter-create.component';
import { DatetimeComponent } from '@components/forms/datetime/datetime.component';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { MarkdownModule } from 'ngx-markdown';
import { TrialOverComponent } from '@components/modals/trial-over/trial-over.component';
import { EditProductPageComponent } from '@pages/shop-detail/edit-product/edit-product.page';
import { ShopSettingsPromoComponent } from '@pages/shop-detail/shop-settings/shop-settings-promo/shop-settings-promo.component';
import { PromoCreateComponent } from '@components/modals/promo-create/promo-create.component';
import { ShopSettingsHelloComponent } from '@pages/shop-detail/shop-settings/shop-settings-hello/shop-settings-hello.component';
import { ShopSettingsOrderFieldsComponent } from '@pages/shop-detail/shop-settings/shop-settings-order-fields/shop-settings-order-fields.component';
import { PaymentTinkoffComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-tinkoff/payment-tinkoff.component';
import { PaymentCustomComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-custom/payment-custom.component';
import { PaymentDeleteComponent } from '@components/modals/payment-delete/payment-delete.component';

const mapConfig: YaConfig = {
	apikey: '2446b987-df7d-4664-b556-b4e57ef18cc2',
	lang: 'ru_RU',
};

@NgModule({
	declarations: [
		AppComponent,
		AuthComponent,
		ShopListComponent,
		PageComponent,
		MainComponent,
		NavPanelComponent,
		PageTitleComponent,
		LetDirective,
		ShopCreateComponent,
		StartVideoComponent,
		DialogHeaderComponent,
		DialogBodyComponent,
		DialogFooterComponent,
		DialogCloseDirective,
		SignUpFormComponent,
		SignInFormComponent,
		ForgotPassFormComponent,
		ResetPassFormComponent,
		ShopCardComponent,
		ShopDeleteComponent,
		ToastComponent,
		LoaderComponent,
		ShopDetailComponent,
		BreadcrumbsComponent,
		HomeComponent,
		ShopStatusChangeComponent,
		ProductsComponent,
		GetTitlePhotoPipe,
		ReduceAmountPipe,
		GetFilePipe,
		CreateProductPageComponent,
		EditProductPageComponent,
		ProductSelectTypeComponent,
		ProductSelectColorsComponent,
		ProductDetailComponent,
		ShopSettingsComponent,
		ShopSettingsDeliveryComponent,
		ShopSettingsPaymentComponent,
		TabsComponent,
		ShopPickupPointComponent,
		FormatAddressPipe,
		SearchPipe,
		ProductDeleteComponent,
		InfoPlateComponent,
		OrderHistoryComponent,
		OrderDetailComponent,
		AnalyticsComponent,
		DatePeriodComponent,
		DatetimeComponent,
		OnlynumberDirective,
		ImportComponent,
		ImportDetailComponent,
		PaymentAtolComponent,
		PaymentUkassaComponent,
		PaymentTinkoffComponent,
		ShopSettingsCrmComponent,
		ShopDeliveryApishipComponent,
		PagingControlsComponent,
		TarifsComponent,
		SubscriptionsComponent,
		SubscribeHistoryComponent,
		ProviderPointComponent,
		ShopSettingsNewsletterComponent,
		NewsletterCreateComponent,
		TrialOverComponent,
		ShopSettingsPromoComponent,
		PromoCreateComponent,
		ShopSettingsHelloComponent,
		ShopSettingsOrderFieldsComponent,
		PaymentCustomComponent,
		PaymentDeleteComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		TkIconModule,
		TkFormFieldModule,
		TkButtonModule,
		TkInputModule,
		ReactiveFormsModule,
		EyeComponent,
		HttpClientModule,
		OverlayModule,
		DialogModule,
		PasswordErrorComponent,
		EmailErrorComponent,
		CommonErrorComponent,
		InputTrimDirective,
		TkDatepickerModule,
		BrowserAnimationsModule,
		TkCheckboxModule,
		ToastrModule.forRoot({
			toastComponent: ToastComponent,
			extendedTimeOut: 0,
			timeOut: 3000,
			tapToDismiss: false,
			newestOnTop: true,
			preventDuplicates: true,
			positionClass: 'toast-bottom-center',
		}),
		FormsModule,
		CdkTableModule,
		TkSelectModule,
		ClipboardModule,
		TkTooltipModule,
		AdaptiveDirective,
		TkAutocompleteModule,
		TranslocoRootModule,
		TkRadioModule,
		NgxMaskModule.forRoot(),
		YouTubePlayerModule,
		AngularYandexMapsModule.forRoot(mapConfig),
		AngularMarkdownEditorModule.forRoot({ iconlibrary: 'fa' }),
		MarkdownModule.forRoot(),
	],
	providers: [
		LetDirective,
		LocationBackDirective,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptorService,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: AuthInterceptorService,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
