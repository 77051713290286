import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EmailValidators, errors } from '@utils/validations';
import { AuthService } from '@services/auth.service';
import { ToastService } from '@services/toast.service';
import { LoadingService } from '@services/loading.service';

@Component({
	selector: 'tk-forgot-pass-form',
	templateUrl: './forgot-pass.component.html',
	styleUrls: ['./forgot-pass.component.sass', '../common.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LoadingService],
})
export class ForgotPassFormComponent {
	sendingRepeat: boolean = false;
	repeatButton: boolean = false;
	mail: string = '';
	timeLeft: number = 60;
	interval: any;

	constructor(
		private cdk: ChangeDetectorRef,
		private authService: AuthService,
		private toast: ToastService,
		public loading$: LoadingService,
	) {}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	form: UntypedFormGroup = new UntypedFormGroup({
		'email': new UntypedFormControl(null, [Validators.required, ...EmailValidators]),
	});

	submitForm = () => {
		this.loading$.next(true);
		this.mail = this.form.value.email;
		this.authService
			.passwordRecovery(this.form.value)
			.subscribe({
				next: () => {
					this.sendingRepeat = true;
					this.repeatButton = false;
					this.timeLeft = 60;
					clearInterval(this.interval);

					this.interval = setInterval(() => {
						if (this.timeLeft == 1) {
							this.repeatButton = true;
							this.cdk.markForCheck();
						}
						if (this.timeLeft > 0) {
							this.timeLeft--;
							this.cdk.markForCheck();
						}
					}, 1000);
				},
				error: err => {
					this.toast.error(
						err.error?.exceptionName === 'AccountNotFoundException'
							? 'Пользователь с таким email не найден'
							: err.error?.message,
					);
				},
			})
			.add(() => {
				this.loading$.next(false);
			});
	};

	get isDisabled(): boolean {
		return this.form.invalid;
	}
}
