import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from '@models/breadcrumb.model';
import { BreadcrumbService } from '@services/breadcrumb.service';

@Component({
	selector: 'tg-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
	@Input() exactMatch = true;
	breadcrumbs$: Observable<Breadcrumb[]> = this.breadcrumbService.breadcrumbs$;

	constructor(private readonly breadcrumbService: BreadcrumbService) {}
}
