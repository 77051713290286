import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ColorResponse } from '@models/generated/api';
import { BehaviorSubject } from 'rxjs';
import { ProductService } from '@services/product.service';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tg-shop-create',
	templateUrl: './product-select-colors.component.html',
	styleUrls: ['./product-select-colors.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectColorsComponent {
	colorsList$ = new BehaviorSubject<ColorResponse[]>([]);
	selectedColors: ColorResponse[] = [];

	constructor(
		private dialogRef: DialogRef,
		private productService: ProductService,
		private toast: ToastService,
		@Inject(DIALOG_DATA) public dialogData: any,
	) {
		if (this.dialogData.selectedColors) {
			this.selectedColors = this.dialogData.selectedColors;
		}
		this.productService.getColors().subscribe({
			next: data => {
				this.colorsList$.next(data);
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	selectColor(color: ColorResponse, isActive: boolean) {
		isActive
			? this.selectedColors.splice(
					this.selectedColors.findIndex(item => item.id === color.id),
					1,
			  )
			: this.selectedColors.push(color);
	}

	isActiveColor(selectedColor: ColorResponse): boolean {
		return this.selectedColors?.filter((color: ColorResponse) => color.id === selectedColor.id).length > 0;
	}

	submit(): void {
		this.dialogRef.close(this.selectedColors);
	}

	close(): void {
		this.dialogRef.close(null);
	}
}
