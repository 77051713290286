import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShopWelcomeRequest, ShopWelcomeTextResponse } from '@models/generated/api';

@Injectable({
	providedIn: 'root',
})
export class HelloService {
	constructor(private http: HttpClient) {}

	getHello(shopId: string): Observable<ShopWelcomeTextResponse> {
		return this.http.get<ShopWelcomeTextResponse>(`shops/${shopId}/settings/welcome-text`);
	}

	postHello(shopId: string, body: ShopWelcomeRequest): Observable<ShopWelcomeRequest> {
		return this.http.post<ShopWelcomeRequest>(`shops/${shopId}/settings/welcome-text`, body);
	}
}
