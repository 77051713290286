import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { LangService } from '@services/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ShopService } from '@services/shop.service';

@Component({
	selector: 'tg-nav-panel',
	templateUrl: './nav-panel.component.html',
	styleUrls: ['./nav-panel.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPanelComponent {
	lang;
	menuIsOpen: boolean;
	userMenuIsOpen: boolean;

	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'top',
			overlayX: 'start',
			overlayY: 'bottom',
			offsetY: 10,
		},
	];

	constructor(
		private authService: AuthService,
		private router: Router,
		private translocoService: TranslocoService,
		private langService: LangService,
		private cdr: ChangeDetectorRef,
		public shopService: ShopService,
	) {
		this.lang = translocoService.getActiveLang();
		this.cdr.markForCheck();
	}

	logout(): void {
		this.authService.logout();
		this.router.navigate(['/sign-in']);
	}

	openLang() {
		this.menuIsOpen = true;
	}

	setLang(lang: string): void {
		this.translocoService.setActiveLang(lang);
		this.langService.setDefaultLang(lang);
		this.menuIsOpen = false;
		this.lang = this.translocoService.getActiveLang();
		this.cdr.markForCheck();
	}

	closeLang() {
		this.menuIsOpen = false;
	}

	selectUserMenu(open: boolean) {
		this.userMenuIsOpen = open;
	}
}
