/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Возможности тарифа
 */
export type PlanFeatureResponse = {
	/**
	 * Идентификатор
	 */
	id?: string;
	/**
	 * Название
	 */
	featureName?: string;
	/**
	 * Тип
	 */
	type?: PlanFeatureResponse.type;
	/**
	 * Флаг доступности
	 */
	isAvailable?: boolean;
};

export namespace PlanFeatureResponse {
	/**
	 * Тип
	 */
	export enum type {
		MAIN = 'MAIN',
		EXTRA = 'EXTRA',
	}
}
