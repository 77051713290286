import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormErrorModel } from '@components/ui/errors/form-error.model';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: '[tkEmailError]',
	templateUrl: './email-error.component.html',
	styleUrls: ['./email-error.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	inputs: ['errors'],
	imports: [CommonModule],
})
export class EmailErrorComponent extends FormErrorModel {}
