import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { Dialog } from '@angular/cdk/dialog';
import { ToastService } from '@services/toast.service';
import { BehaviorSubject, map, tap } from 'rxjs';
import { retailUrlValidator, errors } from '@utils/validations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-shop-settings-crm',
	templateUrl: './shop-settings-crm.component.html',
	styleUrls: ['./shop-settings-crm.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsCrmComponent {
	shopId: string;
	data = new BehaviorSubject<any>(null);
	currentValue: any = true;
	isActive: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private shopService: ShopService,
		private cdr: ChangeDetectorRef,
		private dialog: Dialog,
		private toast: ToastService,
		private sanitizer: DomSanitizer,
	) {
		this.route.parent.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
			});
	}

	refresh() {
		this.shopService.getShopCrmRetail(this.shopId).subscribe(
			res => {
				this.form.patchValue(res);
				this.data.next(res);
				this.isActive = res.isActive;
				this.cdr.markForCheck();
			},
			err => {
				if (err.status === 404) {
					this.currentValue = false;
					this.cdr.markForCheck();
				}
			},
		);
	}

	form: UntypedFormGroup = new UntypedFormGroup({
		'crm': new UntypedFormControl('retail'),
		'host': new UntypedFormControl('', [Validators.required, retailUrlValidator]),
		'apiKey': new UntypedFormControl('', [Validators.required]),
	});

	get isDisabled(): boolean {
		return this.form.invalid;
	}

	setRetailActive(activity: boolean) {
		this.shopService.updateShopCrmRetailActive(this.shopId, { flag: activity }).subscribe({
			next: () => {
				this.toast.success(activity ? 'Успешно активировано' : 'Успешно деактивировано');
			},
			error: err => {
				console.log(err);
			},
		});
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	submit() {
		if (this.isDisabled) {
			return;
		}

		if (this.currentValue) {
			this.shopService.updateShopCrmRetail(this.shopId, this.form.value).subscribe({
				next: () => {
					this.toast.success('Данные сохранены');
				},
				error: err => {
					this.toast.error(
						err.message.includes('symbol code: tgshop')
							? 'По данному домену и API ключу отсутствует магазин с названием "TGShop" и символьным кодом "tgshop"'
							: 'Некорректные данные',
					);
				},
			});
		} else {
			this.shopService.postShopCrmRetail(this.shopId, this.form.value).subscribe({
				next: res => {
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'crm_integrated');
					this.toast.success('Данные сохранены');
					this.currentValue = true;
					this.data.next(res);
					this.isActive = true;
					this.cdr.markForCheck();
				},
				error: err => {
					this.toast.error(
						err.message.includes('symbol code: tgshop')
							? 'По данному домену и API ключу отсутствует магазин с названием "TGShop" и символьным кодом "tgshop"'
							: 'Некорректные данные',
					);
				},
			});
		}
	}
}
