import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { LangService } from '@services/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
	selector: 'tk-auth',
	templateUrl: './auth.page.html',
	styleUrls: ['./auth.page.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent {
	pageForm: string = '';
	lang;
	menuIsOpen: boolean;
	currentYear = new Date().getFullYear();

	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 10,
		},
	];

	constructor(
		public activatedRoute: ActivatedRoute,
		private translocoService: TranslocoService,
		private langService: LangService,
		private cdr: ChangeDetectorRef,
	) {
		this.lang = translocoService.getActiveLang();
		this.cdr.markForCheck();
	}

	ngOnInit() {
		this.activatedRoute.data.subscribe(data => {
			data && (this.pageForm = data?.['form']);
		});
	}

	openLang() {
		this.menuIsOpen = true;
	}

	setLang(lang: string): void {
		this.translocoService.setActiveLang(lang);
		this.langService.setDefaultLang(lang);
		this.menuIsOpen = false;
		this.lang = this.translocoService.getActiveLang();
		this.cdr.markForCheck();
	}

	closeLang() {
		this.menuIsOpen = false;
	}
}
