<ng-container *transloco="let t">
	<!--	tkassa-->
	<div class="payment">
		<div *ngIf="tinkoffData" class="info-block">
			<div class="payment__wrap">
				<div class="payment__header">
					<tk-icon icon="tbank"></tk-icon>
					<div>
						<div class="payment__name">Т-касса</div>
						<div class="payment__type">Онлайн оплата</div>
					</div>
				</div>

				<button class="options" cdkOverlayOrigin #origin (click)="openPaymentControls($event, 'tinkoff')">
					<tk-icon icon="options-vertical"></tk-icon>
				</button>

				<ng-template
					cdkConnectedOverlay
					cdkConnectedOverlayHasBackdrop
					[cdkConnectedOverlayOrigin]="origin"
					[cdkConnectedOverlayOpen]="paymentControlsOpen === 'tinkoff'"
					(backdropClick)="closePaymentControls()"
					cdkConnectedOverlayBackdropClass="backdrop--transparent"
					[cdkConnectedOverlayPositions]="positions"
				>
					<div class="panel">
						<button (click)="openTinkoffModal(tinkoffData)">Редактировать</button>
						<button (click)="deletePayment('tinkoff')">Удалить</button>
					</div>
				</ng-template>
			</div>

			<div class="payment__line">
				<div class="payment__info">
					<div>Идентификатор терминала</div>
					<p>{{ tinkoffData.merchantKey }}</p>
				</div>
				<div class="payment__info">
					<div>НДС</div>
					<p>{{ tinkoffData.vatCode?.value }}</p>
				</div>
				<div class="payment__info">
					<div>ФФД</div>
					<p>{{ tinkoffData.ffdVersion?.value }}</p>
				</div>
			</div>

			<div class="payment__line">
				<div class="payment__info">
					<div>Система налогооблажения</div>
					<p>{{ tinkoffData.taxation?.value }}</p>
				</div>
			</div>
		</div>
	</div>

	<!--	ukassa-->
	<div class="payment">
		<div *ngIf="ukassaData" class="info-block">
			<div class="payment__wrap">
				<div class="payment__header">
					<img src="assets/images/ukassa.png" />
					<div>
						<div class="payment__name">Юкасса</div>
						<div class="payment__type">Онлайн оплата</div>
					</div>
				</div>

				<button class="options" cdkOverlayOrigin #origin (click)="openPaymentControls($event, 'ukassa')">
					<tk-icon icon="options-vertical"></tk-icon>
				</button>

				<ng-template
					cdkConnectedOverlay
					cdkConnectedOverlayHasBackdrop
					[cdkConnectedOverlayOrigin]="origin"
					[cdkConnectedOverlayOpen]="paymentControlsOpen === 'ukassa'"
					(backdropClick)="closePaymentControls()"
					cdkConnectedOverlayBackdropClass="backdrop--transparent"
					[cdkConnectedOverlayPositions]="positions"
				>
					<div class="panel">
						<button (click)="openUkassaModal(ukassaData)">Редактировать</button>
						<button (click)="deletePayment('ukassa')">Удалить</button>
					</div>
				</ng-template>
			</div>

			<div class="payment__line">
				<div class="payment__info">
					<div>Токен</div>
					<p>{{ ukassaData.paymentToken }}</p>
				</div>
				<div class="payment__info">
					<div>Ставка НДС</div>
					<p>{{ ukassaData.vatCode.value }}</p>
				</div>
			</div>
		</div>
	</div>

	<!--	custom-->
	<div *ngIf="customData" class="payment">
		<ng-container *ngFor="let payment of customData">
			<div class="info-block">
				<div class="payment__wrap">
					<div class="payment__header">
						<div>
							<div class="payment__name">{{ payment.name }}</div>
							<div class="payment__type">Свой способ оплаты</div>
						</div>
					</div>

					<button class="options" cdkOverlayOrigin #origin (click)="openPaymentControls($event, payment.id)">
						<tk-icon icon="options-vertical"></tk-icon>
					</button>

					<ng-template
						cdkConnectedOverlay
						cdkConnectedOverlayHasBackdrop
						[cdkConnectedOverlayOrigin]="origin"
						[cdkConnectedOverlayOpen]="paymentControlsOpen === payment.id"
						(backdropClick)="closePaymentControls()"
						cdkConnectedOverlayBackdropClass="backdrop--transparent"
						[cdkConnectedOverlayPositions]="positions"
					>
						<div class="panel">
							<button (click)="openCustomPaymentModal(paymentData)">Редактировать</button>
							<button (click)="deletePayment(payment.id)">Удалить</button>
						</div>
					</ng-template>
				</div>

				<div class="payment__line">
					<div class="payment__info">
						<div>Размер скидки</div>
						<p>
							{{ payment.discountValue }}
							{{ payment.discountType === 'DISCOUNT' ? shopService.shopMoneySign.value : '%' }}
						</p>
					</div>
				</div>

				<div class="payment__line">
					<div class="payment__info">
						<div>Описание</div>
						<p>{{ payment.description }}</p>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<tk-button-group>
		<button
			[disabled]="ukassaData || tinkoffData"
			type="button"
			#origin
			class="add-button"
			(click)="openMenu($event)"
		>
			<tk-icon icon="plus"></tk-icon> Онлайн оплата
		</button>
		<ng-template
			cdkConnectedOverlay
			cdkConnectedOverlayHasBackdrop
			[cdkConnectedOverlayOrigin]="origin"
			[cdkConnectedOverlayOpen]="menuIsOpen"
			(backdropClick)="closeMenu()"
			cdkConnectedOverlayBackdropClass="backdrop--transparent"
			[cdkConnectedOverlayPositions]="positions"
		>
			<div class="panel panel--width">
				<button (click)="openTinkoffModal()">Т-касса</button>
				<button (click)="openUkassaModal()">Юкасса</button>
			</div>
		</ng-template>
		<button type="button" (click)="openCustomPaymentModal()" class="add-button">
			<tk-icon icon="plus"></tk-icon> Свой способ оплаты
		</button>
	</tk-button-group>
</ng-container>
