import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploadResponse } from '@models/generated/api';

@Injectable({
	providedIn: 'root',
})
export class ImageService {
	constructor(private http: HttpClient) {}

	uploadImage(file: File, type: string): Observable<FileUploadResponse> {
		let fd = new FormData();
		fd.append('file', file);
		fd.append('fileType', type);

		return this.http.post('files', fd);
	}
}
