<tg-dialog-header> Новая рассылка </tg-dialog-header>

<tg-dialog-body>
	<form [formGroup]="form" (ngSubmit)="submit()">
		<tk-form-field>
			<tk-label>Название рассылки</tk-label>

			<input type="text" tkInput formControlName="name" />
			<tk-error *ngIf="errors(form, 'name') as errorField" tkCommonError [errors]="errorField"></tk-error>
		</tk-form-field>

		<angular-markdown-editor
			textareaId="area1"
			[rows]="12"
			name="markdownText"
			[ngModel]="markdownText | async"
			(ngModelChange)="markdownText.next($event)"
			[options]="editorOptions"
			[locale]="locale"
			(onChange)="onChange($event.detail.eventData)"
			[ngModelOptions]="{ standalone: true }"
		>
		</angular-markdown-editor>

		<h4>Прикрепить фото?</h4>

		<div class="tooltip tooltip--file dropzone">
			<div>
				<p>{{ photos.length === 10 ? 'Максимум фотографий' : 'До 10 фото' }}</p>
				<p>Формат: PNG, JPEG, JPG, GIF, WEBP. Рекомендуемое разрешение 1080×1440</p>
				<p>Размер: объём файла не должен превышать 5 MB</p>
			</div>

			<label *ngIf="photos.length < 10" for="photos">
				<tk-icon icon="button"></tk-icon>
			</label>

			<input
				type="file"
				#defaultFileDropRef
				id="photos"
				multiple
				[hidden]="photos.length === 10"
				(change)="uploadPhoto($event, false)"
			/>
		</div>

		<ng-container *ngIf="photos">
			<div class="photo__container">
				<div class="photo" *ngFor="let photo of photos">
					<img [src]="photo.photoId | getFile | async" alt />
					<div class="photo__delete" (click)="deletePhoto(photo.photoId)">
						<tk-icon icon="cross"></tk-icon>
					</div>
				</div>
			</div>
		</ng-container>

		<h4>Кому отправить?</h4>
		<tk-form-field>
			<tk-label>Пользовательский сегмент</tk-label>
			<tk-select formControlName="userGroup">
				<tk-option [value]="userGroup.ALL"> Все </tk-option>
				<tk-option [value]="userGroup.ORDER_CREATED"> Оформили заказ </tk-option>
			</tk-select>
		</tk-form-field>

		<h4>Когда отправить?</h4>
		<tk-radio-group direction="horizontal" formControlName="ready">
			<ng-container
				*ngFor="
					let item of [
						{ value: 'now', name: 'Сейчас' },
						{ value: 'later', name: 'Позже' }
					]
				"
			>
				<tk-radio-button [value]="item.value">
					{{ item.name }}
				</tk-radio-button>
			</ng-container>
		</tk-radio-group>

		<ng-container *ngIf="ready.value === 'later'">
			<tk-form-field>
				<tk-label>Дата отправки</tk-label>
				<input formControlName="date" [tkDatepicker]="startDatePicker" />
				<tk-icon icon="calendar-24" (click)="startDatePicker.open(undefined)" tkSuffix></tk-icon>
				<tk-datepicker #startDatePicker></tk-datepicker>

				<tk-error *ngIf="errors(form, 'date') as errorField" tkCommonError [errors]="errorField">
					Дата отправки указана неверно.
				</tk-error>
			</tk-form-field>

			<div>
				<tk-form-field>
					<tk-label>Время отправки</tk-label>
					<input type="time" tkInput formControlName="time" />
					<tk-icon icon="Clock" (click)="startDatePicker.open(undefined)" tkSuffix></tk-icon>

					<tk-error *ngIf="errors(form, 'time') as errorField" tkCommonError [errors]="errorField">
						Время отправки указано неверно.
					</tk-error>
				</tk-form-field>
			</div>
		</ng-container>

		<tk-button-group>
			<button tkButton size="xl" type="submit" [disabled]="!form.valid">Создать рассылку</button>
		</tk-button-group>
	</form>
</tg-dialog-body>
