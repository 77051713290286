<ng-container *transloco="let t; read: 'categories'">
	<ng-container *transloco="let tg; read: 'global'">
		<div class="actions">
			<tk-form-field>
				<tk-label>{{ tg('search') }}</tk-label>

				<input type="text" height="48" tkInput [formControl]="searchControl" />

				<tk-icon tkSuffix icon="search"></tk-icon>
			</tk-form-field>
			<tk-button-group>
				<tk-button tkButton theme="secondary" size="l" (click)="createCategoryHandler()">
					<tk-icon icon="plus"></tk-icon>
					{{ t('create_btn') }}
				</tk-button>
			</tk-button-group>
		</div>
		<tg-categories-tree-view
			*ngIf="!!(categories$ | async)?.length; else emptyContent"
			class="tree-view"
			[categories]="categories$ | async"
			(onEdit)="editCategoryHandler($event)"
			(onCreateSubCategory)="createSubCategoryHandler($event)"
			(onDelete)="deleteCategoryHandler($event)"
			(onChildrenToggle)="childrenToggleHandler($event)"
		>
		</tg-categories-tree-view>
		<ng-template #emptyContent>
			<div class="empty-container">
				<div *ngIf="loaded$ | async" class="empty-content">
					<img class="empty-image" src="/assets/images/boxes.png" alt="" />
					<div class="empty-title">
						{{ t('empty_title') }}
					</div>
					<div class="empty-description">
						{{ t('empty_description') }}
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
