<tg-info-plate>
	Инструкция для подключения интеграции:<br /><br />

	1. Войдите в личный кабинет “АТОЛ Онлайн” во вкладку “Мои компании”<br />
	2. Для нужного подключения откройте файл “Настройка интеграции”<br />
	3. Найдите в этом файле параметры login, pass и group_code и внесите их в форму
</tg-info-plate>

<form [formGroup]="form" (ngSubmit)="submit()">
	<div class="block">
		<h5>Личный кабинет</h5>
		<tk-form-field>
			<tk-label>Логин</tk-label>

			<input type="text" tkInput formControlName="login" tkInputTrim />
			<tk-error *ngIf="errors(form, 'login') as errorField" tkCommonError [errors]="errorField"></tk-error>
		</tk-form-field>
		<tk-form-field>
			<tk-label>Пароль</tk-label>

			<input type="text" tkInput formControlName="pass" tkInputTrim />
			<tk-error *ngIf="errors(form, 'pass') as errorField" tkCommonError [errors]="errorField"></tk-error>
		</tk-form-field>
		<tk-form-field>
			<tk-label>Group_code</tk-label>

			<input type="text" tkInput formControlName="groupCode" tkInputTrim />
			<tk-error *ngIf="errors(form, 'groupCode') as errorField" tkCommonError [errors]="errorField"></tk-error>
		</tk-form-field>
	</div>
	<div class="block">
		<h5>Информация о компании</h5>
		<tk-form-field>
			<tk-label>Email</tk-label>

			<input type="text" tkInput formControlName="companyEmail" tkInputTrim />
			<tk-error *ngIf="errors(form, 'companyEmail') as errorField" [errors]="errorField" tkEmailError
				>Обязательное поле</tk-error
			>
		</tk-form-field>
		<tk-form-field>
			<tk-label>СНО</tk-label>
			<tk-select formControlName="companySno">
				<tk-option *ngFor="let sno of snoTypes" [value]="sno.value">
					{{ sno.name }}
				</tk-option>
			</tk-select>
			<tk-error *ngIf="errors(form, 'companySno')">Обязательное поле</tk-error>
		</tk-form-field>
		<tk-form-field>
			<tk-label>ИНН</tk-label>

			<input type="text" tkInput formControlName="companyInn" tkInputTrim />
			<tk-error *ngIf="errors(form, 'companyInn') as errorField" tkCommonError [errors]="errorField"></tk-error>
		</tk-form-field>
		<tk-form-field>
			<tk-label>Место расчетов</tk-label>

			<input type="text" tkInput formControlName="companyPaymentAddress" tkInputTrim />
			<tk-error
				*ngIf="errors(form, 'companyPaymentAddress') as errorField"
				tkCommonError
				[errors]="errorField"
			></tk-error>
		</tk-form-field>
	</div>

	<button tkButton [disabled]="!form.valid" class="submit">Сохранить</button>
</form>
