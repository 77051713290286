import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { DestroyService } from '@services/destroy.service';
import { PaymentTinkoffComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-tinkoff/payment-tinkoff.component';
import { ToastService } from '@services/toast.service';
import { PaymentUkassaComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-ukassa/payment-ukassa.component';
import { PaymentCustomComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-custom/payment-custom.component';
import { ShopDeleteComponent } from '@components/modals/shop-delete/shop-delete.component';
import { environment } from '@environments/environment';
import { PaymentDeleteComponent } from '@components/modals/payment-delete/payment-delete.component';

@Component({
	selector: 'tg-shop-settings-payment',
	templateUrl: './shop-settings-payment.component.html',
	styleUrls: ['./shop-settings-payment.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class ShopSettingsPaymentComponent {
	shopId: string | null = null;
	menuIsOpen: boolean = false;
	paymentControlsOpen: any = null;
	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 10,
		},
	];

	tinkoffData: any = null;
	ukassaData: any = null;
	customData: any = null;

	constructor(
		public route: ActivatedRoute,
		public shopService: ShopService,
		private dialog: Dialog,
		private destroy$: DestroyService,
		private cdr: ChangeDetectorRef,
		private toast: ToastService,
	) {
		this.route.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
			});
	}

	refresh() {
		this.shopService.getTinkoffPaymentData(this.shopId).subscribe({
			next: res => {
				if (res) {
					this.tinkoffData = res;
					this.cdr.markForCheck();
				}
			},
			error: () => {
				this.tinkoffData = null;
				this.cdr.markForCheck();
			},
		});

		this.shopService.getShopPaymentToken(this.shopId).subscribe({
			next: res => {
				if (res) {
					this.ukassaData = res;
					this.cdr.markForCheck();
				}
			},
			error: () => {
				this.ukassaData = null;
				this.cdr.markForCheck();
			},
		});

		this.shopService.getCustomPaymentList(this.shopId).subscribe({
			next: res => {
				if (res) {
					this.customData = res;
					this.cdr.markForCheck();
				}
			},
			error: () => {
				this.customData = null;
				this.cdr.markForCheck();
			},
		});
	}

	get paymentData() {
		return this.customData.find(i => i.id === this.paymentControlsOpen);
	}

	openMenu(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.menuIsOpen = true;
	}

	closeMenu() {
		this.menuIsOpen = false;
	}

	openPaymentControls(event: MouseEvent, type: any) {
		event.stopPropagation();
		event.preventDefault();
		this.paymentControlsOpen = type;
	}

	closePaymentControls() {
		this.paymentControlsOpen = null;
	}

	openTinkoffModal(data?) {
		this.closeMenu();
		this.closePaymentControls();
		this.dialog
			.open<any>(PaymentTinkoffComponent, {
				data: data
					? {
							shopId: this.shopId,
							formValue: {
								...data,
								ffdVersion: data.ffdVersion?.code,
								taxation: data.taxation?.code,
								vatCode: data.vatCode?.code,
							},
					  }
					: { shopId: this.shopId },
			})
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(v => !!v),
			)
			.subscribe({
				next: res => {
					this.shopService.setTinkoffPaymentData(this.shopId, res).subscribe(
						res => {
							this.toast.success(data ? 'Способ оплаты изменён!' : 'Способ оплаты добавлен!');
							this.refresh();
							this.cdr.markForCheck();
						},
						err => {
							this.toast.error(err?.error?.message);
							this.cdr.markForCheck();
						},
					);
				},
				error: err => {
					console.log('error');
				},
				complete: () => {},
			});
	}

	openUkassaModal(data?) {
		this.closeMenu();
		this.closePaymentControls();
		this.dialog
			.open<any>(PaymentUkassaComponent, {
				data: data
					? {
							shopId: this.shopId,
							formValue: {
								...data,
								vatCode: data.vatCode?.code,
							},
					  }
					: { shopId: this.shopId },
			})
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(v => !!v),
			)
			.subscribe({
				next: res => {
					this.shopService.updateShopPaymentToken(this.shopId, res.vatCode, res.paymentToken).subscribe(
						res => {
							this.toast.success(data ? 'Способ оплаты изменён!' : 'Способ оплаты добавлен!');
							this.refresh();
							// @ts-ignore
							ym(environment.metricId, 'reachGoal', 'payment_connected');
							this.cdr.markForCheck();
						},
						err => {
							if (err.status === 400) {
								this.toast.error('Некорректный платежный токен');
								this.cdr.markForCheck();
								return;
							}

							this.toast.error(err?.error?.message);
							this.cdr.markForCheck();
						},
					);
				},
				error: err => {
					console.log('error');
				},
				complete: () => {},
			});
	}

	openCustomPaymentModal(data?: any) {
		this.closeMenu();
		this.closePaymentControls();

		this.dialog
			.open<any>(PaymentCustomComponent, {
				data: data
					? {
							shopId: this.shopId,
							formValue: data,
					  }
					: { shopId: this.shopId },
			})
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(v => !!v),
			)
			.subscribe({
				next: res => {
					if (data) {
						this.shopService.editCustomPayment(this.shopId, data.id, res).subscribe(
							res => {
								this.toast.success('Способ оплаты изменён!');
								this.refresh();
								this.cdr.markForCheck();
							},
							err => {
								this.toast.error(err?.error?.message);
								this.cdr.markForCheck();
							},
						);
						return;
					}
					this.shopService.createCustomPayment(this.shopId, res).subscribe(
						res => {
							this.toast.success('Способ оплаты добавлен!');
							this.refresh();
							this.cdr.markForCheck();
						},
						err => {
							this.toast.error(err?.error?.message);
							this.cdr.markForCheck();
						},
					);
				},
				error: err => {
					console.log('error');
				},
				complete: () => {},
			});
	}

	deletePayment(type: any) {
		this.closeMenu();
		this.closePaymentControls();
		if (this.customData.find(i => i.id === type)) {
			this.dialog
				.open(PaymentDeleteComponent)
				.closed.pipe(
					takeUntil(this.destroy$),
					filter(Boolean),
					switchMap(() => this.shopService.deleteCustomPayment(this.shopId, type)),
				)
				.subscribe({
					next: () => {
						this.toast.success('Способ оплаты успешно удалён!');
						this.refresh();
						this.cdr.markForCheck();
					},
					error: err => {
						this.toast.error(err?.error?.message);
						this.cdr.markForCheck();
					},
				});
		}

		if (type === 'tinkoff') {
			this.dialog
				.open(PaymentDeleteComponent)
				.closed.pipe(
					takeUntil(this.destroy$),
					filter(Boolean),
					switchMap(() => this.shopService.deleteTinkoffPaymentData(this.shopId)),
				)
				.subscribe({
					next: () => {
						this.toast.success('Способ оплаты успешно удалён!');
						this.refresh();
						this.cdr.markForCheck();
					},
					error: err => {
						this.toast.error(err?.error?.message);
						this.cdr.markForCheck();
					},
				});
		}

		if (type === 'ukassa') {
			this.dialog
				.open(PaymentDeleteComponent)
				.closed.pipe(
					takeUntil(this.destroy$),
					filter(Boolean),
					switchMap(() => this.shopService.deleteUkassaPaymentData(this.shopId)),
				)
				.subscribe({
					next: () => {
						this.toast.success('Способ оплаты успешно удалён!');
						this.refresh();
						this.cdr.markForCheck();
					},
					error: err => {
						this.toast.error(err?.error?.message);
						this.cdr.markForCheck();
					},
				});
		}
	}
}
