<div class="actions">
	<div></div>

	<tk-button-group>
		<a (click)="clickCreateBtn()" tkButton theme="secondary" size="xl">
			<tk-icon icon="plus"></tk-icon>
			Создать промокод
		</a>
	</tk-button-group>
</div>

<div class="table-wrap">
	<cdk-table class="table" [dataSource]="(promoList$ | async).content">
		<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

		<ng-container cdkColumnDef="promocodeName">
			<cdk-header-cell *cdkHeaderCellDef> Промокод </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				{{ row.promocodeName }}
			</cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="validDateFrom">
			<cdk-header-cell *cdkHeaderCellDef> Создан (дата) </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row"> {{ row.validDateFrom | date : 'dd.MM.yyyy' }}</cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="discountValue">
			<cdk-header-cell *cdkHeaderCellDef> Величина (₽/%) </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				{{ row.discountValue }}{{ row.discountType === 'PERCENT' ? '%' : '₽' }}
			</cdk-cell>
		</ng-container>

		<ng-container cdkColumnDef="notApplicableDiscounted">
			<cdk-header-cell *cdkHeaderCellDef> Применимость к скидкам </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				<tk-checkbox [disabled]="true" [ngModel]="row.notApplicableDiscounted"></tk-checkbox>
			</cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="minOrderSum">
			<cdk-header-cell *cdkHeaderCellDef> Мин. сумма заказа </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row"> {{ row.minOrderSum == null ? '0' : row.minOrderSum }} ₽ </cdk-cell>
		</ng-container>

		<ng-container cdkColumnDef="validDateTo">
			<cdk-header-cell *cdkHeaderCellDef> Действует до (дата) </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				{{ row.validDateTo | date : 'dd.MM.yyyy' }}
			</cdk-cell>
		</ng-container>

		<ng-container cdkColumnDef="promocodeState">
			<cdk-header-cell *cdkHeaderCellDef> Статус </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				<ng-container [ngSwitch]="row.promocodeState">
					<span class="green" *ngSwitchCase="'ACTIVE'">Активен</span>
					<span class="yellow" *ngSwitchCase="'EXPIRED'">Истёк</span>
					<span class="red" *ngSwitchCase="'DISABLED'">Отменён</span>
				</ng-container>
			</cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="actions">
			<cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				<button class="options" cdkOverlayOrigin #origin (click)="openMenu(row.id, $event)">
					<tk-icon icon="options-vertical"></tk-icon>
				</button>

				<ng-template
					cdkConnectedOverlay
					cdkConnectedOverlayHasBackdrop
					[cdkConnectedOverlayOrigin]="origin"
					[cdkConnectedOverlayOpen]="menuIsOpen === row.id"
					(backdropClick)="closeMenu()"
					cdkConnectedOverlayBackdropClass="backdrop--transparent"
					[cdkConnectedOverlayPositions]="positions"
				>
					<div class="panel">
						<button (click)="cancelPromo(row.id)">
							{{ row.promocodeState === 'DISABLED' ? 'Активировать' : 'Отменить' }}
						</button>
						<button (click)="deletePromo(row.id)">Удалить</button>
					</div>
				</ng-template>
			</cdk-cell>
		</ng-container>

		<cdk-row *cdkRowDef="let row; columns: columns" class="cdk-row-hover"></cdk-row>
	</cdk-table>
</div>

<tk-paging-controls
	[pages]="pages(promoList$ | async)"
	[page]="currentSettings.page ?? 0"
	(amountChange)="setAmount($event)"
	(pageChange)="changePage($event)"
></tk-paging-controls>
