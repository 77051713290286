<form [formGroup]="form" (ngSubmit)="submit()">
	<h4>Текст приветственного сообщения</h4>

	<angular-markdown-editor
		textareaId="area1"
		[rows]="12"
		name="markdownText"
		[ngModel]="markdownText | async"
		(ngModelChange)="markdownText.next($event)"
		[options]="editorOptions"
		[locale]="locale"
		(onChange)="onChange($event.detail.eventData)"
		[ngModelOptions]="{ standalone: true }"
	>
	</angular-markdown-editor>
	<tk-error *ngIf="form?.get('welcomeText')?.value?.length > 4096"> Превышено количество символов </tk-error>
	<tk-hint>Любые символы, максимальное количество символов: 4096 символов</tk-hint>

	<h4>Прикрепить фото</h4>

	<div class="tooltip tooltip--file dropzone">
		<div>
			<p>{{ photos.length === 10 ? 'Максимум фотографий' : 'До 10 фото' }}</p>
			<p>Формат: PNG, JPEG, JPG, GIF, WEBP. Рекомендуемое разрешение 1080×1440</p>
			<p>Размер: объём файла не должен превышать 5 MB</p>
		</div>

		<label *ngIf="photos.length < 10" for="photos">
			<tk-icon icon="button"></tk-icon>
		</label>

		<input
			type="file"
			#defaultFileDropRef
			id="photos"
			multiple
			[hidden]="photos.length === 10"
			(change)="uploadPhoto($event, false)"
		/>
	</div>
	<tk-hint class="last">Необязательное поле</tk-hint>

	<ng-container *ngIf="photos">
		<div class="photo__container">
			<div class="photo" *ngFor="let photo of photos">
				<img [src]="photo | getFile | async" alt />
				<div class="photo__delete" (click)="deletePhoto(photo)">
					<tk-icon icon="cross"></tk-icon>
				</div>
			</div>
		</div>
	</ng-container>

	<tk-button-group>
		<button tkButton size="xl" type="submit" [disabled]="!form.valid">Сохранить</button>
	</tk-button-group>
</form>
