import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ShopService } from '@services/shop.service';
import { errors } from '@utils/validations';
import { MarkdownService } from 'ngx-markdown';
import { ToastService } from '@services/toast.service';
import { ImageService } from '@services/image.service';
import { PromoService } from '@services/promo.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-newsletter-create',
	templateUrl: './promo-create.component.html',
	styleUrls: ['./promo-create.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoCreateComponent implements OnInit {
	form!: FormGroup;
	isPromoInvalid: boolean = false;

	@ViewChild('promoField') promoField;

	constructor(
		private fb: FormBuilder,
		public dialogRef: DialogRef,
		public shopService: ShopService,
		private cdr: ChangeDetectorRef,
		private markdownService: MarkdownService,
		private toast: ToastService,
		private imageService: ImageService,
		private promoService: PromoService,
		@Inject(DIALOG_DATA) public dialogData: { shopId: string },
	) {
		this.buildForm();
	}

	ngOnInit() {
		this.form.valueChanges.subscribe(res => {
			this.checkDateValid();
		});
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	get ready() {
		return this.form.get('ready');
	}

	onChange(e) {
		this.form.controls['text'].setValue(e.getContent());
	}

	buildForm() {
		this.form = this.fb.group({
			promocodeName: ['', [Validators.required]],
			discountType: ['', [Validators.required]],
			discountValue: ['', [Validators.required]],
			validDateFrom: ['', [Validators.required]],
			validDateTo: ['', [Validators.required]],
			notApplicableDiscounted: [false, Validators.required],
			minOrderSum: [''],
		});
	}

	checkDateValid = () => {
		const dateStart = new Date(this.form.get('validDateFrom').value);
		const dateEnd = new Date(this.form.get('validDateTo').value);
		if (dateStart && dateEnd) {
			if (dateStart > dateEnd) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};

	submit(): void {
		const value = this.form.value;
		this.isPromoInvalid = false;

		this.promoService.createPromo(this.dialogData.shopId!, value).subscribe({
			next: res => {
				// @ts-ignore
				ym(environment.metricId, 'reachGoal', 'promocode_creation_finish');
				this.dialogRef.close(res);
			},
			error: err => {
				if (
					err.error.errors.find(i => i.fieldName === 'createPromocode.promocodeRequest.promocodeName')
						.detailMessage === 'Название промокода содержит недопустимые символы'
				) {
					this.isPromoInvalid = true;
					this.cdr.markForCheck();
				} else {
					this.toast.error(err.error?.message);
				}
			},
		});

		this.cdr.markForCheck();
	}
}
