import { Directive, ElementRef, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

@Directive({
	selector: `[tk-dialog-close], [tkDialogClose]`,
	exportAs: 'tkDialogClose',
	host: {
		'(click)': 'close()',
		type: 'button', // Prevents accidental form submits.
	},
})
export class DialogCloseDirective implements OnInit, OnChanges {
	@Input('tk-dialog-close') dialogResult: any;
	@Input('tkDialogClose') tkDialogClose: any;
	@Input('confirmationText') confirmationText: string | null = null;

	constructor(
		@Optional() public dialogRef: DialogRef<any>,
		private elementRef: ElementRef<HTMLElement>,
		private dialog: Dialog,
	) {}

	ngOnInit(): void {
		if (!this.dialogRef) {
			// @ts-ignore
			this.dialogRef = getClosestDialog(this.elementRef, this.dialog.openDialogs)!;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		const proxiedChange = changes['dialogResult'] || changes['tkDialogClose'];

		if (proxiedChange) {
			this.dialogResult = proxiedChange.currentValue;
		}
	}

	close(): void {
		const close = () => this.dialogRef.close(this.dialogResult);

		if (this.confirmationText !== null) {
			if (confirm(this.confirmationText)) {
				close();
			}
		} else {
			close();
		}
	}
}

export function getClosestDialog(
	element: ElementRef<HTMLElement>,
	openDialogs: DialogRef<any>[],
): DialogRef<any> | null | undefined {
	let parent: HTMLElement | null = element.nativeElement.parentElement;

	while (parent && !parent.classList.contains('tk-dialog-container')) {
		parent = parent.parentElement;
	}

	return parent
		? openDialogs.find(dialog => (dialog as any)._containerInstance._elementRef.nativeElement === parent)
		: null;
}
