<tk-form-field>
	<ng-content></ng-content>
	<tk-label>{{ dateLabel }}</tk-label>
	<input [formControl]="dateControl" [tkDatepicker]="datePicker" />
	<button type="button" tkSuffix (click)="datePicker.open(undefined)">
		<tk-icon icon="calendar-24"></tk-icon>
	</button>
	<tk-datepicker #datePicker></tk-datepicker>
</tk-form-field>

<tk-form-field *ngIf="showTime">
	<tk-label>Время</tk-label>
	<input [formControl]="timeControl" tkTimepicker />
	<tk-icon icon="calendar-24" tkSuffix class="pe-none"></tk-icon>
</tk-form-field>
