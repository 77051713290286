<h4>Заказ</h4>

<tg-info-plate
	>Здесь вы можете убрать ненужные поля из формы заказа. По умолчанию все поля включены и являются обязательными,
	кроме поля «Промокод» и «Комментарий к заказу», при оформлении заказа покупателем.</tg-info-plate
>

<div *ngIf="isFieldExist('Фамилия')" class="checkbox" (click)="enableField('LAST_NAME')">
	<tk-checkbox [ngModel]="isFieldEnabled('Фамилия')" [ngModelOptions]="{ standalone: true }">
		Запрашивать фамилию при заказе
	</tk-checkbox>
</div>

<div *ngIf="isFieldExist('Отчество')" class="checkbox" (click)="enableField('PATRONYMIC')">
	<tk-checkbox [ngModel]="isFieldEnabled('Отчество')" [ngModelOptions]="{ standalone: true }">
		Запрашивать отчество при заказе
	</tk-checkbox>
</div>

<div *ngIf="isFieldExist('Телефон')" class="checkbox" (click)="enableField('PHONE')">
	<tk-checkbox [ngModel]="isFieldEnabled('Телефон')" [ngModelOptions]="{ standalone: true }">
		Запрашивать номер телефона при заказе
	</tk-checkbox>
</div>

<div *ngIf="isFieldExist('Email')" class="checkbox" (click)="enableField('EMAIL')">
	<tk-checkbox [ngModel]="isFieldEnabled('Email')" [ngModelOptions]="{ standalone: true }">
		Запрашивать email при заказе
	</tk-checkbox>
</div>

<div *ngIf="isFieldExist('Промокод')" class="checkbox" (click)="enableField('PROMOCODE')">
	<tk-checkbox [ngModel]="isFieldEnabled('Промокод')" [ngModelOptions]="{ standalone: true }">
		Показывать поле «промокод»
	</tk-checkbox>
</div>

<div *ngIf="isFieldExist('Комментарии к заказу')" class="checkbox" (click)="enableField('COMMENT')">
	<tk-checkbox [ngModel]="isFieldEnabled('Комментарии к заказу')" [ngModelOptions]="{ standalone: true }">
		Показывать поле «Комментарий к заказу»
	</tk-checkbox>
</div>
