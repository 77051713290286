<h3>Общая информация</h3>
<ng-container *ngIf="transferList | async">
	<form [formGroup]="apishipForm" (ngSubmit)="save()">
		<div class="block">
			<h5>Токен</h5>
			<tk-form-field>
				<tk-label>Ссылка на токен</tk-label>
				<input type="text" tkInput tkInputTrim formControlName="token" />
				<tk-error
					*ngIf="errors(apishipForm, 'token') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>

				<tk-error *ngIf="apishipForm.get('token').hasError('minlength')"
					>Максимальная длина 32 символов</tk-error
				>
			</tk-form-field>
		</div>

		<div class="block" formGroupName="shopInfo">
			<h5>Информация о продавце</h5>
			<tk-form-field>
				<tk-label>Контактный телефон</tk-label>
				<input type="tel" tkInput formControlName="contactPhone" mask="+0 (000) 000-00-00" />
				<tk-error
					*ngIf="errors(apishipForm, 'shopInfo.contactPhone') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>
			<tk-form-field>
				<tk-label>ФИО контактного лица</tk-label>
				<input type="text" tkInput tkInputTrim formControlName="responsiblePerson" />
				<tk-error
					*ngIf="errors(apishipForm, 'shopInfo.responsiblePerson') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>

			<tk-form-field tkAutocompleteOrigin>
				<tk-label>Адрес склада</tk-label>
				<input
					(ngModelChange)="onChangeSearch()"
					autocomplete="off"
					type="text"
					tkInput
					formControlName="address"
					[tkAutocomplete]="autocomplete"
				/>
				<tk-error *ngIf="errors(apishipForm, 'address') && apishipForm.controls['address'].hasError('required')"
					>Обязательное поле</tk-error
				>
				<tk-error *ngIf="address.hasError('fias_id')">Выберите адрес из списка</tk-error>
				<tk-error *ngIf="address.hasError('fias_house')">Адрес должен содержать номер дома</tk-error>
				<tk-autocomplete #autocomplete="tkAutocomplete">
					<tk-option *ngFor="let item of fiasList" [value]="item.address" (click)="selectEvent(item)">{{
						item.address
					}}</tk-option>
				</tk-autocomplete>
			</tk-form-field>
		</div>

		<ng-container *ngIf="providers?.length">
			<h3>Подключенные службы доставки</h3>
			<div class="company">
				<div *ngFor="let company of providers" class="company__item">
					<img src="assets/images/providers/{{ company.providerKey }}.svg" />
					<tk-icon icon="success"></tk-icon>
				</div>
			</div>
		</ng-container>

		<div formArrayName="apishipDeliveryServices">
			<ng-container *ngFor="let company of providers; let i = index">
				<h3>Основные настройки доставки {{ getProviderFieldName(company.providerKey) }}</h3>

				<div class="block" [formGroupName]="i">
					<h5>Забор груза</h5>
					<tk-form-field>
						<tk-label>Тип забора груза</tk-label>
						<tk-select formControlName="productTransferType">
							<tk-option *ngFor="let item of transferList.value" [value]="item.code">
								{{ item.value }}
							</tk-option>
						</tk-select>
						<tk-error
							*ngIf="errors(apishipForm, 'productTransferType') as errorField"
							tkCommonError
							[errors]="errorField"
						></tk-error>
					</tk-form-field>

					<ng-container
						*ngIf="getProviderFieldValue(i, 'productTransferType') === 'FROM_DELIVERY_SERVICE_WAREHOUSE'"
					>
						<h5>Пункт доставки</h5>
						<div class="provider-point" *ngIf="getProviderFieldValue(i, 'pickPointId')">
							<div class="provider-point__name">{{ getProviderFieldValue(i, 'pickPointName') }}</div>
							<div class="provider-point__address">
								{{ getProviderFieldValue(i, 'pickPointAddress') }}
							</div>
							<div class="provider-point__time">{{ getProviderFieldValue(i, 'pickPointTime') }}</div>
						</div>
						<div
							class="provider__select"
							(click)="openPickupPointMap(getProviderFieldValue(i, 'city'), company.providerKey, i)"
						>
							{{ getProviderFieldValue(i, 'pickPointId') ? 'Изменить' : 'Выбрать на карте' }}
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<h3>Настройка габаритов товара</h3>
		<tg-info-plate>
			Инструкция:<br /><br />
			1. Выберите стандартные габариты ваших товаров.<br />
			2. Укажите средний вес ваших товаров.<br />
			3. Данные по умолчанию добавятся во все товары вашего каталога. При необходимости можете внести изменения в
			карточки определенных товаров.
		</tg-info-plate>

		<div class="block">
			<h5>Габариты</h5>
			<tk-form-field>
				<tk-label>Тип габаритов</tk-label>
				<tk-select formControlName="sizeType">
					<tk-option *ngFor="let item of dimensionList" [value]="item.productDimensionType">
						Тип {{ item.productDimensionType }}: {{ item.width }}×{{ item.height }}×{{
							item.length
						}}
						см</tk-option
					>
					<tk-option value="Другое"> Другое </tk-option>
				</tk-select>
				<tk-error
					*ngIf="errors(apishipForm, 'sizeType') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>

			<div *ngIf="apishipForm.value.sizeType === 'Другое'" class="other">
				<tk-form-field>
					<tk-label>
						Длина
						<tk-icon
							class="icon"
							icon="info"
							size="20"
							tkTooltip="Только целые числа"
							tkTooltipPlacement="bottom"
						></tk-icon>
					</tk-label>
					<input type="number" appOnlynumber tkInput tkInputTrim formControlName="defaultLength" />
					<tk-error
						*ngIf="errors(apishipForm, 'defaultLength') as errorField"
						tkCommonError
						[errors]="errorField"
					></tk-error>
				</tk-form-field>
				<tk-form-field>
					<tk-label>
						Ширина
						<tk-icon
							class="icon"
							icon="info"
							size="20"
							tkTooltip="Только целые числа"
							tkTooltipPlacement="bottom"
						></tk-icon>
					</tk-label>
					<input type="number" appOnlynumber tkInput tkInputTrim formControlName="defaultWidth" />
					<tk-error
						*ngIf="errors(apishipForm, 'defaultWidth') as errorField"
						tkCommonError
						[errors]="errorField"
					></tk-error>
				</tk-form-field>
				<tk-form-field>
					<tk-label>
						Высота
						<tk-icon
							class="icon"
							icon="info"
							size="20"
							tkTooltip="Только целые числа"
							tkTooltipPlacement="bottom"
						></tk-icon>
					</tk-label>
					<input type="number" appOnlynumber tkInput tkInputTrim formControlName="defaultHeight" />
					<tk-error
						*ngIf="errors(apishipForm, 'defaultHeight') as errorField"
						tkCommonError
						[errors]="errorField"
					></tk-error>
				</tk-form-field>
			</div>
		</div>

		<div class="block">
			<h5>Приблизительный вес товаров</h5>
			<tk-form-field>
				<tk-label>Вес товара, гр</tk-label>
				<input type="number" appOnlynumber tkInput tkInputTrim formControlName="defaultWeight" />
				<tk-error
					*ngIf="errors(apishipForm, 'defaultWeight') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>
		</div>

		<button tkButton type="submit" class="submit">Сохранить</button>
	</form>
</ng-container>
