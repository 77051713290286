<p *ngIf="errors?.['minlength']">Минимальная длина пароля 8 символов</p>
<p *ngIf="errors?.['maxlength']">Максимальная длина пароля 32 символа</p>
<p *ngIf="errors?.['required']">Обязательное поле</p>
<p *ngIf="errors?.['withoutUpper']">Пароль должен содержать минимум 1 заглавную букву</p>
<p *ngIf="errors?.['withoutLower']">Пароль должен содержать минимум 1 строчную букву</p>
<p *ngIf="errors?.['withoutNumber']">Пароль должен содержать минимум 2 цифры</p>
<p *ngIf="errors?.['hasRussianLetter']">Пароль должен состоять из латинских символов</p>
<p *ngIf="errors?.['hasSpace']">В пароле не должно быть пробелов</p>
<p *ngIf="errors?.['notSame']">Пароли не совпадают</p>
<p *ngIf="errors?.['duplicateEmail']">Пароль должен отличаться от почты</p>
<p *ngIf="errors?.['invalidCharacters']">Пароль содержит запрещённые символы</p>
