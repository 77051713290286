import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DictionaryCodeValueResponse } from '@models/generated/api';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { map, tap } from 'rxjs';
import { DictionariesService } from '@services/dictionaries.service';

@Component({
	selector: 'tg-payment-ukassa',
	templateUrl: './payment-ukassa.component.html',
	styleUrls: ['./payment-ukassa.component.sass', '../shop-settings-payment.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentUkassaComponent {
	tokenControl = new FormControl('');
	vatControl = new FormControl('');
	shopId: string;
	currentToken: string | null = null;
	currentVat: string | null = null;

	vatCodes: DictionaryCodeValueResponse[];

	constructor(
		private route: ActivatedRoute,
		public shopService: ShopService,
		private dictionariesService: DictionariesService,
		private cdr: ChangeDetectorRef,
		@Inject(DIALOG_DATA) public dialogData: any,
		public dialogRef: DialogRef,
	) {
		// this.route.parent.parent.params
		// 	.pipe(
		// 		map(d => d['id']),
		// 		tap(id => (this.shopId = id)),
		// 	)
		// 	.subscribe(() => {
		// 		this.refresh();
		// 	});

		if (!shopService.shopMoneySign.value) {
			this.shopService.getShop(this.shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
				},
			});
		}

		this.dictionariesService.getVatCodes().subscribe({
			next: data => {
				this.vatCodes = data;

				if (this.dialogData) {
					this.shopId = this.dialogData.shopId;
					if (this.dialogData.formValue) {
						this.currentToken = this.dialogData.formValue.paymentToken ?? null;
						this.tokenControl.setValue(this.currentToken);
						this.currentVat = this.dialogData.formValue.vatCode ?? this.vatCodes[0].code;
						this.vatControl.setValue(this.currentVat);

						this.cdr.markForCheck();
					}
				}
			},
		});
	}

	// refresh() {
	// 	this.shopService.getShopPaymentToken(this.shopId).subscribe(res => {
	// 		this.currentToken = res.paymentToken ?? null;
	// 		this.tokenControl.setValue(this.currentToken);
	// 		this.currentVat = res.vatCode?.code ?? this.vatCodes[0].code;
	// 		this.vatControl.setValue(this.currentVat);
	//
	// 		this.cdr.markForCheck();
	// 	});
	//
	//
	// }

	// submit() {
	// 	if (!this.tokenControl.valid) {
	// 		this.tokenControl.markAsTouched();
	// 		return;
	// 	}
	//
	// 	this.shopService.updateShopPaymentToken(this.shopId, this.vatControl.value, this.tokenControl.value).subscribe(
	// 		res => {
	// 			// @ts-ignore
	// 			ym(environment.metricId, 'reachGoal', 'payment_connected');
	// 			this.toast.success('Платежные данные сохранены');
	// 			this.currentToken = this.tokenControl.value;
	// 			this.currentVat = this.vatControl.value;
	// 			this.cdr.markForCheck();
	// 		},
	// 		err => {
	// 			if (err.status === 400) {
	// 				this.toast.error('Некорректный платежный токен');
	// 				this.tokenControl.setErrors({ invalid: true });
	// 				this.cdr.markForCheck();
	// 				return;
	// 			}
	// 			this.toast.error(err?.error?.message);
	// 		},
	// 	);
	// }

	submit(): void {
		if (!this.tokenControl.valid) {
			this.tokenControl.markAsTouched();
			return;
		}

		const value = {
			paymentToken: this.tokenControl.value,
			vatCode: this.vatControl.value,
		};

		this.dialogRef.close(value);
	}

	cancel(): void {
		this.dialogRef.close(null);
	}
}
