import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DialogRef } from '@angular/cdk/dialog';
import { NewsletterUserGroupResponse } from '@models/generated/api';
import { ShopService } from '@services/shop.service';
import { errors, maxFilesize, validateImage } from '@utils/validations';
import userGroup = NewsletterUserGroupResponse.userGroup;
import { MarkdownService } from 'ngx-markdown';
import { EditorLocale } from 'angular-markdown-editor';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { ImageService } from '@services/image.service';

@Component({
	selector: 'tg-newsletter-create',
	templateUrl: './newsletter-create.component.html',
	styleUrls: ['./newsletter-create.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterCreateComponent implements OnInit {
	form!: FormGroup;
	userGroup = userGroup;
	editorOptions: any = {};
	markdownText = new BehaviorSubject<string>('');
	photos = [];

	locale: EditorLocale = {
		language: 'ru',
		dictionary: {
			'Bold': 'Жирный',
			'bold': 'жирный',
			'Italic': 'Курсив',
			'italic': 'курсив',
			'Heading': 'Заголовок',
			'URL/Link': 'Вставьте url',
			'Image': 'Вставьте изображение',
			'List': 'Список',
			'Quote': 'Цитата',
			'quote here': 'Текст цитаты',
			'Preview': 'Предпросмотр',
			'strong text': 'жирный текст',
			'Strikethrough': 'Перечеркнутый',
			'strikethrough': 'перечеркнутый',
			'emphasized text': 'текст курсивом',
			'heading text': 'Заголовок',
			'enter link description here': 'Описание ссылки',
			'Insert Hyperlink': 'Вставьте ссылку',
			'enter image description here': 'Описание изображения',
			'Insert Image Hyperlink': 'Вставьте ссылку на изображение',
			'enter image title here': 'Заголовок изображения',
			'list text here': 'элемент списка',
			'Unordered List': 'Маркированный список',
			'Ordered List': 'Нумерованный список',
		},
	};

	constructor(
		private fb: FormBuilder,
		public dialogRef: DialogRef,
		public shopService: ShopService,
		private cdr: ChangeDetectorRef,
		private markdownService: MarkdownService,
		private toast: ToastService,
		private imageService: ImageService,
	) {
		this.buildForm();
	}

	ngOnInit() {
		this.editorOptions = {
			language: 'ru',
			parser: val => this.markdownService.compile(val.trim()),
			fullscreen: false,
			hiddenButtons: ['cmdTable', 'cmdImage'],
			disabledButtons: ['Code'],
		};

		this.ready.valueChanges.subscribe({
			next: () => {
				if (this.ready.value === 'later') {
					this.form.get('date').enable();
					this.form.get('time').enable();
				} else {
					this.form.get('date').disable();
					this.form.get('time').disable();
				}
			},
		});
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	get ready() {
		return this.form.get('ready');
	}

	onChange(e) {
		this.form.controls['text'].setValue(e.getContent());
	}

	buildForm() {
		this.form = this.fb.group({
			name: ['', [Validators.required]],
			text: ['', [Validators.required]],
			userGroup: [userGroup.ALL, [Validators.required]],
			ready: ['now', [Validators.required]],
			date: ['', [Validators.required]],
			time: ['', [Validators.required]],
			photos: [[]],
		});

		this.form.get('date').disable();
		this.form.get('time').disable();
	}

	getTrimmedData = obj => {
		if (obj && typeof obj === 'object') {
			Object.keys(obj).forEach(key => {
				if (typeof obj[key] === 'object') {
					this.getTrimmedData(obj[key]);
				} else if (typeof obj[key] === 'string') {
					obj[key] = obj[key].trim();
				}
			});
		}
		return obj;
	};

	uploadPhoto({ target }: any, isColor: boolean): void {
		const files = target.files;

		let skipErr: boolean = false;
		Object.keys(files).forEach((key, index) => {
			if (!validateImage(files[key].type, files[key].size)) {
				if (files.length > 1) {
					!skipErr &&
						this.toast.error('Не все фото загружены. Проверьте размер или формат загружаемых файлов');
					skipErr = true;
					return;
				}
				this.toast.error(
					files[key].size > maxFilesize
						? 'Объём файла не должен превышать 5 MB'
						: 'Допустимый формат: PNG, JPEG, JPG, GIF, WEBP',
				);
				return;
			}
			this.imageService.uploadImage(files[key], 'NEWSLETTER_PHOTO').subscribe({
				next: data => {
					if (this.photos.length < 10) {
						this.photos.push({ photoId: data.id });
					}
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
				complete: () => {
					target.value = '';
					this.cdr.markForCheck();
				},
			});
		});
	}

	deletePhoto(id: string) {
		this.photos = this.photos!.filter(photo => photo.photoId !== id);
		this.cdr.markForCheck();
	}

	submit(): void {
		let text = this.form.get('text').value;

		text = text.split('**').join('*');
		text = text.split('~~').join('~');
		this.form.get('text').setValue(text);

		this.form.get('photos').setValue(this.photos);

		const value = this.form.value;

		if (this.ready.value === 'now') {
			value.scheduledDatetime = null;
		} else {
			value.scheduledDatetime = new Date(
				Date.parse(this.form.value.date + ' ' + this.form.value.time),
			).toISOString();
		}
		delete value.date;
		delete value.time;
		delete value.ready;

		this.dialogRef.close(value);
	}
}
