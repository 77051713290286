import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ShopService } from '@services/shop.service';
import { catchError, distinctUntilChanged, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

export class TokenValidator {
	static createValidator(shopService: ShopService, cdr: ChangeDetectorRef): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors> => {
			return shopService.checkToken({ token: control.value }).pipe(
				distinctUntilChanged(),
				map(() => null),
				catchError(() => of({ tokenValidation: true })),
				tap(() => setTimeout(() => cdr.detectChanges(), 0)),
			);
		};
	}
}
