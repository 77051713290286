import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CategoryResponse } from '@models/generated/api';

@Component({
	selector: 'tg-product-select-category',
	templateUrl: './product-select-category.component.html',
	styleUrls: ['./product-select-category.component.sass'],
})
export class ProductSelectCategoryComponent {
	@Input() categories: CategoryResponse[] = [];
	@Input() selectedCategoryId: string | null = null;
	@Input() level: number = 0;
	@Output() categorySelected = new EventEmitter<CategoryResponse>();

	selectCategory(category: CategoryResponse): void {
		this.categorySelected.emit(category);
	}

	onChildCategorySelected(category: CategoryResponse): void {
		this.categorySelected.emit(category);
	}
}
