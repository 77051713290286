<ng-container *transloco="let t">
	<tg-dialog-header>
		<ng-container *ngIf="!dialogData">{{ t('main.create_store') }}</ng-container>
		<ng-container *ngIf="dialogData">{{ t('main.edit_store') }}</ng-container>
	</tg-dialog-header>

	<tg-dialog-body>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<tk-form-field>
				<tk-label>{{ t('main.store_name') }}</tk-label>

				<input type="text" tkInput formControlName="shopName" />
				<tk-error *ngIf="errors(form, 'shopName') as errorField" tkCommonError [errors]="errorField"></tk-error>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Вид товаров</tk-label>
				<tk-select [disabled]="!!dialogData" formControlName="productType">
					<tk-option value="GOODS"> Не цифровые товары </tk-option>
					<tk-option value="DIGITAL"> Цифровые товары </tk-option>
				</tk-select>
			</tk-form-field>

			<tk-form-field>
				<tk-label>{{ t('main.tele_name') }}</tk-label>

				<input type="text" tkInput formControlName="authorTelegramUserName" />
				<tk-error *ngIf="username?.errors ? username?.errors['pattern'] : false">
					Некорректное имя пользователя
				</tk-error>
				<tk-hint>Для получения уведомлений о заказах</tk-hint>
			</tk-form-field>

			<tk-radio-group direction="horizontal" formControlName="currency">
				<ng-container *ngFor="let item of currency">
					<tk-radio-button *ngIf="!isEdit || dialogData.currency === item.value" [value]="item.value">
						{{ item.name }} ({{ item.sign }})
					</tk-radio-button>
				</ng-container>
			</tk-radio-group>

			<tk-form-field>
				<tk-label>{{ t('main.min_field') }}</tk-label>

				<input type="text" tkInput formControlName="minimumOrderSum" />
				<tk-hint [ngSwitch]="form.value.currency">
					<ng-container *ngSwitchCase="'RUB'">{{ t('main.min_text') }} 100 ₽</ng-container>
					<ng-container *ngSwitchCase="'USD'">{{ t('main.min_text') }} 1 $</ng-container>
					<ng-container *ngSwitchCase="'UZS'">{{ t('main.min_text') }} 12 000 Sʻ</ng-container>
				</tk-hint>
				<tk-error
					*ngIf="errors(form, 'minimumOrderSum') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>

			<div class="instruction">
				<tk-icon icon="help"></tk-icon>

				<div class="instruction__content">
					{{ t('main.instruction.head') }}
					<ol>
						<li>
							{{ t('main.instruction.step_1_1') }}
							<a href="https://t.me/BotFather" target="_blank">@BotFather</a>
							{{ t('main.instruction.step_1_2') }}
						</li>
						<li>
							{{ t('main.instruction.step_2_1') }} <span class="orange">/newbot</span>
							{{ t('main.instruction.step_2_2') }}
						</li>
						<li>{{ t('main.instruction.step_3') }}</li>
						<li>
							{{ t('main.instruction.step_4_1') }}
							<span class="orange">{{ t('main.instruction.step_4_2') }}</span>
						</li>
						<li>{{ t('main.instruction.step_5') }}</li>
					</ol>
				</div>
			</div>

			<tk-form-field>
				<tk-label>{{ t('main.tele_token') }}</tk-label>

				<input type="text" tkInput formControlName="token" />
				<tk-error *ngIf="token?.errors ? token?.errors['tokenValidation'] : false"> Ошибка токена </tk-error>
				<div *ngIf="form.get('token').value && !hasTokenError() && !form.pending" class="token-success">
					<tk-icon icon="success"></tk-icon>
				</div>
			</tk-form-field>

			<tk-button-group>
				<button tkButton theme="secondary" size="xl" (click)="dialogRef.close()" *ngIf="dialogData">
					{{ t('global.cancel') }}
				</button>
				<button tkButton size="xl" type="submit" [disabled]="!form.valid">
					<ng-container *ngIf="!dialogData">{{ t('main.create_btn') }}</ng-container>
					<ng-container *ngIf="dialogData">{{ t('main.save') }}</ng-container>
				</button>
			</tk-button-group>

			<span *ngIf="!dialogData" class="consent">
				{{ t('global.agree.agree_1') }}
				<a href="/assets/TGShop_Политика_конфиденциальности_27_07_23.docx">
					{{ t('global.agree.agree_2') }}
				</a>
				{{ t('global.agree.agree_3') }}
			</span>
		</form>
	</tg-dialog-body>
</ng-container>
