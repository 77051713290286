/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма редактирования токена оплаты для магазина
 */
export type PaymentTelegramDtoRequest = {
	/**
	 * Токен оплаты из BotFather
	 */
	paymentToken?: string;
	/**
	 * НДС
	 */
	vatCode: PaymentTelegramDtoRequest.vatCode;
};

export namespace PaymentTelegramDtoRequest {
	/**
	 * НДС
	 */
	export enum vatCode {
		NO_VAT = 'NO_VAT',
		VAT_0 = 'VAT_0',
		VAT_10 = 'VAT_10',
		VAT_20 = 'VAT_20',
		VAT_10_110 = 'VAT_10_110',
		VAT_20_120 = 'VAT_20_120',
	}
}
