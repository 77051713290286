import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	Inject,
	Input,
	Optional,
	QueryList,
	Self,
	ViewChildren,
} from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { AbstractTkControl } from '@technokratos/components-angular/core';
import { TkCalendar, TkDatepickerDirective } from '@technokratos/components-angular/datepicker';

const overlayPositions: ConnectedPosition[] = [
	{
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
	},
	{
		originX: 'start',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'bottom',
	},
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
	},
];

@Component({
	selector: 'tk-date-period',
	templateUrl: './date-period.component.html',
	styleUrls: ['./date-period.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: AbstractTkControl,
			useExisting: DatePeriodComponent,
		},
	],
})
export class DatePeriodComponent extends AbstractTkControl<string> implements AfterContentInit {
	controlType = 'date-period';
	@ContentChildren(TkDatepickerDirective, { descendants: true }) datepickers!: QueryList<TkDatepickerDirective>;
	overlayOpened = false;
	overlayPositions = overlayPositions;
	@Input() startDate?: string;
	@Input() endDate?: string;
	startDateControl?: UntypedFormControl | null;
	endDateControl?: UntypedFormControl | null;
	@ViewChildren(TkCalendar, { read: TkCalendar }) calendar!: QueryList<TkCalendar>;

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		control: NgControl | null,
		@Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
		public elementRef: ElementRef,
	) {
		super(control, changeDetectorRef);
	}

	ngAfterContentInit(): void {
		this.startDateControl = this.datepickers.first.ngControl?.control as UntypedFormControl;
		this.endDateControl = this.datepickers.last.ngControl?.control as UntypedFormControl;
	}

	override containerClick(): void {
		this.open();
	}

	open(): void {
		this.overlayOpened = true;
		this.cdr.markForCheck();
	}

	close(): void {
		this.overlayOpened = false;
		this.cdr.markForCheck();
	}

	dateSelected(date: string): void {
		if (!this.startDateControl || !this.endDateControl) {
			return;
		}
		if (this.startDateControl.value && this.startDateControl.value === this.endDateControl.value) {
			const startValue = this.startDateControl.value;
			if (startValue > date) {
				this.startDateControl.setValue(date);
				this.endDateControl.setValue(startValue);
			} else {
				this.endDateControl.setValue(date);
			}
		} else {
			this.startDateControl.setValue(date);
			this.endDateControl.setValue(date);
		}
	}
}
