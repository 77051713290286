import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from '@pages/auth/auth.page';
import { ShopListComponent } from '@pages/shop-list/shop-list.component';
import { AuthGuard } from '@guards/auth.guard';
import { ShopDetailComponent } from '@pages/shop-detail/shop-detail.component';
import { HomeComponent } from '@pages/home/home.component';
import { ShopService } from '@services/shop.service';
import { ProductsComponent } from '@pages/shop-detail/products/products.component';
import { CreateProductPageComponent } from '@pages/shop-detail/create-product/create-product.page';
import { ProductDetailComponent } from '@pages/shop-detail/product-detail.component/product-detail.component';
import { ShopSettingsDeliveryComponent } from '@pages/shop-detail/shop-settings/shop-settings-delivery/shop-settings-delivery.component';
import { ShopSettingsPaymentComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/shop-settings-payment.component';
import { ShopSettingsComponent } from '@pages/shop-detail/shop-settings/shop-settings.component';
import { OrderHistoryComponent } from '@pages/shop-detail/order-history/order-history.component';
import { OrderDetailComponent } from '@pages/shop-detail/order-detail/order-detail.component';
import { AnalyticsComponent } from '@pages/shop-detail/analytics/analytics.component';
import { ImportComponent } from '@pages/shop-detail/import/import.component';
import { PaymentUkassaComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-ukassa/payment-ukassa.component';
import { PaymentAtolComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-atol/payment-atol.component';
import { ShopSettingsCrmComponent } from '@pages/shop-detail/shop-settings/shop-settings-retail/shop-settings-crm.component';
import { TarifsComponent } from '@pages/user/tarifs/tarifs.component';
import { SubscriptionsComponent } from '@pages/user/subscriptions/subscriptions.component';
import { SubscriptionGuard } from '@guards/subscription.guard';
import { ShopSettingsNewsletterComponent } from '@pages/shop-detail/shop-settings/shop-settings-newsletter/shop-settings-newsletter.component';
import { EditProductPageComponent } from '@pages/shop-detail/edit-product/edit-product.page';
import { ShopSettingsPromoComponent } from '@pages/shop-detail/shop-settings/shop-settings-promo/shop-settings-promo.component';
import { ShopSettingsHelloComponent } from '@pages/shop-detail/shop-settings/shop-settings-hello/shop-settings-hello.component';
import { ShopSettingsOrderFieldsComponent } from '@pages/shop-detail/shop-settings/shop-settings-order-fields/shop-settings-order-fields.component';
import { PaymentTinkoffComponent } from '@pages/shop-detail/shop-settings/shop-settings-payment/payment-tinkoff/payment-tinkoff.component';
import { CategoriesComponent } from '@pages/shop-detail/categories/categories.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		children: [
			{
				path: '',
				redirectTo: '/shop',
				pathMatch: 'full',
			},
			{
				path: 'shop',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'Мои магазины',
				},
				children: [
					{
						path: '',
						component: ShopListComponent,
						pathMatch: 'full',
					},
					{
						path: ':id',
						data: {
							breadcrumb: (data: any) => data.shop.shopName,
						},
						resolve: { shop: ShopService },
						canActivate: [SubscriptionGuard],
						children: [
							{
								path: '',
								component: ShopDetailComponent,
								children: [
									{
										path: '',
										component: CategoriesComponent,
									},
									{
										path: 'products',
										component: ProductsComponent,
									},

									{
										path: 'history',
										component: OrderHistoryComponent,
									},
									{
										path: 'analytics',
										component: AnalyticsComponent,
									},
									{
										path: 'import',
										component: ImportComponent,
									},
								],
							},
							{
								path: 'products/create-product',
								component: CreateProductPageComponent,
								data: {
									breadcrumb: 'Создание товара',
								},
							},
							{
								path: 'products/:productId/edit-product',
								component: EditProductPageComponent,
								data: {
									breadcrumb: 'Редактирование товара',
								},
							},
							{
								path: 'products/:productId',
								component: ProductDetailComponent,
								data: {
									breadcrumb: 'Товар',
								},
							},
							{
								path: 'orders/:orderId',
								component: OrderDetailComponent,
								data: {
									breadcrumb: 'Заказ',
								},
							},
							{
								path: 'settings',
								component: ShopSettingsComponent,
								data: {
									breadcrumb: 'Настройка магазина',
								},
								children: [
									{
										path: '',
										pathMatch: 'full',
										redirectTo: 'delivery',
									},
									{
										path: 'delivery',
										component: ShopSettingsDeliveryComponent,
									},
									{
										path: 'payment',
										component: ShopSettingsPaymentComponent,
									},
									{
										path: 'atol',
										component: PaymentAtolComponent,
									},
									{
										path: 'crm',
										component: ShopSettingsCrmComponent,
									},
									{
										path: 'newsletter',
										component: ShopSettingsNewsletterComponent,
									},
									{
										path: 'promo',
										component: ShopSettingsPromoComponent,
									},
									{
										path: 'hello',
										component: ShopSettingsHelloComponent,
									},
									{
										path: 'order-fields',
										component: ShopSettingsOrderFieldsComponent,
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'tarifs',
				component: TarifsComponent,
			},
			{
				path: 'subscriptions',
				component: SubscriptionsComponent,
			},
		],
	},
	{
		path: 'sign-up',
		component: AuthComponent,
		data: { form: 'sign-up' },
	},
	{
		path: 'sign-in',
		component: AuthComponent,
		data: { form: 'sign-in' },
	},
	{
		path: 'forgot-pass',
		component: AuthComponent,
		data: { form: 'forgot-pass' },
	},
	{
		path: 'reset-pass',
		component: AuthComponent,
		data: { form: 'reset-pass' },
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
