<div class="column">
	<div class="logo">
		<a routerLink="/">
			<tk-icon icon="icon-bag-revert" size="32"></tk-icon>
		</a>
	</div>

	<nav class="nav nav__divider" aria-label="nav" aria-labelledby="nav">
		<a routerLink="/" routerLinkActive="nav__link--active" class="nav__link">
			<tk-icon icon="home"></tk-icon>
		</a>
	</nav>
</div>

<nav class="column nav" aria-label="nav" aria-labelledby="nav">
	<a #user_origin (click)="selectUserMenu(true)" routerLinkActive="nav__link--active" class="nav__link">
		<img src="/assets/images/profile.png" alt="Profile image" />

		<ng-template
			cdkConnectedOverlay
			cdkConnectedOverlayHasBackdrop
			[cdkConnectedOverlayOrigin]="user_origin"
			[cdkConnectedOverlayOpen]="userMenuIsOpen"
			(backdropClick)="selectUserMenu(false)"
			cdkConnectedOverlayBackdropClass="backdrop--transparent"
			[cdkConnectedOverlayPositions]="positions"
		>
			<div class="panel">
				<a (click)="selectUserMenu(false)" routerLink="/tarifs" [queryParams]="shopService.utmQuery.value"
					>Тарифы</a
				>
				<a (click)="selectUserMenu(false)" routerLink="/subscriptions">Мои подписки</a>
			</div>
		</ng-template>
	</a>

	<div #origin class="lang" (click)="openLang()">
		<span>{{ lang }}</span>
		<ng-template
			cdkConnectedOverlay
			cdkConnectedOverlayHasBackdrop
			[cdkConnectedOverlayOrigin]="origin"
			[cdkConnectedOverlayOpen]="menuIsOpen"
			(backdropClick)="closeLang()"
			cdkConnectedOverlayBackdropClass="backdrop--transparent"
			[cdkConnectedOverlayPositions]="positions"
		>
			<div class="panel">
				<button (click)="setLang('ru')">Русский</button>
				<button (click)="setLang('en')">English</button>
				<button (click)="setLang('uz')">Oʻzbek</button>
			</div>
		</ng-template>
	</div>

	<button (click)="logout()" class="nav__link">
		<tk-icon icon="logout"></tk-icon>
	</button>
</nav>
