<ng-container *transloco="let t">
	<form class="form" [formGroup]="form" (submit)="submitForm()">
		<h2 class="form__title">{{ t('auth.create_text') }}</h2>
		<tk-form-field>
			<tk-label>{{ t('auth.email') }}</tk-label>

			<input formControlName="email" tkInput />
			<tk-error *ngIf="errors(form, 'email') as errorField" tkEmailError [errors]="errorField"></tk-error>
		</tk-form-field>

		<tk-form-field>
			<tk-label>{{ t('auth.pass') }}</tk-label>

			<input
				#password
				type="password"
				formControlName="password"
				tkInput
				tkInputTrim
				autocomplete="off"
				aria-labelledby="password"
			/>
			<tk-eye [input]="password" tkSuffix data-test-id="btn-password-eye"></tk-eye>
			<tk-hint>{{ t('auth.pass_text') }}</tk-hint>
			<tk-error *ngIf="errors(form, 'password') as errorField" tkPasswordError [errors]="errorField"></tk-error>
		</tk-form-field>

		<tk-form-field>
			<tk-label>{{ t('auth.pass_repeat') }}</tk-label>

			<input
				#passwordRepeat
				type="password"
				formControlName="passwordRepeat"
				tkInput
				tkInputTrim
				autocomplete="off"
				aria-labelledby="passwordRepeat"
			/>
			<tk-eye [input]="passwordRepeat" tkSuffix data-test-id="btn-password-eye"></tk-eye>

			<tk-error
				*ngIf="errors(form, 'passwordRepeat') as errorField"
				tkPasswordError
				[errors]="errorField"
			></tk-error>
		</tk-form-field>

		<tk-button-group class="tk-button-group">
			<button
				tkButton
				size="xl"
				theme="primary"
				type="submit"
				[disabled]="isDisabled"
				*ngIf="!(loading$ | async)"
			>
				{{ t('auth.sign_up') }}
			</button>
			<ng-container *ngIf="loading$ | async">
				<ng-container *ngTemplateOutlet="loader"></ng-container>
			</ng-container>
		</tk-button-group>

		<div class="privacy">
			{{ t('global.agree.agree_1') }}
			<a href="/assets/TGShop_Политика_конфиденциальности_27_07_23.docx">
				{{ t('global.agree.agree_2') }}
			</a>
			{{ t('global.agree.agree_3') }}
		</div>

		<div class="sign-in">
			{{ t('auth.have_an_acc') }} <a routerLink="/sign-in">{{ t('auth.have_sign_in') }}</a>
		</div>
	</form>

	<ng-template #loader>
		<tk-loader></tk-loader>
	</ng-template>
</ng-container>
