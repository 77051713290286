import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	NewsletterRequest,
	NewsletterResponse,
	NewsletterStatus,
	NewsletterUserGroupResponse,
	PromocodeRequest,
	PromocodeResponse,
	UpdateNewsletterStatusRequest,
} from '@models/generated/api';
import { WithPagingParams } from '@models/paging.interface';

@Injectable({
	providedIn: 'root',
})
export class PromoService {
	constructor(private http: HttpClient) {}

	getPromoList(shopId: string, params: WithPagingParams): Observable<PromocodeResponse[]> {
		// @ts-ignore
		return this.http.get<PromocodeResponse[]>(`shop/${shopId}/promocodes`, {
			params: { ...params, showDisabled: true },
		});
	}

	createPromo(shopId: string, body: PromocodeRequest): Observable<PromocodeResponse> {
		return this.http.post<PromocodeResponse>(`shop/${shopId}/promocodes`, body);
	}

	updatePromo(shopId: string, promoId: string): Observable<PromocodeResponse> {
		return this.http.post<PromocodeResponse>(`shop/${shopId}/promocodes/${promoId}/activity-switch`, {});
	}

	// delete
	deletePromo(shopId: string, promoId: string): Observable<PromocodeResponse> {
		return this.http.delete<PromocodeResponse>(`shop/${shopId}/promocodes/${promoId}`, {});
	}
}
