import { ValidationErrors } from '@angular/forms';
import { Injectable, Input } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FormErrorModel {
	_errors?: ValidationErrors | null;

	get errors() {
		return this._errors;
	}

	@Input()
	set errors(error: ValidationErrors | null | undefined) {
		this._errors = error;
	}
}
