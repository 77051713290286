/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ShopStockUpdateRequest = {
	isEnabled: boolean;
	updateStockUrl: string;
	periodType: ShopStockUpdateRequest.periodType;
	onceAtDay?: string;
	hoursInterval?: number;
};

export namespace ShopStockUpdateRequest {
	export enum periodType {
		ONCE_AT_DAY = 'ONCE_AT_DAY',
		HOURS_PERIOD = 'HOURS_PERIOD',
	}
}
