<div class="head">
	<h2>Тарифы</h2>
	<div class="year-switch">
		<div (click)="setYearSwitch(false)" class="year-switch__item" [ngClass]="!yearSwitch && 'active'">
			Помесячная оплата
		</div>
		<div (click)="setYearSwitch(true)" class="year-switch__item" [ngClass]="yearSwitch && 'active'">
			Оплата за год (-20%)
		</div>
	</div>
</div>
<div *ngIf="isPromocodeAvailable" class="promocode__wrap">
	<div class="promocode__text">Есть промокод?</div>
	<tk-form-field class="promocode__field">
		<tk-label>Введите промокод</tk-label>

		<input
			[disabled]="isPromocodeActive"
			type="text"
			tkInput
			[formControl]="promocodeControl"
			oninput="this.value = this.value.toUpperCase()"
		/>

		<div *ngIf="!isPromocodeActive" class="promo-btn" (click)="selectPromocode()" tkSuffix>Применить</div>
		<div *ngIf="isPromocodeActive" class="promo-btn promo-btn--red" (click)="removePromocode()" tkSuffix>
			Отменить
		</div>
	</tk-form-field>
</div>

<div class="tarifs">
	<ng-container *ngFor="let plan of plans">
		<div class="tarifs__item">
			<div class="popular" *ngIf="plan.isPopular">
				<tk-icon class="icon" icon="flash" size="16"></tk-icon>
				Популярный
			</div>
			<div>
				<h2>{{ plan.planName }}</h2>
				<h2 class="price">
					{{ yearSwitch ? plan.realPriceForYear + ' ₽/мес.' : plan.realPriceForMonth + ' ₽/мес.' }}
				</h2>
				<div *ngIf="yearSwitch || plan.realPriceForMonth != plan.basePrice" class="price--hidden">
					{{ plan.basePrice }} ₽/мес.
				</div>
				<ng-container *ngFor="let feature of plan.features">
					<div *ngIf="feature.type === 'MAIN'" class="limit">{{ feature.featureName }}</div>
				</ng-container>

				<div class="list">
					<ng-container *ngFor="let feature of plan.features">
						<div *ngIf="feature.type !== 'MAIN'" class="list__item">
							<tk-icon
								class="icon"
								[ngClass]="feature.isAvailable ? 'icon--blue' : 'icon--red'"
								[icon]="feature.isAvailable ? 'success' : 'close'"
								size="20"
							></tk-icon>
							{{ feature.featureName }}
						</div>
					</ng-container>
				</div>
			</div>

			<div>
				<div class="renew">
					<div class="wrap">
						<tk-checkbox
							[disabled]="isPromocodeActive"
							[hidden]="isPromocodeActive"
							[(ngModel)]="isRenew"
						></tk-checkbox>
						Согласен на автопродление подписки стоимостью
						{{ (yearSwitch ? plan.totalPrice : plan.realPriceForMonth) + ' рублей' }} в
						{{ yearSwitch ? 'год' : 'месяц' }}
					</div>
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						tkTooltip="Подписка автоматически продлевается незадолго до окончания срока, чтобы обеспечить непрерывность вашей работы"
						tkTooltipPlacement="top"
					></tk-icon>
				</div>

				<div class="policy">
					<div class="wrap">
						<tk-checkbox [(ngModel)]="policy"></tk-checkbox>
						<div>
							Ознакомлен с
							<a href="/assets/_TGShop_Пользовательское_соглашение_на_сайт_27_07_23.docx" target="_blank">
								офертой
							</a>
							и согласен на обработку
							<a href="/assets/TGShop_Политика_конфиденциальности_27_07_23.docx"> персональных данных </a>
						</div>
					</div>
				</div>

				<button
					class="button"
					tkButton
					type="button"
					size="xl"
					theme="primary"
					(click)="selectPlan(plan)"
					*ngIf="!(loading$ | async)"
					[disabled]="!policy"
				>
					Оформить
				</button>
				<ng-container *ngIf="loading$ | async">
					<tk-loader class="loader" width="FULL"></tk-loader>
				</ng-container>

				<div class="hint">{{ plan.planDescription }}</div>
			</div>
		</div>
	</ng-container>
</div>
<div class="warning">
	<b
		>Важно! При проблемах с оплатой тарифа пишите в личные сообщения
		<a href="https://t.me/kirill_tgshop" target="_blank">@kirill_tgshop</a></b
	>
</div>
