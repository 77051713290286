import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ShopResponse } from '@models/generated/api';
import shopState = ShopResponse.shopState;
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
	selector: 'tg-shop-status-change',
	templateUrl: './shop-status-change.component.html',
	styleUrls: ['./shop-status-change.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ShopStatusChangeComponent),
			multi: true,
		},
	],
})
export class ShopStatusChangeComponent implements ControlValueAccessor {
	@Input() canEdit: boolean = true;

	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'center',
			overlayY: 'top',
			offsetY: 10,
		},
	];

	innerValue?: shopState;
	shopState = shopState;
	isOpen: boolean = false;

	constructor(private cdr: ChangeDetectorRef) {}

	onChangeCallback = (v: shopState) => {};
	onTouchedCallback = () => {};

	toggle() {
		if (this.canEdit) {
			this.isOpen = !this.isOpen;
		}
	}

	close() {
		this.isOpen = false;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	writeValue(value: shopState): void {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.cdr.detectChanges();
		}
	}

	changeStatus(status: shopState): void {
		this.onChangeCallback(status);
		this.onTouchedCallback();
		this.close();
	}
}
