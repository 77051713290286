import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DictionaryCodeValueResponse, ProductDimensionsTypeResponse } from '@models/generated/api';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class DictionariesService {
	constructor(private http: HttpClient) {}

	getVatCodes(): Observable<DictionaryCodeValueResponse[]> {
		return this.http.get<DictionaryCodeValueResponse[]>('dictionaries/vat-codes');
	}

	getTaxationCodes(): Observable<DictionaryCodeValueResponse[]> {
		return this.http.get<DictionaryCodeValueResponse[]>('dictionaries/taxations');
	}

	getFFDCodes(): Observable<DictionaryCodeValueResponse[]> {
		return this.http.get<DictionaryCodeValueResponse[]>('dictionaries/ffd');
	}

	getProductTransfers(): Observable<DictionaryCodeValueResponse[]> {
		return this.http.get<DictionaryCodeValueResponse[]>('dictionaries/product-transfers');
	}

	getProductDimensions(): Observable<ProductDimensionsTypeResponse[]> {
		return this.http.get<ProductDimensionsTypeResponse[]>('dictionaries/product-dimensions');
	}
}
