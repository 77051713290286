import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DictionariesService } from '@services/dictionaries.service';
import { errors } from '@utils/validations';

@Component({
	selector: 'tg-payment-custom',
	templateUrl: './payment-custom.component.html',
	styleUrls: ['./payment-custom.component.sass', '../shop-settings-payment.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCustomComponent {
	shopId: string;

	form = new FormGroup({
		name: new FormControl('', [Validators.maxLength(100)]),
		description: new FormControl('', [Validators.maxLength(100)]),
		discountValue: new FormControl('', []),
		discountType: new FormControl('DISCOUNT', []),
		vatCode: new FormControl('NO_VAT'),
	});

	constructor(
		private route: ActivatedRoute,
		public shopService: ShopService,
		private dictionariesService: DictionariesService,
		private cdr: ChangeDetectorRef,
		@Inject(DIALOG_DATA) public dialogData: any,
		public dialogRef: DialogRef,
	) {
		if (this.dialogData) {
			this.shopId = this.dialogData.shopId;
			if (this.dialogData.formValue) {
				this.form.patchValue(this.dialogData.formValue, { emitEvent: false });
				this.cdr.markForCheck();
			}
		}
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	get discountType() {
		return this.form.controls.discountType.value;
	}

	submit(): void {
		const value = this.form.value;

		this.dialogRef.close(this.form.value);
	}

	cancel(): void {
		this.dialogRef.close(null);
	}
}
