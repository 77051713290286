<ng-container *transloco="let t; read: 'main'">
	<button
		class="status"
		cdkOverlayOrigin
		#trigger="cdkOverlayOrigin"
		[class.status--active]="innerValue === shopState.ACTIVE"
		[class.status--disable]="innerValue === shopState.DISABLE"
		[class.status--stopping]="innerValue === shopState.STOPPING"
		[ngSwitch]="innerValue"
		(click)="toggle()"
	>
		<ng-container *ngSwitchCase="shopState.ACTIVE">{{ t('active') }}</ng-container>
		<ng-container *ngSwitchCase="shopState.DISABLE">{{ t('inactive') }}</ng-container>
		<ng-container *ngSwitchCase="shopState.STOPPING">{{ t('suspended') }}</ng-container>

		<tk-icon *ngIf="innerValue !== shopState.STOPPING && canEdit" icon="arrow-down"></tk-icon>
	</button>

	<ng-template
		cdkConnectedOverlay
		cdkConnectedOverlayHasBackdrop
		cdkConnectedOverlayBackdropClass="backdrop--transparent"
		[cdkConnectedOverlayOrigin]="trigger"
		[cdkConnectedOverlayOpen]="isOpen"
		(backdropClick)="close()"
		[cdkConnectedOverlayPositions]="positions"
	>
		<div class="panel">
			<button *ngIf="innerValue === shopState.DISABLE" (click)="changeStatus(shopState.ACTIVE)">
				{{ t('make_active') }}
			</button>
			<button *ngIf="innerValue === shopState.ACTIVE" (click)="changeStatus(shopState.DISABLE)">
				{{ t('stop') }}
			</button>
		</div>
	</ng-template>
</ng-container>
