import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AddressResponse, AddressSuggestionResponse } from '@models/generated/api';
import { BehaviorSubject } from 'rxjs';
import { ProductService } from '@services/product.service';
import { ToastService } from '@services/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DadataService } from '@services/dadata.service';
import { DeliverySettingsService } from '@services/delivery-setting.service';
import { AuthService } from '@services/auth.service';
import { YaGeocoderService, YaReadyEvent } from 'angular8-yandex-maps';
import { PlacemarkConstructor } from '@utils/map';

@Component({
	selector: 'tg-provider-point',
	templateUrl: './provider-point.component.html',
	styleUrls: ['./provider-point.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderPointComponent {
	pointsList$ = new BehaviorSubject<any[]>([]);
	selectedPoint = new BehaviorSubject<any>(null);
	step: 0 | 1 = 0;

	map?: ymaps.Map;
	mapPoints: PlacemarkConstructor[] = [];

	form: FormGroup = new FormGroup({
		'city': new FormControl(null, Validators.required),
		'selectedPoint': new FormControl(null, Validators.required),
	});

	fiasList: AddressSuggestionResponse[] = [];
	fiasSelected: AddressSuggestionResponse | AddressResponse | null = null;

	constructor(
		private dialogRef: DialogRef,
		private productService: ProductService,
		private toast: ToastService,
		@Inject(DIALOG_DATA) public dialogData: any,
		private cdr: ChangeDetectorRef,
		private dadataService: DadataService,
		private deliverySettingsService: DeliverySettingsService,
		private authService: AuthService,
		private yaGeocoderService: YaGeocoderService,
	) {
		this.selectedPoint.subscribe({
			next: () => {
				setTimeout(() => {
					this.cdr.detectChanges();
				}, 50);
			},
		});
	}

	get address() {
		return this.form.get('city');
	}

	getDadata(s: string) {
		this.dadataService.getDadata(s, 'CITY').subscribe({
			next: res => {
				this.fiasList = res;
				this.cdr.markForCheck();
			},
		});
	}

	onChangeSearch() {
		if (this.address.value.length < 3) {
			this.fiasList = [];
			return;
		}

		this.address.setErrors({ 'fias': null });
		this.address.updateValueAndValidity();
		this.cdr.markForCheck();
		this.getDadata(this.address.value);
	}

	selectEvent(item) {
		this.fiasSelected = item;
		this.address.setErrors({ 'fias': null });
		this.address.updateValueAndValidity();
		this.cdr.markForCheck();
	}

	selectPoint(point: any) {
		this.selectedPoint.next(point);
	}

	onMapReady({ target }: YaReadyEvent<ymaps.Map>): void {
		this.pointsList$.subscribe({
			next: res => {
				this.yaGeocoderService.geocode(this.fiasSelected['address']).subscribe({
					next: (result: object) => {
						const firstGeoObject = (result as any).geoObjects.get(0);
						target.setCenter(firstGeoObject.geometry.getCoordinates());
						this.cdr.markForCheck();
					},
				});
				this.createCustomPoints(this.pointsList$.value!, target);
				this.cdr.markForCheck();
			},
		});

		this.selectedPoint.subscribe({
			next: () => {
				target.geoObjects.each(geoObject => {
					if (geoObject.properties.get('data').pickPointId === this.selectedPoint.value.pickPointId) {
						geoObject.balloon.open(target.getCenter());
					}
				});
			},
		});

		target.events.add('mousedown', e => {
			target.balloon.close();
		});

		target.events.add('balloonopen', e => {
			const pointData = e.get('target').properties._data.data;
			this.selectedPoint.next(pointData);
			setTimeout(() => {
				document.getElementById(pointData.pickPointId).scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest',
				});
				this.cdr.detectChanges();
			}, 100);
		});
	}

	createCustomPoints(points: any[], map: ymaps.Map) {
		const placemarks: PlacemarkConstructor[] = [];

		points?.forEach(point => {
			placemarks.push({
				geometry: [point.latitude!, point.longitude!],
				properties: {
					balloonContent: '',
					data: point,
				},
				options: {
					iconColor: '#2aabee',
					balloonContentLayout: ymaps.templateLayoutFactory.createClass(
						'<div class="balloon__name">' +
							point.name +
							'</div> ' +
							'<div class="balloon__address">' +
							point.address +
							'</div> ',
					),
				},
			});
		});
		this.mapPoints = placemarks;
		this.cdr.markForCheck();
	}

	next(): void {
		if (this.fiasSelected.fiasId) {
			this.deliverySettingsService
				.getApishipPickupPoints(this.dialogData.token, this.dialogData.providerKey, this.fiasSelected.fiasId)
				.subscribe({
					next: res => {
						this.pointsList$.next(res);
						this.step = 1;
						this.cdr.markForCheck();
					},
				});
		}
	}

	submit(): void {
		this.dialogRef.close(this.selectedPoint.value);
	}

	close(): void {
		this.dialogRef.close(null);
	}
}
