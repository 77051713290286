/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PhotoCreateRequest } from './PhotoCreateRequest';
import type { PositionCreateRequest } from './PositionCreateRequest';

/**
 * Форма создания/редактирования позиции
 */
export type VendorCodeCreateRequest = {
	/**
	 * Артикул товара
	 */
	vendorCodeValue?: string;
	/**
	 * Идентификатор цвета товара
	 */
	colorId?: VendorCodeCreateRequest.colorId;
	/**
	 * Цена товара
	 */
	price: number;
	/**
	 * Цена товара
	 */
	discount: number;
	/**
	 * Список размеров
	 */
	positions: Array<PositionCreateRequest>;
	/**
	 * Список фотографий
	 */
	photos: Array<PhotoCreateRequest>;
	title: boolean;
};

export namespace VendorCodeCreateRequest {
	/**
	 * Идентификатор цвета товара
	 */
	export enum colorId {
		DEFAULT = 'DEFAULT',
		AZURE = 'AZURE',
		BEIGE = 'BEIGE',
		BEIGE_MELANGE = 'BEIGE_MELANGE',
		BLACK = 'BLACK',
		BLUE = 'BLUE',
		BLUE_MELANGE = 'BLUE_MELANGE',
		BORDEAUX = 'BORDEAUX',
		BRONZE = 'BRONZE',
		BROWN = 'BROWN',
		CANDY_PINK = 'CANDY_PINK',
		CARAMEL = 'CARAMEL',
		CHARCOAL = 'CHARCOAL',
		CHERRY = 'CHERRY',
		CHOCOLATE = 'CHOCOLATE',
		COPPER = 'COPPER',
		CORAL = 'CORAL',
		CORAL_MELANGE = 'CORAL_MELANGE',
		CORAL_PINK = 'CORAL_PINK',
		CREAM = 'CREAM',
		CRIMSON = 'CRIMSON',
		DARK_BLUE = 'DARK_BLUE',
		DARK_BROWN = 'DARK_BROWN',
		DARK_GRAY = 'DARK_GRAY',
		DARK_GREEN = 'DARK_GREEN',
		DARK_MAROON = 'DARK_MAROON',
		DARK_PINK = 'DARK_PINK',
		DARK_TURQUOISE = 'DARK_TURQUOISE',
		DEEP_BLUE = 'DEEP_BLUE',
		DEEP_LILAC = 'DEEP_LILAC',
		FOREST_GREEN = 'FOREST_GREEN',
		FUCHSIA = 'FUCHSIA',
		GINGER = 'GINGER',
		GOLDEN = 'GOLDEN',
		GRANITE_GRAY = 'GRANITE_GRAY',
		GRAY = 'GRAY',
		GREEN = 'GREEN',
		ICE_MELT = 'ICE_MELT',
		INDIGO = 'INDIGO',
		IVORY = 'IVORY',
		KHAKI = 'KHAKI',
		KHAKI_GOLD = 'KHAKI_GOLD',
		KHAKI_GREEN = 'KHAKI_GREEN',
		LAVENDER = 'LAVENDER',
		LEMON = 'LEMON',
		LETTUCE_GREEN = 'LETTUCE_GREEN',
		LIGHT_BEIGE = 'LIGHT_BEIGE',
		LIGHT_BLUE = 'LIGHT_BLUE',
		LIGHT_BROWN = 'LIGHT_BROWN',
		LIGHT_ORANGE = 'LIGHT_ORANGE',
		LIGHT_YELLOW = 'LIGHT_YELLOW',
		LILAC = 'LILAC',
		LIME = 'LIME',
		MANGO = 'MANGO',
		MARSALA = 'MARSALA',
		MELANGE_GRAY = 'MELANGE_GRAY',
		METALLIC_GRAY = 'METALLIC_GRAY',
		MILKY = 'MILKY',
		MINT = 'MINT',
		MUSTARD = 'MUSTARD',
		OLIVE = 'OLIVE',
		ORANGE = 'ORANGE',
		PALE_KHAKI = 'PALE_KHAKI',
		PASTEL_CORAL = 'PASTEL_CORAL',
		PINK = 'PINK',
		POWDER = 'POWDER',
		PURPLE = 'PURPLE',
		RED = 'RED',
		SAND_KHAKI = 'SAND_KHAKI',
		SCARLET = 'SCARLET',
		SEA_BLUE = 'SEA_BLUE',
		SILVER = 'SILVER',
		SKY_BLUE = 'SKY_BLUE',
		TERRACOTTA = 'TERRACOTTA',
		TRANSPARENT = 'TRANSPARENT',
		TURQUOISE = 'TURQUOISE',
		VIOLET = 'VIOLET',
		WHITE = 'WHITE',
		YELLOW = 'YELLOW',
	}
}
