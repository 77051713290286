<ng-container *transloco="let t;">
	<tg-breadcrumbs></tg-breadcrumbs>
	<h2>{{ t('edit.edit') }}</h2>
	<div class="wrap-container">
		<div>
			<tg-tabs>
				<button
					*ngFor="let item of steps"
					[class.active]="activeStep.index === item.index"
					(click)="setStep(item)"
					[class.disabled]="!item.canOpen"
				>
					{{item.title}}
				</button>
			</tg-tabs>

			<form [formGroup]="form">
				<ng-container [ngSwitch]="activeStep.index">
					<ng-container *ngSwitchCase="0">
						<tk-form-field>
							<tk-label>{{ t('edit.name') }}</tk-label>

							<input type="text" tkInput formControlName="productName" />
							<tk-error
								*ngIf="errors(form, 'productName') as errorField"
								tkCommonError
								[errors]="errorField"
							></tk-error>
						</tk-form-field>

						<tk-form-field class="field--textarea">
							<tk-label>{{ t('edit.short_desc') }}</tk-label>

							<textarea type="text" tkInput formControlName="shortDescription"></textarea>
							<tk-error
								*ngIf="errors(form, 'shortDescription') as errorField"
								tkCommonError
								[errors]="errorField"
							></tk-error>
						</tk-form-field>

						<tk-form-field class="field--textarea">
							<tk-label>{{ t('edit.detail_desc') }}</tk-label>

							<textarea type="text" tkInput formControlName="fullDescription"></textarea>
							<tk-error
								*ngIf="errors(form, 'fullDescription') as errorField"
								tkCommonError
								[errors]="errorField"
							></tk-error>
						</tk-form-field>

						<tk-form-field>
							<tk-label>{{ t('products.priority') }}</tk-label>

							<input type="number" appOnlynumber tkInput formControlName="priority" />
							<tk-hint>{{ t('global.optional') }}</tk-hint>
							<tk-error
								*ngIf="errors(form, 'priority') as errorField"
								tkCommonError
								[errors]="errorField"
							></tk-error>
						</tk-form-field>

						<div *ngIf="shopService.isDigitalShop()" class="thanks">
							<div class="tooltip">
								<tk-icon class="tooltip__icon" icon="info"></tk-icon>
								<pre><p>Чтобы отформатировать текст, используйте специальные символы:
1. *жирный*
2. _курсив_
3. [ссылка](http://tgshop.io)</p></pre>
							</div>

							<div class="thanks__wrap">
								<tk-form-field class="field--textarea thanks__field">
									<tk-label>Благодарность</tk-label>

									<textarea type="text" tkInput formControlName="thankYouText"></textarea>
									<tk-error
										*ngIf="errors(form, 'thankYouText') as errorField"
										tkCommonError
										[errors]="errorField"
									></tk-error>
									<tk-hint>Этот текст будет показан клиенту после покупки товара</tk-hint>
								</tk-form-field>

								<div>
									<div class="thanks__preview">
										<div [innerHTML]="preview"></div>
									</div>
									<tk-hint>Предпросмотр</tk-hint>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="1">
						<ng-container *ngIf="!shopService.isDigitalShop()">
							<div class="tooltip">
								<tk-icon class="tooltip__icon" icon="info"></tk-icon>
								<p>{{ t('edit.info_text') }}</p>
							</div>

							<h4>{{ t('edit.size') }}</h4>
							<ng-container *ngIf="!(typeId$ | async)">
								<div class="default-tag">
									<tk-icon icon="success"></tk-icon>
									{{ t('edit.default') }}
								</div>

								<button tkButton theme="ghost" (click)="selectType()" class="add-button">
									<tk-icon icon="plus"></tk-icon>
									{{ t('edit.add_size') }}
								</button>
							</ng-container>

							<ng-container *ngIf="typeId$ | async">
								<div class="grey-container">
									<div class="grey-container__header">
										<h5>{{typeId$.value.sizeTypeName}}</h5>
										<div class="grey-container__close" (click)="removeSelectedType()">
											<tk-icon icon="trash"></tk-icon>
										</div>
									</div>

									<tk-form-field>
										<tk-label>{{ t('edit.size') }}</tk-label>
										<tk-select
											formControlName="sizes"
											multiple
											(ngModelChange)="updateAmountFields(true)"
										>
											<tk-option *ngFor="let size of typeSizeList$ | async" [value]="size">
												{{size.sizeValue}}
											</tk-option>
										</tk-select>
									</tk-form-field>
								</div>
							</ng-container>

							<h4>{{ t('edit.options') }}</h4>

							<div class="colors" *ngIf="selectedColors | async">
								<div
									*ngFor="let color of selectedColors | async"
									(click)="setActiveColor(color)"
									class="colors__item"
									[class.active]="color.id === activeColor?.id"
								>
									<span [style.background-color]="color.rgbCode"></span>
									{{color.colorName}}
								</div>
							</div>

							<div class="default-tag" *ngIf="!(selectedColors | async)">
								<tk-icon icon="success"></tk-icon>
								{{ t('edit.default') }}
							</div>
						</ng-container>

						<div class="grey-container">
							<div *ngIf="!shopService.isDigitalShop()" class="grey-container__header">
								<h5>{{activeColor ? activeColor.colorName : t('edit.default_color') }}</h5>
								<div class="grey-container__controls">
									<div
										class="checkbox"
										(click)="setTitleColor()"
										*ngIf="(selectedColors | async)?.length"
									>
										<tk-checkbox [ngModel]="isTitleColor()" [ngModelOptions]="{standalone: true}">
											{{ t('edit.make_title') }}
										</tk-checkbox>
									</div>
									<div *ngIf="activeColor" class="grey-container__close" (click)="removeColor()">
										<tk-icon icon="trash"></tk-icon>
									</div>
								</div>
							</div>

							<tk-form-field>
								<tk-label>{{ t('edit.price') }}, {{ shopService.shopMoneySign.value }}</tk-label>

								<input type="number" tkInput formControlName="price" />
								<tk-error
									*ngIf="errors(form, 'price') as errorField"
									tkCommonError
									[errors]="errorField"
								></tk-error>
							</tk-form-field>

							<div class="field-container--medium">
								<div>
									<tk-form-field>
										<tk-label>{{ t('edit.discount') }}</tk-label>

										<input type="number" tkInput appOnlynumber formControlName="discount" />
										<tk-error
											*ngIf="errors(form, 'discount') as errorField"
											tkCommonError
											[errors]="errorField"
										></tk-error>
									</tk-form-field>
									<div class="hint">{{ t('global.optional') }}</div>
								</div>

								<div>
									<tk-form-field>
										<tk-label>{{ t('edit.sku') }}</tk-label>

										<input type="text" tkInput formControlName="vendorCodeValue" />
										<tk-error
											*ngIf="errors(form, 'vendorCodeValue') as errorField"
											tkCommonError
											[errors]="errorField"
										></tk-error>
									</tk-form-field>
								</div>
							</div>

							<h5 class="h-margin">{{ t('products.quantity') }}</h5>

							<ng-container *ngIf="!this.form.value.sizes">
								<tk-form-field>
									<tk-label>{{ t('edit.quantity') }}</tk-label>

									<input type="number" tkInput formControlName="amount" appOnlynumber />
									<tk-error
										*ngIf="errors(form, 'amount') as errorField"
										tkCommonError
										[errors]="errorField"
									></tk-error>
								</tk-form-field>

								<div *ngIf="!shopService.isDigitalShop()" class="hint hint--neg-margin">
									{{ t('edit.quantity_text') }}
								</div>
							</ng-container>

							<ng-container *ngIf="this.form.value.sizes">
								<div class="slider" (click)="toggleDetail()" [class.slider--rotate]="isDetail">
									{{ t('edit.quantity_size') }}
									<tk-icon icon="arrow-down"></tk-icon>
								</div>

								<ng-container *ngIf="isDetail">
									<div class="field-container--small">
										<ng-container *ngFor="let position of detailData?.positions">
											<tk-form-field
												class="size-amount"
												[ngClass]="{'tk-form-field--invalid': position.amount === null}"
											>
												<tk-label>{{position.name}}</tk-label>
												<input
													tkInput
													appOnlynumber
													min="0"
													[id]="position.sizeId"
													max="999999999999999999"
													maxlength="18"
													type="text"
													[value]="position.amount"
													(change)="changePositionAmount($event, position)"
												/>
												<tk-error *ngIf="position.amount === null">Обязательное поле</tk-error>
											</tk-form-field>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>
						</div>

						<ng-container *ngIf="!shopService.isDigitalShop()">
							<button
								tkButton
								theme="ghost"
								(click)="selectColors(selectedColors.value)"
								class="add-button"
							>
								<tk-icon icon="plus"></tk-icon>
								{{ t('edit.add_color') }}
							</button>

							<h4>Габариты и вес товаров</h4>

							<div class="grey-container grey-container--short">
								<div class="grey-container__header">
									<h5>Габариты</h5>
								</div>

								<tk-form-field>
									<tk-label>Тип габаритов</tk-label>
									<tk-select formControlName="dimensionType">
										<tk-option
											*ngFor="let item of dimensionList"
											[value]="item.productDimensionType"
										>
											Тип {{ item.productDimensionType }}: {{ item.width }}×{{ item.height }}×{{
											item.length }} см</tk-option
										>
										<tk-option value="Другое"> Другое </tk-option>
									</tk-select>
									<tk-error
										*ngIf="errors(form, 'dimensionType') as errorField"
										tkCommonError
										[errors]="errorField"
									></tk-error>
									<tk-hint>{{ t('global.optional') }}</tk-hint>
								</tk-form-field>

								<div *ngIf="form.value.dimensionType === 'Другое'" class="other">
									<tk-form-field>
										<tk-label>
											Длина
											<tk-icon
												class="icon"
												icon="info"
												size="20"
												tkTooltip="Только целые числа"
												tkTooltipPlacement="bottom"
											></tk-icon>
										</tk-label>
										<input
											type="number"
											appOnlynumber
											tkInput
											tkInputTrim
											formControlName="length"
											step="0.001"
										/>
										<tk-error
											*ngIf="errors(form, 'length') as errorField"
											tkCommonError
											[errors]="errorField"
										></tk-error>
									</tk-form-field>
									<tk-form-field>
										<tk-label>
											Ширина
											<tk-icon
												class="icon"
												icon="info"
												size="20"
												tkTooltip="Только целые числа"
												tkTooltipPlacement="bottom"
											></tk-icon>
										</tk-label>
										<input
											type="number"
											appOnlynumber
											tkInput
											tkInputTrim
											formControlName="width"
										/>
										<tk-error
											*ngIf="errors(form, 'width') as errorField"
											tkCommonError
											[errors]="errorField"
										></tk-error>
									</tk-form-field>
									<tk-form-field>
										<tk-label>
											Высота
											<tk-icon
												class="icon"
												icon="info"
												size="20"
												tkTooltip="Только целые числа"
												tkTooltipPlacement="bottom"
											></tk-icon>
										</tk-label>
										<input
											type="number"
											appOnlynumber
											tkInput
											tkInputTrim
											formControlName="height"
										/>
										<tk-error
											*ngIf="errors(form, 'height') as errorField"
											tkCommonError
											[errors]="errorField"
										></tk-error>
									</tk-form-field>
								</div>
							</div>

							<div class="grey-container grey-container--short">
								<div class="grey-container__header">
									<h5>Приблизительный вес товаров</h5>
								</div>

								<tk-form-field>
									<tk-label>Вес товара, гр</tk-label>

									<input type="number" appOnlynumber tkInput tkInputTrim formControlName="weight" />
									<tk-error
										*ngIf="errors(form, 'weight') as errorField"
										tkCommonError
										[errors]="errorField"
									></tk-error>
									<tk-hint>{{ t('global.optional') }}</tk-hint>
								</tk-form-field>
							</div>
						</ng-container>
					</ng-container>

					<ng-container *ngSwitchCase="2">
						<div class="tooltip">
							<tk-icon class="tooltip__icon" icon="info"></tk-icon>
							<div>
								<p>{{ t('edit.img_format_text.1') }}</p>
								<p>{{ t('edit.img_format_text.2') }}</p>
								<p>{{ t('edit.img_format_text.3') }}</p>
							</div>
						</div>

						<ng-container *ngIf="!(selectedColors | async)">
							<h3>{{ t('edit.cover_img') }}</h3>

							<div class="tooltip tooltip--file dropzone">
								<p *ngIf="!titlePhotoId">{{ t('edit.img_upload_tip', { number: 1 }) }}</p>

								<p *ngIf="titlePhotoId">
									Добавлено максимум фото. Добавление недоступно. Чтобы добавить фото, сначала удалите
									ненужные.
								</p>

								<label *ngIf="!titlePhotoId" for="fileDropRef">
									<tk-icon icon="button"></tk-icon>
								</label>

								<input
									type="file"
									#fileDropRef
									id="fileDropRef"
									multiple
									[hidden]="titlePhotoId"
									(change)="uploadTitlePhoto($event)"
								/>
							</div>

							<ng-container *ngIf="titlePhotoId">
								<div class="photo photo--title">
									<img [src]="(titlePhotoId | getFile | async)" alt />
									<div class="photo__delete" (click)="deleteTitlePhoto()">
										<tk-icon icon="cross"></tk-icon>
									</div>
								</div>
							</ng-container>

							<h3>{{ t('edit.other_img') }}</h3>

							<div *ngIf="!shopService.isDigitalShop()" class="default-tag">
								<tk-icon icon="success"></tk-icon>
								{{ t('edit.default') }}
							</div>

							<div class="tooltip tooltip--file dropzone">
								<p *ngIf="defaultData.photos?.length < 10">
									{{ t('edit.img_upload_tip', { number: 10 }) }}
								</p>

								<p *ngIf="defaultData.photos?.length === 10">
									Добавлено максимум фото. Добавление недоступно. Чтобы добавить фото, сначала удалите
									ненужные.
								</p>

								<label *ngIf="defaultData.photos?.length < 10" for="defaultFileDropRef">
									<tk-icon icon="button"></tk-icon>
								</label>

								<input
									type="file"
									#defaultFileDropRef
									id="defaultFileDropRef"
									multiple
									[hidden]="defaultData.photos?.length === 10"
									(change)="uploadCommonPhoto($event, false, defaultData.photos)"
								/>
							</div>

							<ng-container *ngIf="defaultData.photos">
								<div class="photo__container">
									<div class="photo" *ngFor="let photo of defaultData.photos">
										<img [src]="(photo.photoId | getFile | async)" alt />
										<div class="photo__delete" (click)="deleteDefaultPhoto(photo.photoId)">
											<tk-icon icon="cross"></tk-icon>
										</div>
									</div>
								</div>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="(selectedColors | async)">
							<h3>{{ t('edit.colors') }}</h3>

							<ng-container *ngFor="let color of (selectedColors | async)">
								<div class="colors colors__item">
									<span [style.background-color]="color.rgbCode"></span>
									{{color.colorName}}
								</div>

								<div class="tooltip tooltip--file dropzone">
									<p *ngIf="findColorById(color.id).photos?.length < 10">
										{{ t('edit.img_upload_tip', { number: 10 }) }}
									</p>

									<p *ngIf="findColorById(color.id).photos?.length === 10">
										Добавлено максимум фото. Добавление недоступно. Чтобы добавить фото, сначала
										удалите ненужные.
									</p>

									<label *ngIf="findColorById(color.id).photos?.length < 10" [for]="color.id">
										<tk-icon icon="button"></tk-icon>
									</label>

									<input
										type="file"
										[id]="color.id"
										multiple
										[hidden]="findColorById(color.id).photos?.length === 10"
										(change)="uploadCommonPhoto($event, true, findColorById(color.id).photos)"
									/>
								</div>

								<div class="photo__wrap" *ngIf="findColorById(color.id).photos.length > 0">
									<label [for]="'title' + color.id">
										<div class="photo--color-title">
											<span
												*ngIf="filterByTitle(findColorById(color.id).photos, true).length === 0"
												>Титульное фото цвета</span
											>
											<div
												class="photo"
												*ngFor="let photo of filterByTitle(findColorById(color.id).photos, true)"
											>
												<img [src]="(photo.photoId | getFile | async)" alt />
												<div
													class="photo__delete"
													(click)="deleteColorPhoto(color.id, photo.photoId)"
												>
													<tk-icon icon="cross"></tk-icon>
												</div>
											</div>
										</div>
									</label>

									<input
										type="file"
										[id]="'title' + color.id"
										class="input input--title"
										[hidden]="findColorById(color.id).photos?.length === 10"
										(change)="uploadCommonPhoto($event, true, findColorById(color.id).photos)"
									/>

									<div class="photo__container">
										<div
											class="photo"
											*ngFor="let photo of filterByTitle(findColorById(color.id).photos, false)"
										>
											<img [src]="(photo.photoId | getFile | async)" alt />
											<div
												class="photo__delete"
												(click)="deleteColorPhoto(color.id, photo.photoId)"
											>
												<tk-icon icon="cross"></tk-icon>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>

				<tk-button-group>
					<button
						type="button"
						tkButton
						theme="secondary"
						(click)="prevStep()"
						[disabled]="activeStep.index === 0"
					>
						{{ t('global.cancel') }}
					</button>

					<button type="button" tkButton theme="primary" (click)="nextStep()" *ngIf="!(loading$ | async)">
						{{activeStep.index === 2 ? t('products.edit_btn') : t('edit.next') }}
					</button>
					<ng-container *ngIf="loading$ | async">
						<ng-container *ngTemplateOutlet="loader"></ng-container>
					</ng-container>
				</tk-button-group>
			</form>
		</div>
	</div>
</ng-container>

<ng-template #loader>
	<tk-loader width="FULL"></tk-loader>
</ng-template>
