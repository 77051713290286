import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ShopResponse } from '@models/generated/api';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';

@Component({
	selector: 'tg-shop-card',
	templateUrl: './shop-card.component.html',
	styleUrls: ['./shop-card.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class ShopCardComponent {
	@Input() shop!: ShopResponse;

	@Output() onDelete = new EventEmitter();
	@Output() onEdit = new EventEmitter<ShopResponse>();

	editShop(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.onEdit.emit();
	}

	deleteShop(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.onDelete.emit();
	}
}
