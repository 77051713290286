import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CategoryCreateDto } from './types';
import { CategoryResponse } from '@models/generated/api';

@Injectable({
	providedIn: 'root',
})
export class CategoriesAPIService {
	constructor(private http: HttpClient) {}

	get(shopId: string, query: string): Observable<CategoryResponse[]> {
		return this.http.get<CategoryResponse[]>(`shops/${shopId}/categories?search=${query}`);
	}

	post(shopId: string, dto: CategoryCreateDto): Observable<CategoryResponse> {
		return this.http.post<CategoryResponse>(`shops/${shopId}/categories`, dto);
	}

	put(shopId: string, dto: CategoryResponse): Observable<CategoryResponse[]> {
		return this.http.put<CategoryResponse[]>(`shops/${shopId}/categories/${dto.categoryId}`, dto);
	}

	delete(shopId: string, id: string): Observable<void> {
		return this.http.delete<void>(`shops/${shopId}/categories/${id}`);
	}
}
