<ng-container *transloco="let t">
	<h3>{{ t('analytics.whole') }}</h3>
	<div class="flex">
		<ng-container *ngIf="data$ | async">
			<tk-form-field>
				<tk-label>
					{{ t('analytics.total_visitors') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.total_visitors_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input tkInput disabled [value]="data$.value.uniqueClientCount?.toLocaleString()" />
			</tk-form-field>

			<tk-form-field>
				<tk-label>
					{{ t('analytics.start') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.start_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input tkInput disabled [value]="data$.value.startCommandSendCount?.toLocaleString()" />
			</tk-form-field>

			<tk-form-field>
				<tk-label>
					{{ t('analytics.visits') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.visits_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input tkInput disabled [value]="data$.value.webappOpenCount?.toLocaleString()" />
			</tk-form-field>

			<tk-form-field>
				<tk-label>
					{{ t('analytics.total_orders') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.total_orders_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input tkInput disabled [value]="data$.value.orderCount?.toLocaleString()" />
			</tk-form-field>

			<tk-form-field>
				<tk-label>
					{{ t('analytics.paid_orders') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.paid_orders_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input tkInput disabled [value]="data$.value.paidOrderCount?.toLocaleString()" />
			</tk-form-field>

			<tk-form-field>
				<tk-label>
					{{ t('analytics.total_sales') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.total_sales_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input
					tkInput
					disabled
					[value]="(data$.value.totalRevenue?.toLocaleString() ?? 0) + ' ' + shopService.shopMoneySign.value"
				/>
			</tk-form-field>

			<tk-form-field>
				<tk-label>
					{{ t('analytics.conversion') }}
					<tk-icon
						class="icon"
						icon="info"
						size="20"
						[tkTooltip]="t('analytics.conversion_hint')"
						tkTooltipPlacement="bottom"
					></tk-icon>
				</tk-label>

				<input tkInput disabled [value]="data$.value.conversion?.toLocaleString() + '%'" />
			</tk-form-field>
		</ng-container>
	</div>

	<h3>{{ t('analytics.period') }}</h3>

	<ng-container [formGroup]="form">
		<tk-form-field class="period">
			<tk-label [class.focus]="form.value['dateFrom'] || form.value['dateTo']">{{
				t('analytics.range')
			}}</tk-label>

			<tk-date-period>
				<input type="text" formControlName="dateFrom" tkDatepicker />
				<span *ngIf="form.value['dateFrom'] || form.value['dateTo']">-</span>
				<input type="text" formControlName="dateTo" tkDatepicker />
			</tk-date-period>
			<tk-icon tkSuffix icon="calendar" class="filter-period__icon"></tk-icon>
			<tk-hint>{{ t('analytics.max') }}</tk-hint>
			<tk-error *ngIf="checkMonthValidSize(6)">{{ t('analytics.max') }}</tk-error>
		</tk-form-field>
	</ng-container>

	<div class="flex" *ngIf="periodData$ | async">
		<tk-form-field>
			<tk-label>
				{{ t('analytics.period_range.new_visitors') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.new_visitors_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input tkInput disabled [value]="periodData$.value.uniqueClientCount?.toLocaleString()" />
		</tk-form-field>

		<tk-form-field>
			<tk-label>
				{{ t('analytics.start') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.start_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input tkInput disabled [value]="periodData$.value.startCommandSendCount?.toLocaleString()" />
		</tk-form-field>

		<tk-form-field>
			<tk-label>
				{{ t('analytics.visits') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.visits_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input tkInput disabled [value]="periodData$.value.webappOpenCount?.toLocaleString()" />
		</tk-form-field>

		<tk-form-field>
			<tk-label>
				{{ t('analytics.period_range.new_orders') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.new_orders_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input tkInput disabled [value]="periodData$.value.orderCount?.toLocaleString()" />
		</tk-form-field>

		<tk-form-field>
			<tk-label>
				{{ t('analytics.paid_orders') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.paid_orders_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input tkInput disabled [value]="periodData$.value.paidOrderCount?.toLocaleString()" />
		</tk-form-field>

		<tk-form-field>
			<tk-label>
				{{ t('analytics.period_range.new_sales') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.new_sales_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input
				tkInput
				disabled
				[value]="
					(periodData$.value.totalRevenue?.toLocaleString() ?? 0) + ' ' + shopService.shopMoneySign.value
				"
			/>
		</tk-form-field>

		<tk-form-field>
			<tk-label>
				{{ t('analytics.conversion') }}
				<tk-icon
					class="icon"
					icon="info"
					size="20"
					[tkTooltip]="t('analytics.period_range.conversion_hint')"
					tkTooltipPlacement="bottom"
				></tk-icon>
			</tk-label>

			<input tkInput disabled [value]="periodData$.value.conversion?.toLocaleString() + '%'" />
		</tk-form-field>
	</div>
</ng-container>

<ng-template #loader>
	<tk-loader width="FULL"></tk-loader>
</ng-template>
