import { ChangeDetectorRef, Directive, EmbeddedViewRef, Injector, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[tkAdjustiveView]',
})
export abstract class AdjustiveViewDirective {
	protected _elseTemplateRef?: TemplateRef<unknown>;
	protected _thenViewRef: EmbeddedViewRef<unknown> | null = null;
	protected replaceElse = false;
	protected showing: boolean = false;
	protected _tkAdjustiveView!: string;

	private _cdr: ChangeDetectorRef;
	private _viewContainer: ViewContainerRef;
	private _templateRef: TemplateRef<unknown>;

	protected constructor(protected injector: Injector) {
		this._cdr = injector.get(ChangeDetectorRef);
		this._viewContainer = injector.get(ViewContainerRef);
		this._templateRef = injector.get(TemplateRef);
	}

	get condition() {
		return this._tkAdjustiveView?.replace(/[a-zA-Z\d]/gm, '');
	}

	get dirtyViewValue() {
		return this._tkAdjustiveView?.replace(/[^a-zA-Z\d]/gm, '');
	}

	setTkAdjustiveView(condition: string) {
		this._tkAdjustiveView = condition;
		this.checkCondition();
	}

	setTkAdjustiveViewElse(template: TemplateRef<unknown> | undefined) {
		if (!this._elseTemplateRef || this._elseTemplateRef !== template) {
			this.replaceElse = true;
			this._elseTemplateRef = template;
			this._updateView();
		}
	}

	protected checkCondition() {
		if (!this._tkAdjustiveView) {
			return;
		}
	}

	protected _updateView() {
		if (!this.showing) {
			this._viewContainer.clear();
			this._thenViewRef = null;

			if (this._elseTemplateRef) {
				this._thenViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef);
				this.replaceElse = true;
			}

			this._cdr.markForCheck();
			return;
		}

		if (!this._thenViewRef || this.replaceElse) {
			this._viewContainer.clear();

			if (this._templateRef) {
				this._thenViewRef = this._viewContainer.createEmbeddedView(this._templateRef);
				this.replaceElse = false;
				this._cdr.markForCheck();
			}
		}
	}

	protected compareValuesWithCondition(condition: string, currentValue: any, requiredValue: any) {
		this.showing = eval(currentValue + condition + requiredValue);
	}
}
