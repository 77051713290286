/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Промокод
 */
export type PromocodeResponse = {
	/**
	 * Идентификатор промокода
	 */
	id?: string;
	/**
	 * Название промокода
	 */
	promocodeName?: string;
	/**
	 * Статус промокода
	 */
	promocodeState?: PromocodeResponse.promocodeState;
	/**
	 * Тип скидки
	 */
	discountType?: PromocodeResponse.discountType;
	/**
	 * Размер скидки
	 */
	discountValue?: number;
	/**
	 * Дата начала действия промокода
	 */
	validDateFrom?: string;
	/**
	 * Дата окончания действия промокода
	 */
	validDateTo?: string;
	/**
	 * Минимальная сумма заказа для применения промокода
	 */
	minOrderSum?: number;
	notApplicableDiscounted?: boolean;
};

export namespace PromocodeResponse {
	/**
	 * Статус промокода
	 */
	export enum promocodeState {
		ACTIVE = 'ACTIVE',
		EXPIRED = 'EXPIRED',
		DISABLED = 'DISABLED',
	}

	/**
	 * Тип скидки
	 */
	export enum discountType {
		DISCOUNT = 'DISCOUNT',
		PERCENT = 'PERCENT',
	}
}
