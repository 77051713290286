<div class="container">
	<div class="icon" [class.success]="success" *ngIf="success">
		<tk-icon icon="toast-success"></tk-icon>
	</div>
	<div class="icon" [class.error]="error" *ngIf="error">
		<tk-icon icon="toast-error"></tk-icon>
	</div>

	<p *ngIf="message" class="text">{{ message }}</p>
</div>

<button (click)="remove()" class="close">
	<tk-icon icon="close-circle" size="16"></tk-icon>
</button>
