import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CategoryEditDialogMode, CategoryEditModalData } from '@components/modals/category-edit/types';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'tg-category-edit-modal',
	templateUrl: './category-edit.component.html',
	styleUrls: ['./category-edit.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryEditComponent implements AfterViewInit {
	DIALOG_TITLE_KEY: Record<CategoryEditDialogMode, string> = {
		'new-root': 'edit_modal_title_new_root',
		'new-child': 'edit_modal_title_new_child',
		'edit-root': 'edit_modal_title_edit_root',
		'edit-child': 'edit_modal_title_edit_child',
	};

	DIALOG_SUBMIT_TEXT_KEY: Record<CategoryEditDialogMode, string> = {
		'new-root': 'edit_modal_create_btn',
		'new-child': 'edit_modal_create_btn',
		'edit-root': 'edit_modal_save_btn',
		'edit-child': 'edit_modal_save_btn',
	};

	formControl = new FormControl();

	@ViewChild('subcategoryInput', { static: false, read: ElementRef }) formControlInputRef: ElementRef;

	constructor(private dialogRef: DialogRef, @Inject(DIALOG_DATA) public dialogData: CategoryEditModalData) {
		if (dialogData.actualCategory) {
			this.formControl.setValue(dialogData.actualCategory.categoryName);
		}
	}

	ngAfterViewInit(): void {
		this.formControlInputRef?.nativeElement?.scrollIntoView?.();
	}

	submit() {
		const newCategoryName = this.formControl.value;

		if (!newCategoryName) {
			return;
		}

		this.dialogRef.close({
			...this.dialogData.actualCategory,
			categoryName: newCategoryName,
		});
	}
}
