/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Габарит
 */
export type ProductDimensionsTypeResponse = {
	/**
	 * Название стандартного габарита
	 */
	productDimensionType?: ProductDimensionsTypeResponse.productDimensionType;
	/**
	 * Ширина
	 */
	width?: number;
	/**
	 * Высота
	 */
	height?: number;
	/**
	 * Длина
	 */
	length?: number;
};

export namespace ProductDimensionsTypeResponse {
	/**
	 * Название стандартного габарита
	 */
	export enum productDimensionType {
		S = 'S',
		M = 'M',
		L = 'L',
		XL = 'XL',
	}
}
