<ng-container *transloco="let t; read: 'categories'">
	<div
		class="row-container"
		[ngClass]="{ '--expandable': category.children?.length > 0 }"
		(click)="childrenToggleAction(category)"
	>
		<div class="row-data">
			<div *ngFor="let idx of Array(depth)" class="expander-container">
				<div class="expander"></div>
			</div>
			<div class="expander-container">
				<div class="expander">
					<ng-container *ngIf="category.children?.length > 0">
						<svg
							*ngIf="category.isExpanded"
							width="12"
							height="2"
							viewBox="0 0 12 2"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H11C11.4142 0.25 11.75 0.585786 11.75 1C11.75 1.41421 11.4142 1.75 11 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1Z"
								fill="#000B26"
								fill-opacity="0.32"
							/>
						</svg>
						<svg
							*ngIf="!category.isExpanded"
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1V5.25H1C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75H5.25V11C5.25 11.4142 5.58579 11.75 6 11.75C6.41421 11.75 6.75 11.4142 6.75 11V6.75H11C11.4142 6.75 11.75 6.41421 11.75 6C11.75 5.58579 11.4142 5.25 11 5.25H6.75V1Z"
								fill="#000B26"
								fill-opacity="0.32"
							/>
						</svg>
					</ng-container>
				</div>
			</div>
			<div class="label">{{ category.categoryName }}</div>
		</div>

		<ng-container *ngIf="!category.isImported">
			<button class="actions-btn" cdkOverlayOrigin #origin (click)="toggleActionsMenu($event, true)">
				<tk-icon icon="options-vertical"></tk-icon>
			</button>
			<ng-template
				cdkConnectedOverlay
				cdkConnectedOverlayHasBackdrop
				[cdkConnectedOverlayOrigin]="origin"
				[cdkConnectedOverlayOpen]="actionsIsOpen"
				(backdropClick)="toggleActionsMenu($event, false)"
				cdkConnectedOverlayBackdropClass="backdrop--transparent"
				[cdkConnectedOverlayPositions]="actionsMenuPositions"
			>
				<div class="actions-menu">
					<button (click)="editAction([])">
						<tk-icon icon="edit"></tk-icon>
						<span>{{ t('action_edit') }}</span>
					</button>
					<button (click)="createSubCategoryAction([])">
						<tk-icon icon="plus"></tk-icon>
						<span>{{ t('action_create_child') }}</span>
					</button>
					<button class="delete" (click)="deleteAction(category)">
						<tk-icon icon="trash"></tk-icon>
						<span>{{ t('action_delete') }}</span>
					</button>
				</div>
			</ng-template>
		</ng-container>
	</div>
	<ng-container *ngIf="category.isExpanded">
		<tg-categories-tree-view-row
			*ngFor="let childCategory of category.children ?? []"
			[category]="childCategory"
			[depth]="depth + 1"
			(onEdit)="editAction($event)"
			(onCreateSubCategory)="createSubCategoryAction($event)"
			(onDelete)="deleteAction($event)"
			(onChildrenToggle)="childrenToggleAction($event)"
		>
		</tg-categories-tree-view-row>
	</ng-container>
</ng-container>
