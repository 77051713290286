import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaginatedResponse, PaginationModel } from '@models/pagination.model';
import { FormControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NewsletterService } from '@services/newsletter.service';
import { BehaviorSubject, filter, map, switchMap, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NewsletterRequest, NewsletterResponse } from '@models/generated/api';
import { ToastService } from '@services/toast.service';
import { Dialog } from '@angular/cdk/dialog';
import { NewsletterCreateComponent } from '@components/modals/newsletter-create/newsletter-create.component';

@Component({
	selector: 'tg-shop-settings-newsletter',
	templateUrl: './shop-settings-newsletter.component.html',
	styleUrls: ['./shop-settings-newsletter.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsNewsletterComponent extends PaginationModel {
	columns = ['name', 'createdAt', 'scheduledDatetime', 'status', 'actions'];

	menuIsOpen: string = '';
	interval;

	newsletterList$ = new BehaviorSubject<PaginatedResponse<NewsletterResponse[]>>({});
	shopId: string;

	search: boolean = false;
	searchControl = new FormControl();

	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'top',
			overlayX: 'center',
			overlayY: 'bottom',
			offsetY: 10,
		},
	];

	constructor(
		private newsletterService: NewsletterService,
		private route: ActivatedRoute,
		private toast: ToastService,
		private dialog: Dialog,
	) {
		super();

		this.route.parent.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
				this.interval = setInterval(() => {
					this.refresh();
				}, 3000);
			});
	}

	refresh() {
		this.newsletterService.getNewsletters(this.shopId, this.currentSettings).subscribe({
			next: (res: any) => {
				this.newsletterList$.next(res);
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	clickCreateBtn() {
		this.dialog
			.open<NewsletterRequest>(NewsletterCreateComponent)
			.closed.pipe(
				filter(v => !!v),
				switchMap(data => this.newsletterService.createNewsletter(this.shopId, data).pipe(tap(() => {}))),
			)
			.subscribe({
				next: () => {
					this.toast.success('Рассылка успешно создана!');
					this.refresh();
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
				complete: () => {
					// this.loading$.next(false);
				},
			});
	}

	onChangeSearch() {
		if (this.searchControl.value?.length < 3) {
			if (this.search) {
				this.search = false;
				this.refresh();
			}
			return;
		} else {
			this.search = true;
		}
		if (this.search) {
			this.refresh();
		}
	}

	openMenu(id: string, event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.menuIsOpen = id;
	}

	closeMenu() {
		this.menuIsOpen = '';
	}

	deleteNewsletter(id: string) {
		this.newsletterService.updateStatus(this.shopId, id).subscribe({
			next: res => {
				this.refresh();
				this.toast.error('Рассылка отменена');
			},
		});
	}

	override changePage(page: number): void {
		super.changePage(page);
		this.refresh();
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}
}
