import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { controlValues } from '@utils/control-values';
import { DateTime } from 'luxon';
import { updateControlState } from '@utils/updateControlState';

@Component({
	selector: 'tk-datetime',
	templateUrl: './datetime.component.html',
	styleUrls: ['./datetime.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeComponent implements ControlValueAccessor {
	@Input() showTime = false;
	@Input() dateLabel = 'Дата';

	dateControl = new FormControl('');
	timeControl = new FormControl('');

	private onTouchedCallback: () => void = () => {};
	private onChangeCallback: (value: any) => void = () => {};

	constructor(protected cdr: ChangeDetectorRef, @Self() @Optional() public ngControl: NgControl) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}

		combineLatest([controlValues(this.dateControl), controlValues(this.timeControl)]).subscribe(
			([date, time]: any) => {
				if (date && time) {
					this.onChangeCallback(
						DateTime.fromISO(date)
							.set({
								hour: time.split(':')[0],
								minute: time.split(':')[1],
							})
							.toISO(),
					);
				} else {
					this.onChangeCallback('');
				}
			},
		);
	}

	writeValue(value: string): void {
		if (!value) {
			this.dateControl.setValue('');
			const initialTimeValue = this.showTime ? '' : '00:00';
			this.timeControl.setValue(initialTimeValue);
			return;
		}
		const parsed = DateTime.fromISO(value);

		this.dateControl.setValue(parsed.toISODate());
		this.timeControl.setValue(parsed.toFormat('HH:mm'));
	}

	registerOnChange(fn: (value: any) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		updateControlState(this.timeControl, isDisabled);
		updateControlState(this.dateControl, isDisabled);
		this.cdr.markForCheck();
	}
}
