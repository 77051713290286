import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'tg-shop-create-beautiful-link-modal',
	templateUrl: './shop-create-beautiful-link-modal.component.html',
	styleUrls: ['./shop-create-beautiful-link-modal.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopCreateBeautifulLinkModalComponent {
	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		@Inject(DIALOG_DATA)
		public dialogData: {
			link?: string;
		},
		public dialogRef: DialogRef,
	) {
		this.buildForm();

		if (this.dialogData?.link) {
			this.form.patchValue({ link: this.dialogData.link }, { emitEvent: false });
		}
	}

	private buildForm(): void {
		const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

		this.form = this.fb.group({
			link: ['', [Validators.required, Validators.pattern(urlPattern)]],
		});
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	onSave(): void {
		if (this.form.valid) {
			this.dialogRef.close(this.form.value);
		}
	}

	hasError(controlName: string): boolean {
		const control = this.form.get(controlName);
		return control ? control.invalid && (control.dirty || control.touched) : false;
	}

	getErrorMessage(controlName: string): string {
		const control = this.form.get(controlName);
		if (control?.errors) {
			if (control.errors['required']) {
				return 'beautiful_link_modal.errors.required';
			}
			if (control.errors['pattern']) {
				return 'beautiful_link_modal.errors.invalid_url';
			}
		}
		return '';
	}
}
