/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OfferImportErrorResponse } from './OfferImportErrorResponse';

/**
 * Импортированный товар
 */
export type OfferImportResponse = {
	/**
	 * Идентификатор
	 */
	id?: string;
	/**
	 * Наименование товара
	 */
	offerName?: string;
	/**
	 * Идентификатор из фид файла
	 */
	externalId?: string;
	/**
	 * Статус импорта
	 */
	importState?: OfferImportResponse.importState;
	/**
	 * Статус импорта
	 */
	importStateDescription?: string;
	/**
	 * Идентификатор продукта
	 */
	productId?: string;
	/**
	 * Ошибки
	 */
	errors?: Array<OfferImportErrorResponse>;
};

export namespace OfferImportResponse {
	/**
	 * Статус импорта
	 */
	export enum importState {
		READ = 'READ',
		INVALID = 'INVALID',
		SAVED = 'SAVED',
		WARN = 'WARN',
		FAILED = 'FAILED',
	}
}
