<form [formGroup]="form" (submit)="submit()">
	<tk-form-field>
		<tk-label>Интегрироваться с...</tk-label>
		<tk-select formControlName="crm">
			<tk-option [value]="'retail'"> RetailCRM </tk-option>
		</tk-select>
	</tk-form-field>

	<ng-container *ngIf="form.controls['crm'].value === 'retail'">
		<tg-info-plate>
			Инструкция для подключения интеграции:<br /><br />

			1. Перед настройкой интеграции создайте новый магазин с названием "TGShop" и символьным кодом "tgshop"<br />
			2. Перейдите в настройки интеграции.<br />
			3. Добавьте новый ключ API с доступом к магазину "TGShop".<br />
			4. В разделе "Разрешенные действия в API" укажите следующие разрешения: заказы, клиенты, справочники и
			пользователи.<br />
			5. В настройках магазина проверьте заполнение полей "Юр. лицо" и "Страна" (в случае незаполнения, возникнут
			ошибки при передачи ставки НДС)<br />
			6. Скопируйте API ключ в поле ниже, сохраните
		</tg-info-plate>

		<tk-form-field>
			<tk-label> Домен </tk-label>
			<input type="text" tkInput tkInputTrim formControlName="host" />
			<tk-error *ngIf="errors(form, 'host') && form.controls['host'].hasError('retailUrl')"
				>Некорректный домен</tk-error
			>
			<tk-icon
				class="tooltip"
				icon="info"
				size="20"
				tkTooltip="https://your-subdomain.retailcrm.ru"
				tkTooltipPlacement="bottom"
			></tk-icon>
		</tk-form-field>

		<tk-form-field>
			<tk-label>API ключ</tk-label>
			<input type="text" tkInput tkInputTrim formControlName="apiKey" />
		</tk-form-field>

		<div *ngIf="(data | async) && (data.value.profileFirstname || data.value.profileLastname)" class="retail__user">
			{{ data.value.profileFirstname }} {{ data.value.profileLastname }}
			<tk-icon icon="success"></tk-icon>
		</div>

		<ng-container *ngIf="currentValue">
			<tk-checkbox
				[ngModel]="isActive"
				[ngModelOptions]="{ standalone: true }"
				(ngModelChange)="setRetailActive($event)"
			>
				Активность интеграции
			</tk-checkbox>
		</ng-container>
	</ng-container>

	<button tkButton class="submit" [disabled]="isDisabled">Сохранить</button>
</form>
