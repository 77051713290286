import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryResponse } from '@models/generated/api';

@Component({
	selector: 'tg-categories-tree-view',
	templateUrl: './categories-tree-view.component.html',
	styleUrls: ['./categories-tree-view.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesTreeViewComponent {
	@Input() categories: CategoryResponse[];

	@Output() onEdit = new EventEmitter<CategoryResponse[]>();

	@Output() onCreateSubCategory = new EventEmitter<CategoryResponse[]>();

	@Output() onDelete = new EventEmitter<CategoryResponse>();

	@Output() onChildrenToggle = new EventEmitter<CategoryResponse>();
}
