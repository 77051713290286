import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EmailValidators, errors } from '@utils/validations';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenCoupleResponse } from '@models/generated/api';
import { ToastService } from '@services/toast.service';
import { LoadingService } from '@services/loading.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'tk-sign-in-form',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.sass', '../common.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LoadingService],
})
export class SignInFormComponent {
	@ViewChild('password') public passwordRef?: ElementRef;

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private router: Router,
		private toast: ToastService,
		public loading$: LoadingService,
		private route: ActivatedRoute,
	) {
		if (this.authService.token) {
			this.router.navigate(['/']);
		}

		const confirmCode = this.route.snapshot.queryParams['confirmCode'];
		if (confirmCode) {
			this.authService.confirm(confirmCode).subscribe({
				next: data => {
					this.authService.authorize(data);
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'sign_in');
					this.toast.success('Поздравляем! Вы успешно зарегистрированы!');
					this.router.navigate(['/']);
				},
				error: () => {},
			});
		}
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	form: UntypedFormGroup = new UntypedFormGroup({
		'email': new UntypedFormControl(null, [Validators.required, ...EmailValidators]),
		'password': new UntypedFormControl(null, Validators.required),
	});

	submitForm = () => {
		this.loading$.next(true);
		this.authService.signIn(this.form.value).subscribe({
			next: (data: TokenCoupleResponse) => {
				this.authService.authorize(data);
				this.loading$.next(false);
				// @ts-ignore
				ym(environment.metricId, 'reachGoal', 'sign_in');

				const backRoute = this.route.snapshot.queryParams['back'];
				if (backRoute) {
					this.router.navigate(['/', backRoute]);
				} else {
					this.router.navigate(['/']);
				}
			},
			error: err => {
				switch (err.status) {
					case 401:
						this.toast.error('Неверный логин или пароль');
						break;
					case 404:
						this.toast.error('Что-то пошло не так');
						break;
					default:
						this.toast.error(err.error?.message);
				}
				this.loading$.next(false);
			},
		});
	};

	get isDisabled(): boolean {
		return this.form.invalid;
	}
}
