import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject, map, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@services/toast.service';

import { EditorLocale } from 'angular-markdown-editor';
import { ShopService } from '@services/shop.service';
import { MarkdownService } from 'ngx-markdown';
import { ImageService } from '@services/image.service';
import { errors, maxFilesize, validateImage } from '@utils/validations';
import { HelloService } from '@services/hello.service';
import { ShopOrderFieldRequest } from '@models/generated/api';

@Component({
	selector: 'tg-shop-settings-hello',
	templateUrl: './shop-settings-order-fields.component.html',
	styleUrls: ['./shop-settings-order-fields.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsOrderFieldsComponent implements OnInit {
	shopId: string;
	data = new BehaviorSubject<any>(null);

	fields: any = {};

	constructor(
		private fb: FormBuilder,
		public shopService: ShopService,
		private helloService: HelloService,
		private cdr: ChangeDetectorRef,
		private markdownService: MarkdownService,
		private toast: ToastService,
		private imageService: ImageService,
		private route: ActivatedRoute,
	) {
		this.route.parent.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {});
	}

	refresh() {
		this.shopService.getOrderFields(this.shopId).subscribe({
			next: res => {
				console.log(res);
				this.data.next(res);
				this.cdr.markForCheck();
			},
		});
	}

	ngOnInit() {
		this.refresh();
	}

	enableField(name: string) {
		if (name === ShopOrderFieldRequest.shopOrderFieldType.LAST_NAME) {
			this.shopService
				.editOrderField(
					this.shopId,
					this.data.value.find(i => i.name === 'Фамилия').id,
					ShopOrderFieldRequest.shopOrderFieldType.LAST_NAME,
					!this.isFieldEnabled('Фамилия'),
					this.isFieldRequired('Фамилия'),
				)
				.subscribe({
					next: res => {
						this.toast.success('Поле Фамилия успешно обновлено!');
						this.refresh();
					},
					error: err => {
						this.toast.error(err.error?.message);
					},
				});
		}
		if (name === ShopOrderFieldRequest.shopOrderFieldType.PATRONYMIC) {
			this.shopService
				.editOrderField(
					this.shopId,
					this.data.value.find(i => i.name === 'Отчество').id,
					ShopOrderFieldRequest.shopOrderFieldType.PATRONYMIC,
					!this.isFieldEnabled('Отчество'),
					this.isFieldRequired('Отчество'),
				)
				.subscribe({
					next: res => {
						this.toast.success('Поле Отчество успешно обновлено!');
						this.refresh();
					},
					error: err => {
						this.toast.error(err.error?.message);
					},
				});
		}
		if (name === ShopOrderFieldRequest.shopOrderFieldType.PHONE) {
			this.shopService
				.editOrderField(
					this.shopId,
					this.data.value.find(i => i.name === 'Телефон').id,
					ShopOrderFieldRequest.shopOrderFieldType.PHONE,
					!this.isFieldEnabled('Телефон'),
					this.isFieldRequired('Телефон'),
				)
				.subscribe({
					next: res => {
						this.toast.success('Поле Телефон успешно обновлено!');
						this.refresh();
					},
					error: err => {
						this.toast.error(err.error?.message);
					},
				});
		}
		if (name === ShopOrderFieldRequest.shopOrderFieldType.EMAIL) {
			this.shopService
				.editOrderField(
					this.shopId,
					this.data.value.find(i => i.name === 'Email').id,
					ShopOrderFieldRequest.shopOrderFieldType.EMAIL,
					!this.isFieldEnabled('Email'),
					this.isFieldRequired('Email'),
				)
				.subscribe({
					next: res => {
						this.toast.success('Поле Email успешно обновлено!');
						this.refresh();
					},
					error: err => {
						this.toast.error(err.error?.message);
					},
				});
		}
		if (name === ShopOrderFieldRequest.shopOrderFieldType.PROMOCODE) {
			this.shopService
				.editOrderField(
					this.shopId,
					this.data.value.find(i => i.name === 'Промокод').id,
					ShopOrderFieldRequest.shopOrderFieldType.PROMOCODE,
					!this.isFieldEnabled('Промокод'),
					this.isFieldRequired('Промокод'),
				)
				.subscribe({
					next: res => {
						this.toast.success('Поле Промокод успешно обновлено!');
						this.refresh();
					},
					error: err => {
						this.toast.error(err.error?.message);
					},
				});
		}
		if (name === ShopOrderFieldRequest.shopOrderFieldType.COMMENT) {
			this.shopService
				.editOrderField(
					this.shopId,
					this.data.value.find(i => i.name === 'Комментарии к заказу').id,
					ShopOrderFieldRequest.shopOrderFieldType.COMMENT,
					!this.isFieldEnabled('Комментарии к заказу'),
					this.isFieldRequired('Комментарии к заказу'),
				)
				.subscribe({
					next: res => {
						this.toast.success('Поле Комментарии к заказу успешно обновлено!');
						this.refresh();
					},
					error: err => {
						this.toast.error(err.error?.message);
					},
				});
		}
	}

	isFieldExist(name: string) {
		if (this.data.getValue() === null) {
			return false;
		}
		return this.data.value?.find(i => i.name === name);
	}

	isFieldEnabled(name: string) {
		if (this.data.getValue() === null) {
			return false;
		}
		return this.isFieldExist(name)?.enabled;
	}

	isFieldRequired(name: string) {
		if (this.data.getValue() === null) {
			return false;
		}
		return this.isFieldExist(name)?.required;
	}
}
