<ng-container *transloco="let t">
	<tg-dialog-header>{{ t('create.color_add') }}</tg-dialog-header>

	<tg-dialog-body>
		<div class="list">
			<ng-container *ngFor="let color of colorsList$ | async">
				<div
					class="list__item"
					*ngIf="!(color.id === 'DEFAULT')"
					(click)="selectColor(color, isActiveColor(color))"
					[class.active]="isActiveColor(color)"
				>
					<tk-checkbox [ngModel]="isActiveColor(color)" disabled></tk-checkbox>
					<span [style.background-color]="color.rgbCode"></span>
					{{ color.colorName }}
				</div>
			</ng-container>
		</div>
		<tk-button-group>
			<button tkButton theme="secondary" (click)="close()">{{ t('global.cancel') }}</button>
			<button tkButton theme="primary" (click)="submit()">{{ t('create.done') }}</button>
		</tk-button-group>
	</tg-dialog-body>
</ng-container>
