import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '@services/shop.service';

@Component({
	selector: 'tg-shop-settings',
	templateUrl: './shop-settings.component.html',
	styleUrls: ['./shop-settings.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsComponent {
	shopId: string;

	constructor(public route: ActivatedRoute, public shopService: ShopService) {
		this.shopId = this.route.snapshot.paramMap.get('id');

		if (!this.shopService.shopInfo.value) {
			this.shopService.getShop(this.shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
					this.shopService.shopInfo.next(res);
				},
			});
		}
	}
}
