import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { TokenCoupleResponse } from '@models/generated/api';
import { environment } from '@environments/environment';
import { StartsWithHttp } from '@services/url-interceptor.service';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	refreshToken$ = new BehaviorSubject<string | null>(null);
	private isRefreshing = false;

	constructor(private auth: AuthService, private router: Router, private translocoService: TranslocoService) {}
	lang;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (
			request.url === environment.apiUrl + '/admin/token/refresh' ||
			request.url === environment.apiUrl + '/admin/sign-in'
		) {
			return next.handle(request);
		}

		let authRequest = request;
		const token = this.auth.token;

		if (token) {
			authRequest = this.addToken(request, token);
		}

		return next.handle(authRequest).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					return this.handle401Error(authRequest, next);
				}

				return throwError(error);
			}),
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshToken$.next(null);

			const token = this.auth.refreshToken;
			if (token)
				return this.auth
					.refresh({
						accessToken: this.auth.token,
						refreshToken: token,
					})
					.pipe(
						switchMap((tokens: TokenCoupleResponse) => {
							this.isRefreshing = false;
							this.auth.authorize(tokens);
							this.refreshToken$.next(tokens.accessToken);

							return next.handle(this.addToken(request, tokens.accessToken));
						}),
						catchError(err => {
							this.isRefreshing = false;

							this.auth.logout();
							this.router.navigate(['/sign-in']);
							return throwError(err);
						}),
					);
		}
		return this.refreshToken$.pipe(
			filter(token => token !== null),
			take(1),
			switchMap(token => next.handle(this.addToken(request, token))),
		);
	}

	addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
		const headers =
			token != '' && !request.context.get(StartsWithHttp)
				? request.headers
						.set('Authorization', token)
						.set('Accept-Language', this.translocoService.getActiveLang())
				: request.headers.set('Accept-Language', this.translocoService.getActiveLang());
		return request.clone({
			headers,
		});
	}
}
