import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	NewsletterRequest,
	NewsletterResponse,
	NewsletterStatus,
	NewsletterUserGroupResponse,
	UpdateNewsletterStatusRequest,
} from '@models/generated/api';
import { WithPagingParams } from '@models/paging.interface';

@Injectable({
	providedIn: 'root',
})
export class NewsletterService {
	constructor(private http: HttpClient) {}

	getNewsletters(shopId: string, params: WithPagingParams): Observable<NewsletterResponse[]> {
		// @ts-ignore
		return this.http.get<NewsletterResponse[]>(`admin/${shopId}/newsletters`, { params });
	}

	getNewsletterGroups(shopId: string): Observable<NewsletterUserGroupResponse[]> {
		return this.http.get<NewsletterUserGroupResponse[]>(`admin/${shopId}/newsletters/groups`);
	}

	createNewsletter(shopId: string, body: NewsletterRequest): Observable<NewsletterResponse> {
		return this.http.post<NewsletterResponse>(`admin/${shopId}/newsletters`, body);
	}

	// delete
	updateStatus(shopId: string, newsletterId: string): Observable<UpdateNewsletterStatusRequest> {
		return this.http.patch<UpdateNewsletterStatusRequest>(`admin/${shopId}/newsletters/${newsletterId}`, {
			status: NewsletterStatus.CANCELLED,
		});
	}
}
