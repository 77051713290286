<ng-container *transloco="let t">
	<div class="actions">
		<tk-form-field>
			<tk-label>{{ t('global.search') }}</tk-label>

			<input type="text" tkInput [formControl]="searchControl" />

			<tk-icon tkSuffix icon="search"></tk-icon>
		</tk-form-field>

		<tk-button tkButton size="xl" class="get-history__btn" theme="secondary" (click)="getHistory()">
			<tk-icon icon="download"></tk-icon>
			Экспортировать Excel
		</tk-button>
	</div>

	<div class="table__wrap">
		<cdk-table
			class="table"
			[dataSource]="(orderList$ | async).content | search : 'orderNumber' : searchControl.value"
		>
			<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

			<ng-container cdkColumnDef="isViewed">
				<cdk-header-cell *cdkHeaderCellDef> </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					<div class="view-status" [class.active]="!row.isViewed"></div>
				</cdk-cell>
			</ng-container>

			<ng-container cdkColumnDef="orderNumber">
				<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.number') }} </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.orderNumber }}
				</cdk-cell>
			</ng-container>

			<ng-container cdkColumnDef="orderDate">
				<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.date') }} </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.orderDate + 'Z' | date : 'dd.MM.yyyy HH:mm' }}
				</cdk-cell>
			</ng-container>
			<ng-container cdkColumnDef="cost">
				<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.amount') }} </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.cost?.toLocaleString() }} {{ shopService.shopMoneySign.value }}
				</cdk-cell>
			</ng-container>
			<ng-container cdkColumnDef="receiverName">
				<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.customer') }} </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.receiverName ?? '-' }}
				</cdk-cell>
			</ng-container>
			<ng-container cdkColumnDef="paymentState">
				<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.payment') }} </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.paymentStateDescription }}
				</cdk-cell>
			</ng-container>

			<ng-container
				*ngIf="this.shopService.shopInfo.value?.productType === 'GOODS'"
				cdkColumnDef="orderProcessingState"
			>
				<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.delivery') }} </cdk-header-cell>

				<cdk-cell *cdkCellDef="let row">
					{{ row.deliveryTypeDescription }}
				</cdk-cell>
			</ng-container>

			<cdk-row *cdkRowDef="let row; columns: columns" class="cdk-row-hover" (click)="openOrder(row)"></cdk-row>
		</cdk-table>

		<ng-container *ngIf="loading$ | async">
			<ng-container *ngTemplateOutlet="loader"></ng-container>
		</ng-container>
	</div>

	<tk-paging-controls
		[pages]="pages(orderList$ | async)"
		[page]="currentSettings.page ?? 0"
		(amountChange)="setAmount($event)"
		(pageChange)="changePage($event)"
	></tk-paging-controls>

	<ng-template #loader>
		<tk-loader width="FULL"></tk-loader>
	</ng-template>
</ng-container>
