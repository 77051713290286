/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Группы пользователей, которым будет отправлена рассылка
 */
export enum NewsletterUserGroup {
	ALL = 'ALL',
	ORDER_CREATED = 'ORDER_CREATED',
}
