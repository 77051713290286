<ng-container *transloco="let t">
	<tg-dialog-header>
		<div class="wrap">
			{{ data.fileName }}
			<span
				class="status"
				[class.status--alert]="data.jobState === jobStateEnum.FINISHED_WARN"
				[class.status--error]="
					data.jobState === jobStateEnum.FINISHED_ALL_ERROR ||
					data.jobState === jobStateEnum.FAILED ||
					data.jobState === jobStateEnum.FINISHED_ERROR
				"
				[class.status--success]="data.jobState === jobStateEnum.FINISHED_SUCCESS"
				>{{ data.jobStateDescription }}</span
			>
		</div>
	</tg-dialog-header>

	<tg-dialog-body>
		<div class="err" *ngIf="categoryErrors.length > 0">
			<div class="err__item" *ngFor="let cat of categoryErrors">
				Категория {{ cat.categoryName }}:
				<span
					class="detail"
					[class.detail--alert]="cat.importState === offerStateEnum.WARN"
					[class.detail--error]="
						cat.importState === offerStateEnum.FAILED || cat.importState === offerStateEnum.INVALID
					"
					>{{ cat.errorMessage }}</span
				>
			</div>
		</div>
		<div class="err" *ngIf="productErrors.length > 0">
			<div class="err__item" *ngFor="let pos of productErrors">
				{{ pos.offerName }}:
				<span
					*ngFor="let err of pos.errors"
					class="detail"
					[class.detail--alert]="pos.importState === offerStateEnum.WARN"
					[class.detail--error]="
						pos.importState === offerStateEnum.FAILED || pos.importState === offerStateEnum.INVALID
					"
					>{{ err.message }}.
				</span>
			</div>
		</div>
	</tg-dialog-body>
</ng-container>
