import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	PlanResponse,
	SubscriptionConfirmedRequest,
	SubscriptionConfirmedResponse,
	SubscriptionPaymentHistoryResponse,
	SubscriptionResponse,
} from '@models/generated/api';
import { WithPagingParams } from '@models/paging.interface';
import { PaginatedResponse } from '@models/pagination.model';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {
	constructor(private http: HttpClient) {}

	getSubscriptionPlans(promocode?: string) {
		const params: any = {};

		if (promocode) {
			params.promocode = promocode;
		}
		return this.http.get<PlanResponse[]>('plans', { params: { ...params } });
	}

	getPaymentsHistory(params: WithPagingParams): Observable<PaginatedResponse<SubscriptionPaymentHistoryResponse[]>> {
		return this.http.get<PaginatedResponse<SubscriptionPaymentHistoryResponse[]>>('payments', {
			params: { ...params },
		});
	}

	getActiveSubscriptions(): Observable<SubscriptionResponse[]> {
		return this.http.get<SubscriptionResponse[]>('subscriptions');
	}

	createSubscription(data): Observable<SubscriptionConfirmedResponse> {
		return this.http.post<SubscriptionConfirmedResponse>(`subscriptions`, {
			...data,
		});
	}

	updateSubscription(data): Observable<SubscriptionConfirmedRequest> {
		return this.http.put<SubscriptionConfirmedRequest>(`subscriptions`, {
			...data,
		});
	}

	isSubscriptionPromocodeAvailable(): Observable<boolean> {
		return this.http.get<boolean>('subscriptions/is-available-promocodes');
	}

	cancelSubscription(subscriptionId: string) {
		return this.http.post<any>(`subscriptions/${subscriptionId}/cancel`, null);
	}
}
