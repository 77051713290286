<ng-container *transloco="let t">
	<div class="content">
		<ng-content></ng-content>
	</div>

	<div class="footer">
		<span>©TGShop, {{ currentYear }}</span>

		<div class="rights">
			<a (click)="openChat()" target="_blank" class="chat">
				<tk-icon icon="message"></tk-icon>
			</a>
			<div>
				<a href="/assets/_TGShop_Пользовательское_соглашение_на_сайт_27_07_23.docx" target="_blank">{{
					t('auth.service_terms')
				}}</a>
				<a href="/assets/TGShop_Политика_конфиденциальности_27_07_23.docx" target="_blank">{{
					t('auth.privacy')
				}}</a>
			</div>
		</div>
	</div>
</ng-container>
