import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
	selector: 'tg-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent extends Toast {
	@HostBinding('class.success') success = this.isType('toast-success');
	@HostBinding('class.error') error = this.isType('toast-error');

	isType(type: string): boolean {
		return this.toastPackage.toastType === type;
	}

	constructor(protected override toastrService: ToastrService, public override toastPackage: ToastPackage) {
		super(toastrService, toastPackage);
	}
}
