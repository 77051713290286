/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Сортировка
 */
export type OrderingResponse = {
	/**
	 * Название сортировки
	 */
	name?: string;
	/**
	 * Параметр сортировки
	 */
	param?: string;
	/**
	 * Доступные направления
	 */
	dir?: OrderingResponse.dir;
};

export namespace OrderingResponse {
	/**
	 * Доступные направления
	 */
	export enum dir {
		ASC = 'ASC',
		DESC = 'DESC',
	}
}
