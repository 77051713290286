import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImportService } from '@services/import.service';
import { BehaviorSubject, finalize } from 'rxjs';
import { ProductImportShortResponse } from '@models/generated/api';
import { Dialog } from '@angular/cdk/dialog';
import { ImportDetailComponent } from '@components/modals/import-detail/import-detail.component';
import { FormControl } from '@angular/forms';
import { ToastService } from '@services/toast.service';
import { environment } from '@environments/environment';
import { PaginatedResponse, PaginationModel } from '@models/pagination.model';

@Component({
	selector: 'tg-import',
	templateUrl: './import.component.html',
	styleUrls: ['./import.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportComponent extends PaginationModel {
	shopId = this.route.parent.snapshot.paramMap.get('id');
	import$: any = new BehaviorSubject<PaginatedResponse<ProductImportShortResponse[]>>({});
	jobStateEnum = ProductImportShortResponse.jobState;
	searchControl = new FormControl();
	loading = new BehaviorSubject<boolean>(false);
	interval;

	constructor(
		private route: ActivatedRoute,
		private productImport: ImportService,
		private dialog: Dialog,
		private toast: ToastService,
		private cdr: ChangeDetectorRef,
	) {
		super();
		this.refresh();
		this.interval = setInterval(() => {
			this.refresh();
		}, 3000);
	}

	refresh(): void {
		this.productImport.getImports(this.shopId, this.currentSettings).subscribe({
			next: data => {
				this.import$.next(data);
			},
		});
	}

	openDetail(importId): void {
		this.productImport.getImportDetail(this.shopId, importId).subscribe({
			next: res => {
				this.dialog.open(ImportDetailComponent, {
					data: res,
				});
			},
		});
	}

	uploadFile({ target }: any) {
		if (target.files.length === 0) {
			return;
		}
		this.loading.next(true);
		this.productImport
			.uploadImportFile(this.shopId, target.files[0])
			.pipe(
				finalize(() => {
					target.value = '';
					this.loading.next(false);
					this.cdr.markForCheck();
				}),
			)
			.subscribe({
				next: () => {
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'feed_uploaded');
					this.refresh();
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
			});
	}

	override changePage(page: number): void {
		super.changePage(page);
		this.refresh();
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}
}
