<ng-container *transloco="let t; read: 'auth'">
	<form class="form" [formGroup]="form" (submit)="submitForm()">
		<h2 class="form__title">{{ t('create_pass') }}</h2>

		<tk-form-field>
			<tk-label>{{ t('pass') }}</tk-label>

			<input
				#password
				type="password"
				formControlName="password"
				tkInput
				tkInputTrim
				autocomplete="off"
				aria-labelledby="password"
			/>
			<tk-eye [input]="password" tkSuffix data-test-id="btn-password-eye"></tk-eye>
			<tk-error *ngIf="errors(form, 'password') as errorField" tkPasswordError [errors]="errorField"></tk-error>
		</tk-form-field>
		<div class="hint">{{ t('pass_text') }}</div>

		<tk-form-field>
			<tk-label>{{ t('pass_repeat') }}</tk-label>

			<input
				#passwordRepeat
				type="password"
				formControlName="passwordRepeat"
				tkInput
				tkInputTrim
				autocomplete="off"
				aria-labelledby="passwordRepeat"
			/>
			<tk-eye [input]="passwordRepeat" tkSuffix data-test-id="btn-password-eye"></tk-eye>

			<tk-error
				*ngIf="errors(form, 'passwordRepeat') as errorField"
				tkPasswordError
				[errors]="errorField"
			></tk-error>
		</tk-form-field>

		<tk-button-group class="tk-button-group">
			<button tkButton size="xl" theme="primary" type="submit" [disabled]="isDisabled">{{ t('next') }}</button>
		</tk-button-group>

		<div class="sign-in">
			{{ t('have_an_acc') }} <a routerLink="/sign-in">{{ t('have_sign_in') }}</a>
		</div>
	</form>
</ng-container>
