import { CategoryResponse } from '@models/generated/api';

export const modifyCategory = (
	id: string,
	action: (category: CategoryResponse) => Partial<CategoryResponse>,
	categories: CategoryResponse[],
) => {
	for (let idx = 0; idx < categories.length; idx++) {
		if (categories[idx]?.categoryId === id) {
			categories[idx] = {
				...categories[idx],
				...action(categories[idx]),
			};

			return [...categories];
		}

		const newChildrenRefs = modifyCategory(id, action, categories[idx].children ?? []);

		if (newChildrenRefs) {
			categories[idx] = {
				...categories[idx],
				children: newChildrenRefs,
			};

			return [...categories];
		}
	}

	return null;
};

export const deleteCategory = (id: string, categories: CategoryResponse[]) => {
	for (let idx = 0; idx < categories.length; idx++) {
		if (categories[idx]?.categoryId === id) {
			categories.splice(idx, 1);
			return [...categories];
		}

		const newChildrenRefs = deleteCategory(id, categories[idx].children ?? []);

		if (newChildrenRefs) {
			categories[idx] = {
				...categories[idx],
				children: newChildrenRefs,
			};

			return [...categories];
		}
	}

	return null;
};
