/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Группа рассылок
 */
export type NewsletterUserGroupResponse = {
	/**
	 * Название группы
	 */
	userGroup?: NewsletterUserGroupResponse.userGroup;
	/**
	 * Описание группы
	 */
	description?: string;
};

export namespace NewsletterUserGroupResponse {
	/**
	 * Название группы
	 */
	export enum userGroup {
		ALL = 'ALL',
		ORDER_CREATED = 'ORDER_CREATED',
	}
}
