<ng-container *transloco="let t; read: 'categories'">
	<tg-dialog-header>
		{{ t('edit_modal_title_delete') }}
	</tg-dialog-header>
	<tg-dialog-body>
		<div class="description">
			{{ t('edit_modal_delete_description') }}
		</div>
	</tg-dialog-body>
	<tg-dialog-footer>
		<tk-button-group>
			<button tkButton theme="secondary" size="xl" tk-dialog-close>
				{{ t('edit_modal_cancel_btn') }}
			</button>
			<button tkButton size="xl" [tk-dialog-close]="true">
				{{ t('edit_modal_delete_btn') }}
			</button>
		</tk-button-group>
	</tg-dialog-footer>
</ng-container>
