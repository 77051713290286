export const breakpoints: Record<string, Record<string, number>> = {
	'xs': {
		from: 0,
		to: 319,
	},
	'sm': {
		from: 320,
		to: 424,
	},
	'md': {
		from: 425,
		to: 767,
	},
	'xsm': {
		from: 320,
		to: 767,
	},
	'xmd': {
		from: 768,
		to: 1023,
	},
	'lg': {
		from: 1024,
		to: 1365,
	},
	'xls': {
		to: 1220,
	},
	'xl': {
		from: 1366,
	},
};
