import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ShopService } from '@services/shop.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { errors, noWhitespaceValidator } from '@app/utils/validations';
import { ToastService } from '@services/toast.service';
import { AddressSuggestionResponse, PickUpPointResponse } from '@models/generated/api';
import { DadataService } from '@services/dadata.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-shop-pickup-point',
	templateUrl: './shop-pickup-point.component.html',
	styleUrls: ['./shop-pickup-point.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopPickupPointComponent {
	form = new FormGroup({
		pickUpPointName: new FormControl('', [Validators.required, noWhitespaceValidator]),
		address: new FormControl('', [Validators.required, noWhitespaceValidator]),
	});
	loading = false;

	fiasList: AddressSuggestionResponse[] = [];
	fiasSelected: AddressSuggestionResponse | null = null;

	constructor(
		private shopService: ShopService,
		private dialogRef: DialogRef,
		private dadataService: DadataService,
		@Inject(DIALOG_DATA) public data: { shopId: string; point?: PickUpPointResponse },
		private toast: ToastService,
		private cdr: ChangeDetectorRef,
	) {
		if (this.data.point) {
			const address = this.data.point.address;
			this.form.patchValue({
				...this.data.point,
				address: this.data.point.address.addressShort,
			});
			this.fiasSelected = address;
		}
	}

	submit() {
		if (!this.fiasSelected) {
			this.form.controls['address'].setErrors({ 'fias_id': true });
			return;
		}
		if (!this.fiasSelected.isHouse) {
			this.form.controls['address'].setErrors({ 'fias_house': true });
			return;
		}
		this.loading = true;
		this.form.markAllAsTouched();

		if (this.form.invalid) {
			return;
		}
		(this.data.point
			? this.shopService.editShopPickUpPoint(
					this.data.shopId,
					this.data.point.id,
					this.form.value as any,
					this.fiasSelected,
			  )
			: this.shopService.addShopPickUpPoint(this.data.shopId, this.form.value as any, this.fiasSelected)
		).subscribe(
			() => {
				this.dialogRef.close(true);
				if (!this.data.point) {
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'pickup_added');
				}
				this.toast.success(`Пункт выдачи ${this.data.point ? 'сохранен' : 'добавлен'}`);
			},
			err => {
				this.toast.error(err.error?.message);
				this.loading = false;
				this.cdr.markForCheck();
			},
		);
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	getDadata(s: string) {
		this.dadataService.getDadata(s).subscribe({
			next: res => {
				this.fiasList = res;
				this.cdr.markForCheck();
			},
		});
	}

	selectEvent(item) {
		this.fiasSelected = item;
		this.form.controls['address'].setErrors({ 'fias': null });
		this.form.controls['address'].updateValueAndValidity();
		this.cdr.markForCheck();
	}

	onChangeSearch() {
		if (this.form.controls['address'].value.length < 3) {
			this.fiasList = [];
			return;
		}
		this.fiasSelected = null;
		this.form.controls['address'].setErrors({ 'fias': null });
		this.form.controls['address'].updateValueAndValidity();
		this.cdr.markForCheck();
		this.getDadata(this.form.value.address);
	}
}
