<tg-dialog-header> Новый промокод </tg-dialog-header>

<tg-dialog-body>
	<form [formGroup]="form" (ngSubmit)="submit()">
		<tk-form-field>
			<tk-label>Название промокода (значение)</tk-label>

			<input
				type="text"
				tkInput
				formControlName="promocodeName"
				oninput="this.value = this.value.toUpperCase()"
				#promoField
			/>
			<tk-error
				*ngIf="errors(form, 'promocodeName') as errorField"
				tkCommonError
				[errors]="errorField"
			></tk-error>

			<tk-error *ngIf="isPromoInvalid">Название промокода содержит недопустимые символы</tk-error>
		</tk-form-field>

		<h4>Скидка:</h4>
		<tk-radio-group direction="horizontal" formControlName="discountType">
			<tk-radio-button value="DISCOUNT"> В рублях (₽) </tk-radio-button>
			<tk-radio-button value="PERCENT"> В процентах (%) </tk-radio-button>
		</tk-radio-group>

		<tk-form-field>
			<tk-label>Скидка</tk-label>

			<input type="number" tkInput formControlName="discountValue" />
			<tk-error
				*ngIf="errors(form, 'discountValue') as errorField"
				tkCommonError
				[errors]="errorField"
			></tk-error>
		</tk-form-field>

		<tk-checkbox formControlName="notApplicableDiscounted" style="margin-bottom: 32px">
			<span class="item__checkbox-text">Не применять к товарам со скидкой</span>
		</tk-checkbox>

		<tk-form-field>
			<tk-label>При покупке от</tk-label>

			<input type="number" tkInput formControlName="minOrderSum" />
		</tk-form-field>

		<h4>Период действия:</h4>
		<tk-form-field>
			<tk-label>Дата начала</tk-label>
			<input
				#fromOrigin
				formControlName="validDateFrom"
				[tkDatepicker]="startDatePicker"
				(ngModelChange)="(checkDateValid)"
			/>
			<tk-icon icon="calendar-24" (click)="startDatePicker.open(fromOrigin)" tkSuffix></tk-icon>
			<tk-datepicker #startDatePicker></tk-datepicker>

			<tk-error *ngIf="errors(form, 'validDateFrom') as errorField" tkCommonError [errors]="errorField">
				Дата отправки указана неверно.
			</tk-error>
		</tk-form-field>

		<tk-form-field>
			<tk-label>Дата конца</tk-label>
			<input
				#toOrigin
				formControlName="validDateTo"
				[tkDatepicker]="endDatePicker"
				(ngModelChange)="(checkDateValid)"
			/>
			<tk-icon icon="calendar-24" (click)="endDatePicker.open(toOrigin)" tkSuffix></tk-icon>
			<tk-datepicker #endDatePicker></tk-datepicker>

			<tk-error *ngIf="errors(form, 'validDateTo') as errorField" tkCommonError [errors]="errorField">
				Дата отправки указана неверно.
			</tk-error>

			<tk-error *ngIf="!checkDateValid()">Дата конца не может быть раньше даты начала</tk-error>
		</tk-form-field>

		<tk-button-group>
			<button tkButton size="xl" type="submit" [disabled]="!checkDateValid() || !form.valid">
				Создать промокод
			</button>
		</tk-button-group>
	</form>
</tg-dialog-body>
