import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { CategoryResponse } from '@models/generated/api';
import { CategoriesAPIService } from './api';
import { deleteCategory, modifyCategory } from '@services/categories/utils';
import { CategoryCreateDto } from '@services/categories/types';
import { TranslocoService } from '@ngneat/transloco';
import { DestroyService } from '@services/destroy.service';

@Injectable()
export class CategoriesService {
	private categoriesSubject = new BehaviorSubject<CategoryResponse[]>([]);
	private loadedSubject = new BehaviorSubject(false);

	private defaultErrorHandler = err => {
		this.toast.error(err.error?.message);
	};

	categories$: Observable<CategoryResponse[]>;
	loaded$: Observable<boolean>;

	constructor(
		private destroy$: DestroyService,
		private api: CategoriesAPIService,
		private toast: ToastService,
		private transloco: TranslocoService,
	) {
		this.categories$ = this.categoriesSubject.asObservable();
		this.loaded$ = this.loadedSubject.asObservable();
	}

	load(shopId: string, query: string = '') {
		this.api.get(shopId, query).subscribe({
			next: value => {
				this.categoriesSubject.next(value);
				this.loadedSubject.next(true);
			},
			error: this.defaultErrorHandler,
		});
	}

	toggleChildren(id: string) {
		this.categoriesSubject.next(
			modifyCategory(id, category => ({ isExpanded: !category.isExpanded }), this.categoriesSubject.getValue()),
		);
	}

	add(shopId: string, newCategory: CategoryResponse, parentId?: string) {
		const dto: CategoryCreateDto = { categoryName: newCategory.categoryName };
		if (parentId) {
			dto.parentId = parentId;
		}

		this.api.post(shopId, dto).subscribe({
			next: createdCategory => {
				if (parentId) {
					this.categoriesSubject.next(
						modifyCategory(
							parentId,
							category => ({
								...category,
								isExpanded: true,
								children: [...(category.children ?? []), createdCategory],
							}),
							this.categoriesSubject.getValue(),
						),
					);

					this.toast.success(this.transloco.translate('categories.toast_child_created'));

					return;
				}

				const newCategories = [...this.categoriesSubject.getValue(), createdCategory];
				this.categoriesSubject.next(newCategories);

				this.toast.success(this.transloco.translate('categories.toast_root_created'));
			},
			error: this.defaultErrorHandler,
		});
	}

	update(shopId: string, category: CategoryResponse, isRoot: boolean) {
		this.api.put(shopId, category).subscribe({
			next: updatedCategory => {
				this.categoriesSubject.next(
					modifyCategory(
						category.categoryId,
						category => ({
							...category,
							...updatedCategory,
							children: category.children,
						}),
						this.categoriesSubject.getValue(),
					),
				);

				this.toast.success(
					this.transloco.translate(
						isRoot ? 'categories.toast_root_updated' : 'categories.toast_child_updated',
					),
				);
			},
		});
	}

	delete(shopId: string, id: string) {
		return this.api.delete(shopId, id).subscribe({
			next: () => {
				this.categoriesSubject.next(deleteCategory(id, this.categoriesSubject.getValue()));

				this.toast.success(this.transloco.translate('categories.toast_deleted'));
			},
			error: this.defaultErrorHandler,
		});
	}
}
