import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject, map, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@services/toast.service';

import { EditorLocale } from 'angular-markdown-editor';
import { ShopService } from '@services/shop.service';
import { MarkdownService } from 'ngx-markdown';
import { ImageService } from '@services/image.service';
import { errors, maxFilesize, validateImage } from '@utils/validations';
import { HelloService } from '@services/hello.service';

@Component({
	selector: 'tg-shop-settings-hello',
	templateUrl: './shop-settings-hello.component.html',
	styleUrls: ['./shop-settings-hello.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsHelloComponent implements OnInit {
	form!: FormGroup;
	editorOptions: any = {};
	markdownText = new BehaviorSubject<string>('');
	photos = [];
	shopId: string;

	locale: EditorLocale = {
		language: 'ru',
		dictionary: {
			'Bold': 'Жирный',
			'bold': 'жирный',
			'Italic': 'Курсив',
			'italic': 'курсив',
			'Heading': 'Заголовок',
			'URL/Link': 'Вставьте url',
			'Image': 'Вставьте изображение',
			'List': 'Список',
			'Quote': 'Цитата',
			'quote here': 'Текст цитаты',
			'Preview': 'Предпросмотр',
			'strong text': 'жирный текст',
			'Strikethrough': 'Перечеркнутый',
			'strikethrough': 'перечеркнутый',
			'emphasized text': 'текст курсивом',
			'heading text': 'Заголовок',
			'enter link description here': 'Описание ссылки',
			'Insert Hyperlink': 'Вставьте ссылку',
			'enter image description here': 'Описание изображения',
			'Insert Image Hyperlink': 'Вставьте ссылку на изображение',
			'enter image title here': 'Заголовок изображения',
			'list text here': 'элемент списка',
			'Unordered List': 'Маркированный список',
			'Ordered List': 'Нумерованный список',
		},
	};

	constructor(
		private fb: FormBuilder,
		public shopService: ShopService,
		private helloService: HelloService,
		private cdr: ChangeDetectorRef,
		private markdownService: MarkdownService,
		private toast: ToastService,
		private imageService: ImageService,
		private route: ActivatedRoute,
	) {
		this.route.parent.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {});
	}

	refresh() {
		this.buildForm();
		this.helloService.getHello(this.shopId).subscribe({
			next: res => {
				if (res?.welcomeText) {
					this.photos = res.welcomeFiles;

					let text = res.welcomeText;
					text = text.split('*').join('**');
					text = text.split('~').join('~~');
					this.markdownText.next(text);
					this.form.patchValue({
						welcomeText: text,
					});
				} else {
					const defaultText: string = `Рады видеть Вас в нашем магазине ${this.shopService.shopInfo.value?.shopName}! Чтобы приступить к покупкам, перейдите в "Каталог"`;

					this.markdownText.next(defaultText);
					this.form.patchValue({
						welcomeText: defaultText,
					});
				}
				this.form.updateValueAndValidity();
			},
			error: err => {
				if (err.status === 404) {
				} else {
					this.toast.error(err.error?.message);
				}
			},
		});
	}

	ngOnInit() {
		this.editorOptions = {
			language: 'ru',
			parser: val => this.markdownService.compile(val.trim()),
			fullscreen: false,
			hiddenButtons: ['cmdTable', 'cmdImage'],
			disabledButtons: ['Code'],
		};
		this.refresh();
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	onChange(e) {
		this.form.controls['welcomeText'].setValue(e.getContent());
	}

	buildForm() {
		this.form = this.fb.group({
			welcomeText: ['', [Validators.required, Validators.maxLength(4096)]],
			welcomeFiles: [[]],
		});
	}

	getTrimmedData = obj => {
		if (obj && typeof obj === 'object') {
			Object.keys(obj).forEach(key => {
				if (typeof obj[key] === 'object') {
					this.getTrimmedData(obj[key]);
				} else if (typeof obj[key] === 'string') {
					obj[key] = obj[key].trim();
				}
			});
		}
		return obj;
	};

	uploadPhoto({ target }: any, isColor: boolean): void {
		const files = target.files;

		let skipErr: boolean = false;
		Object.keys(files).forEach((key, index) => {
			if (!validateImage(files[key].type, files[key].size)) {
				if (files.length > 1) {
					!skipErr &&
						this.toast.error('Не все фото загружены. Проверьте размер или формат загружаемых файлов');
					skipErr = true;
					return;
				}
				this.toast.error(
					files[key].size > maxFilesize
						? 'Объём файла не должен превышать 5 MB'
						: 'Допустимый формат: PNG, JPEG, JPG, GIF, WEBP',
				);
				return;
			}
			this.imageService.uploadImage(files[key], 'WELCOME_PHOTO').subscribe({
				next: data => {
					if (this.photos.length < 10) {
						this.photos.push(data.id);
					}
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
				complete: () => {
					target.value = '';
					this.cdr.markForCheck();
				},
			});
		});
	}

	deletePhoto(id: string) {
		this.photos = this.photos!.filter(photo => photo !== id);
		this.cdr.markForCheck();
	}

	submit(): void {
		let text = this.form.get('welcomeText').value;

		text = text.split('**').join('*');
		text = text.split('~~').join('~');
		this.form.get('welcomeText').setValue(text);

		this.form.get('welcomeFiles').setValue(this.photos);

		const value = this.form.value;

		this.helloService.postHello(this.shopId, value).subscribe({
			next: res => {
				console.log(res);
				this.toast.success('Приветственное сообщение успешно изменено!');
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}
}
