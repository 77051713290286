import { Pipe, PipeTransform } from '@angular/core';
import { VendorCodeResponse } from '@models/generated/api';

@Pipe({
	name: 'reduceAmount',
})
export class ReduceAmountPipe implements PipeTransform {
	transform(vendorCodes: Array<VendorCodeResponse>): number {
		return vendorCodes?.reduce((vAcc, vCurr) => {
			const positionsAmount = vCurr.positions.reduce((pAcc, pCurr) => {
				return (pAcc += pCurr.amount ?? 0);
			}, 0);
			return (vAcc += positionsAmount);
		}, 0);
	}
}
