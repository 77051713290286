import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ShopResponse } from '@models/generated/api';
import { ShopService } from '@services/shop.service';
import { TokenValidator } from '@directives/validate-shop-token.directice';
import { errors, noWhitespaceValidator } from '@utils/validations';

@Component({
	selector: 'tg-shop-create',
	templateUrl: './shop-create.component.html',
	styleUrls: ['./shop-create.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopCreateComponent {
	isEdit: boolean = false;
	form!: FormGroup;
	currency = [
		{
			name: 'Рубль',
			sign: '₽',
			value: 'RUB',
		},
		{
			name: 'U.S. Dollar',
			sign: '$',
			value: 'USD',
		},
		{
			name: 'Soʻm',
			sign: 'сўм',
			value: 'UZS',
		},
	];

	constructor(
		private fb: FormBuilder,
		@Inject(DIALOG_DATA) public dialogData: ShopResponse,
		public dialogRef: DialogRef,
		public shopService: ShopService,
		private cdr: ChangeDetectorRef,
	) {
		this.buildForm();

		if (this.dialogData) {
			this.isEdit = true;
			this.form.patchValue(this.dialogData, {
				emitEvent: false,
			});
		}
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	get username() {
		return this.form.get('authorTelegramUserName');
	}

	get token() {
		return this.form.get('token');
	}

	hasTokenError() {
		return this.token?.errors?.hasOwnProperty('tokenValidation');
	}

	buildForm() {
		this.form = this.fb.group({
			token: [
				'',
				{
					validators: [Validators.required, noWhitespaceValidator],
					asyncValidators: [TokenValidator.createValidator(this.shopService, this.cdr)],
				},
			],
			shopName: ['', [Validators.required, noWhitespaceValidator]],
			authorTelegramUserName: [
				'',
				[Validators.required, Validators.pattern('[a-zA-Z0-9_]*'), noWhitespaceValidator],
			],
			minimumOrderSum: ['', [Validators.required]],
			currency: ['RUB'],
			productType: ['GOODS'],
		});
	}

	getTrimmedData = obj => {
		if (obj && typeof obj === 'object') {
			Object.keys(obj).forEach(key => {
				if (typeof obj[key] === 'object') {
					this.getTrimmedData(obj[key]);
				} else if (typeof obj[key] === 'string') {
					obj[key] = obj[key].trim();
				}
			});
		}
		return obj;
	};

	submit(): void {
		const value = this.form.value;

		this.dialogRef.close(this.getTrimmedData(this.form.value));
	}
}
