import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TkIconModule } from '@technokratos/components-angular/icon';

@Component({
	standalone: true,
	selector: 'tk-eye',
	templateUrl: './eye.component.html',
	styleUrls: ['./eye.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TkIconModule],
})
export class EyeComponent {
	hide = true;
	@Input() input?: HTMLInputElement;

	changeType(): void {
		this.hide = !this.hide;
		this.input.type = this.hide ? 'password' : 'text';
	}
}
