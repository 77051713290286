import { Injectable } from '@angular/core';
import { LocalStorage } from '@utils/local-storage';
import { NullableString } from '@services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class LangService {
	@LocalStorage() defaultLang: NullableString;

	setDefaultLang(lang: string) {
		this.defaultLang = lang;
	}
}
