import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { CategoryResponse } from '@models/generated/api';

@Component({
	selector: 'tg-categories-tree-view-row',
	templateUrl: './categories-tree-view-row.component.html',
	styleUrls: ['./categories-tree-view-row.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesTreeViewRowComponent {
	@Input() category: CategoryResponse;

	@Input() depth: number;

	@Output() onEdit = new EventEmitter<CategoryResponse[]>();

	@Output() onCreateSubCategory = new EventEmitter<CategoryResponse[]>();

	@Output() onDelete = new EventEmitter<CategoryResponse>();

	@Output() onChildrenToggle = new EventEmitter<CategoryResponse>();

	actionsIsOpen = false;

	actionsMenuPositions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'top',
			overlayX: 'center',
			overlayY: 'bottom',
			offsetY: 10,
		},
	];

	protected readonly Array = Array;

	editAction(categories: CategoryResponse[]) {
		this.onEdit.emit([...categories, this.category]);
		this.actionsIsOpen = false;
	}

	createSubCategoryAction(categories: CategoryResponse[]) {
		this.onCreateSubCategory.emit([...categories, this.category]);
		this.actionsIsOpen = false;
	}

	deleteAction(category: CategoryResponse) {
		this.onDelete.emit(category);
		this.actionsIsOpen = false;
	}

	childrenToggleAction(category: CategoryResponse) {
		this.onChildrenToggle.emit(category);
	}

	toggleActionsMenu(event: Event, isOpen: boolean) {
		event.stopPropagation();

		this.actionsIsOpen = isOpen;
	}
}
