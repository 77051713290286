import { Pipe, PipeTransform } from '@angular/core';
import { VendorCodePhotoResponse, VendorCodeResponse } from '@models/generated/api';

@Pipe({
	name: 'getTitlePhoto',
})
export class GetTitlePhotoPipe implements PipeTransform {
	transform(vendorCodes: Array<VendorCodeResponse>): VendorCodePhotoResponse {
		const titlePhoto = vendorCodes
			?.map(vendor => vendor.photos)
			.flat()
			.filter(photo => photo.isTitle)
			.flat();

		return titlePhoto?.[0];
	}
}
