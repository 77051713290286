import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { CategoriesService } from '@services/categories';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@services/toast.service';
import { CategoryResponse } from '@models/generated/api';
import { DestroyService } from '@services/destroy.service';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { CategoryEditComponent } from '@components/modals/category-edit/category-edit.component';
import { CategoryDeleteComponent } from '@components/modals/category-delete/category-delete.component';

const SEARCH_DEBOUNCE_TIME = 300;

@Component({
	selector: 'tg-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.sass'],
	providers: [CategoriesService, DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesComponent {
	searchControl = new FormControl();
	shopId: string | null = null;
	categories$ = this.categoryService.categories$;
	loaded$ = this.categoryService.loaded$;

	constructor(
		private route: ActivatedRoute,
		private destroy$: DestroyService,
		private dialog: Dialog,
		private categoryService: CategoriesService,
	) {
		this.shopId = this.route.snapshot.paramMap.get('id');
		this.categoryService.load(this.shopId);

		this.searchControl.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(SEARCH_DEBOUNCE_TIME), distinctUntilChanged())
			.subscribe(query => {
				this.categoryService.load(this.shopId, query);
			});
	}

	childrenToggleHandler({ categoryId }: CategoryResponse) {
		this.categoryService.toggleChildren(categoryId);
	}

	createCategoryHandler() {
		this.dialog
			.open<CategoryResponse>(CategoryEditComponent, {
				data: { mode: 'new-root', actualCategory: {} },
			})
			.closed.pipe(filter(newCategory => !!newCategory))
			.subscribe(newCategory => {
				this.categoryService.add(this.shopId, newCategory);
			});
	}

	createSubCategoryHandler(parentCategories: CategoryResponse[]) {
		const parentId = parentCategories[0]?.categoryId ?? '';

		this.dialog
			.open<CategoryResponse>(CategoryEditComponent, {
				data: { mode: 'new-child', parentCategories: parentCategories.reverse(), actualCategory: {} },
			})
			.closed.pipe(filter(newCategory => !!newCategory))
			.subscribe(newCategory => {
				this.categoryService.add(this.shopId, newCategory, parentId);
			});
	}

	editCategoryHandler(parentCategories: CategoryResponse[]) {
		const reversedParentCategories = parentCategories.reverse();
		const actualCategory = reversedParentCategories.pop();
		const isRootCategory = parentCategories.length < 1;

		this.dialog
			.open<CategoryResponse>(CategoryEditComponent, {
				data: {
					mode: isRootCategory ? 'edit-root' : 'edit-child',
					parentCategories: reversedParentCategories,
					actualCategory,
				},
			})
			.closed.pipe(filter(updatedCategory => !!updatedCategory))
			.subscribe(updatedCategory => {
				this.categoryService.update(this.shopId, updatedCategory, isRootCategory);
			});
	}

	deleteCategoryHandler({ categoryId }: CategoryResponse) {
		this.dialog
			.open<boolean>(CategoryDeleteComponent)
			.closed.pipe(filter(confirm => !!confirm))
			.subscribe(() => {
				this.categoryService.delete(this.shopId, categoryId);
			});
	}
}
