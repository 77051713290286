import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApishipResponse, ProductDimensionsTypeResponse } from '@models/generated/api';

@Injectable({
	providedIn: 'root',
})
export class DeliverySettingsService {
	constructor(private http: HttpClient) {}

	getApiship(shopId: string): Observable<ApishipResponse> {
		return this.http.get<ApishipResponse>(`shops/${shopId}/deliveries/apiship`);
	}

	setApiship(
		shopId: string,
		data: any,
		dadata: any,
		dimensions: ProductDimensionsTypeResponse[],
		oldAddressId: string,
	): Observable<ApishipResponse> {
		const body = {
			...data,
			shopInfo: {
				...data.shopInfo,
				address: {
					address: dadata.address || dadata.addressShort,
					fiasId: dadata.fiasId,
				},
			},
			defaultWidth: +data.defaultWidth,
			defaultHeight: +data.defaultHeight,
			defaultLength: +data.defaultLength,
			defaultWeight: +data.defaultWeight,
		};
		if (oldAddressId) {
			body.shopInfo.address.id = oldAddressId;
		}
		if (body.sizeType !== 'Другое') {
			const dimensionsType = dimensions.find(item => item.productDimensionType === body.sizeType);
			body.defaultWidth = dimensionsType.width;
			body.defaultHeight = dimensionsType.height;
			body.defaultLength = dimensionsType.length;
		}
		delete body.sizeType;
		body.apishipDeliveryServices.forEach(provider => {
			delete provider.pickPointTime;
		});

		return this.http.post<ApishipResponse>(`shops/${shopId}/deliveries/apiship`, body);
	}

	getApishipPickupPoints(token: string, providerKey: string, cityGuid: string): Observable<any> {
		return this.http.get<any>(`integration/apiship/pick-up-points`, { params: { token, providerKey, cityGuid } });
	}

	getApishipProviders(token: string, shopId: string): Observable<any> {
		return this.http.get<any>(`integration/apiship`, { params: { token, shopId } });
	}
}
