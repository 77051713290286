import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaginatedResponse, PaginationModel } from '@models/pagination.model';
import { FormControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { BehaviorSubject, map, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PromocodeRequest, PromocodeResponse } from '@models/generated/api';
import { ToastService } from '@services/toast.service';
import { Dialog } from '@angular/cdk/dialog';
import { PromoService } from '@services/promo.service';
import { PromoCreateComponent } from '@components/modals/promo-create/promo-create.component';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-shop-settings-newsletter',
	templateUrl: './shop-settings-promo.component.html',
	styleUrls: ['./shop-settings-promo.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSettingsPromoComponent extends PaginationModel {
	columns = [
		'promocodeName',
		'validDateFrom',
		'discountValue',
		'notApplicableDiscounted',
		'minOrderSum',
		'validDateTo',
		'promocodeState',
		'actions',
	];

	menuIsOpen: string = '';
	interval;

	promoList$ = new BehaviorSubject<PaginatedResponse<PromocodeResponse[]>>({});
	shopId: string;

	search: boolean = false;
	searchControl = new FormControl();

	positions: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'top',
			overlayX: 'center',
			overlayY: 'bottom',
			offsetY: 10,
		},
	];

	constructor(
		private promoService: PromoService,
		private route: ActivatedRoute,
		private toast: ToastService,
		private dialog: Dialog,
	) {
		super();

		this.route.parent.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
			});
	}

	refresh() {
		this.promoService.getPromoList(this.shopId, this.currentSettings).subscribe({
			next: (res: any) => {
				this.promoList$.next(res);
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	clickCreateBtn() {
		// @ts-ignore
		ym(environment.metricId, 'reachGoal', 'promocode_creation_start');
		this.dialog
			.open<PromocodeRequest>(PromoCreateComponent, {
				data: {
					shopId: this.shopId,
				},
			})
			.closed.subscribe({
				next: res => {
					if (res) {
						this.toast.success('Промокод успешно создан!');
						this.refresh();
					}
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
			});
	}

	onChangeSearch() {
		if (this.searchControl.value?.length < 3) {
			if (this.search) {
				this.search = false;
				this.refresh();
			}
			return;
		} else {
			this.search = true;
		}
		if (this.search) {
			this.refresh();
		}
	}

	openMenu(id: string, event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.menuIsOpen = id;
	}

	closeMenu() {
		this.menuIsOpen = '';
	}

	cancelPromo(id: string) {
		this.promoService.updatePromo(this.shopId, id).subscribe({
			next: res => {
				this.refresh();
				this.toast.success('Статус промокода изменён');
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	deletePromo(id: string) {
		this.promoService.deletePromo(this.shopId, id).subscribe({
			next: res => {
				this.refresh();
				this.toast.success('Промокод успешно удалён');
			},
		});
	}

	override changePage(page: number): void {
		super.changePage(page);
		this.refresh();
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}
}
