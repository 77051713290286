import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { ShopService } from '@services/shop.service';

@Component({
	selector: 'tg-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.page-container]': 'true',
	},
})
export class PageComponent {
	showTrialBanner: boolean = false;
	isExpired: boolean = false;
	counter: number;

	constructor(private authService: AuthService, private router: Router, public shopService: ShopService) {
		if (!this.authService.token) {
			return;
		}
		if (this.authService.subscriptionInfo().counter <= 14 && this.authService.subscriptionInfo().isTrial) {
			this.showTrialBanner = true;
			this.counter = this.authService.subscriptionInfo().counter;
		}

		if (!this.authService.subscriptionInfo().status) {
			this.showTrialBanner = true;
			this.isExpired = true;
		}
	}

	navigateToPlans() {
		this.router.navigate(['/tarifs']);
	}
}
