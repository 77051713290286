import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
	currentYear = new Date().getFullYear();

	openChat = () => {
		// @ts-ignore
		ym(environment.metricId, 'reachGoal', 'open_supportchat');
		window.open('https://t.me/helptgshop_bot', '_blank');
	};
}
