<tg-dialog-header>
	<div><img src="assets/images/ukassa.png" /> Юкасса</div>
</tg-dialog-header>

<tg-dialog-body>
	<ng-container *transloco="let t">
		<ng-container *ngIf="shopService.shopMoneySign | async">
			<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
				<tg-info-plate>
					{{ t('payment.instruction_first.head') }}<br /><br />

					{{ t('payment.instruction_first.step_1_1') }}&nbsp;<a
						href="https://yookassa.ru/joinups/?source=TGShop"
						rel="noopener noreferrer"
						target="_blank"
						>{{ t('payment.instruction_first.step_1_2') }}</a
					>. {{ t('payment.instruction_first.step_1_3') }}
					<a href="https://yookassa.ru/joinups/?source=TGShop" rel="noopener noreferrer" target="_blank">{{
						t('payment.instruction_first.step_1_4')
					}}</a
					><br />
					{{ t('payment.instruction_first.step_2') }}<br />
					{{ t('payment.instruction_first.step_3') }}<br />
					{{ t('payment.instruction_first.step_4') }}<br />
					{{ t('payment.instruction_first.step_5') }}<br />
					{{ t('payment.instruction_first.step_6') }}
				</tg-info-plate>
			</ng-container>
			<ng-container *ngIf="shopService.shopMoneySign.value !== '₽'">
				<tg-info-plate>
					{{ t('payment.instruction_second.head') }}<br /><br />

					{{ t('payment.instruction_second.step_1_1') }}
					<a href="https://t.me/BotFather" target="_blank">
						{{ t('payment.instruction_second.step_1_2') }}
					</a>
					{{ t('payment.instruction_second.step_1_3') }}<br />
					{{ t('payment.instruction_second.step_2_1') }}
					{{ t('payment.instruction_second.step_2_2') }}
					{{ t('payment.instruction_second.step_2_3') }}<br />
					{{ t('payment.instruction_second.step_3') }}<br />
					{{ t('payment.instruction_second.step_4') }}<br />
					{{ t('payment.instruction_second.step_5') }}
				</tg-info-plate>
			</ng-container>
		</ng-container>
		<form (submit)="submit()">
			<tk-form-field>
				<tk-label>Укажите токен</tk-label>
				<input type="text" tkInput tkInputTrim [formControl]="tokenControl" />
			</tk-form-field>

			<ng-container *ngIf="shopService.shopMoneySign | async">
				<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
					<tg-info-plate>
						{{ t('payment.vat_instruction.head') }}<br /><br />

						{{ t('payment.vat_instruction.step_1') }}<br />
						{{ t('payment.vat_instruction.step_2') }}
					</tg-info-plate>

					<tk-form-field>
						<tk-label>Ставка НДС</tk-label>
						<tk-select [formControl]="vatControl">
							<tk-option *ngFor="let code of vatCodes" [value]="code.code">
								{{ code.value }}
							</tk-option>
						</tk-select>
					</tk-form-field>
				</ng-container>
			</ng-container>

			<tk-button-group>
				<tk-button theme="secondary" class="submit" (click)="cancel()">Отмена</tk-button>
				<tk-button class="submit" (click)="submit()">{{
					dialogData.formValue ? 'Сохранить' : 'Подключить'
				}}</tk-button>
			</tk-button-group>
		</form>
	</ng-container>
</tg-dialog-body>
