import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PasswordValidators, errors, validatePasswords } from '@utils/validations';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tk-reset-pass-form',
	templateUrl: './reset-pass.component.html',
	styleUrls: ['./reset-pass.component.sass', '../common.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPassFormComponent {
	@ViewChild('password') public passwordRef?: ElementRef;

	constructor(
		private authService: AuthService,
		private router: Router,
		private toast: ToastService,
		private route: ActivatedRoute,
	) {
		const confirmCode = this.route.snapshot.queryParams['confirmCode'];

		if (confirmCode) {
			this.authService.confirm(confirmCode).subscribe({
				next: data => {
					this.authService.authorize(data);
				},
				error: () => {
					this.router.navigate(['/sign-in']);
				},
			});
		}
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	form: UntypedFormGroup = new UntypedFormGroup(
		{
			'password': new UntypedFormControl(null, [Validators.required, ...PasswordValidators]),
			'passwordRepeat': new UntypedFormControl(null, [Validators.required, ...PasswordValidators]),
		},
		{
			validators: validatePasswords(),
		},
	);

	submitForm = () => {
		this.authService.passwordReset(this.form.value).subscribe({
			next: () => {
				this.toast.success('Пароль успешно изменён!');
				this.router.navigate(['/']);
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	};

	get isDisabled(): boolean {
		return this.form.invalid;
	}
}
