import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'tg-category-delete-modal',
	templateUrl: './category-delete.component.html',
	styleUrls: ['./category-delete.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryDeleteComponent {
	constructor(private dialogRef: DialogRef) {}

	submit() {
		this.dialogRef.close(true);
	}
}
