<tg-dialog-header>
	<div><tk-icon icon="tbank"></tk-icon> Т-касса</div>
</tg-dialog-header>

<tg-dialog-body>
	<ng-container *transloco="let t">
		<ng-container *ngIf="shopService.shopMoneySign | async">
			<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
				<tg-info-plate>
					Инструкция по подключению:
					<br /><br />
					1. Зарегистрируйтесь в
					<a href="https://tinkoff.ru/kassa/form/partner/tgshop/" rel="noopener noreferrer" target="_blank"
						>Тинькофф Кассе</a
					>. Чтобы получить выгодные условия подключения, используйте партнерскую
					<a href="https://tinkoff.ru/kassa/form/partner/tgshop/" rel="noopener noreferrer" target="_blank"
						>ссылку</a
					>.
					<br />
					2. После успешного подключения в ЛК Тинькофф Кассы скопируйте данные "Терминал" и "Пароль"
					<br />
					3. Остальные поля зависят от типа налогообложения вашего юридического лица. Поэтому выберите
					соответствующую систему налогообложения и НДС.
					<br />
					4. Далее в личном кабинете Тинькофф пройдите три теста.
					<br />
					<br />
					Если вы выполнили все шаги, вы завершили подключение сервиса Тинькофф Касса.
				</tg-info-plate>
			</ng-container>
			<a
				class="guide"
				href="https://blog.tgshop.io/tinkoff-kassa-podklyuchenie-tinkoff-pay-k-magazinu-v-telegram/"
				rel="noopener noreferrer"
				target="_blank"
			>
				Читать подробный гайд
				<tk-icon icon="chevron-right"></tk-icon>
			</a>
		</ng-container>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<tk-form-field>
				<tk-label>Идентификатор терминала</tk-label>

				<input type="text" tkInput formControlName="merchantKey" #merchantKey tkInputTrim />
				<tk-error
					*ngIf="errors(form, 'merchantKey') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>
			<tk-form-field>
				<tk-label>Пароль</tk-label>

				<input type="text" tkInput formControlName="password" tkInputTrim />
				<tk-error *ngIf="errors(form, 'password') as errorField" tkCommonError [errors]="errorField"></tk-error>
			</tk-form-field>

			<tk-form-field>
				<tk-label>ФФД</tk-label>
				<tk-select formControlName="ffdVersion">
					<tk-option *ngFor="let ffd of ffdCodes" [value]="ffd.code">
						{{ ffd.value }}
					</tk-option>
				</tk-select>
				<tk-error *ngIf="errors(form, 'ffdVersion')">Обязательное поле</tk-error>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Система налогооблажения</tk-label>
				<tk-select formControlName="taxation">
					<tk-option *ngFor="let taxation of taxationCodes" [value]="taxation.code">
						{{ taxation.value }}
					</tk-option>
				</tk-select>
				<tk-error *ngIf="errors(form, 'taxation')">Обязательное поле</tk-error>
			</tk-form-field>

			<tk-form-field>
				<tk-label>НДС</tk-label>
				<tk-select formControlName="vatCode">
					<tk-option *ngFor="let vat of vatCodes" [value]="vat.code">
						{{ vat.value }}
					</tk-option>
				</tk-select>
				<tk-error *ngIf="errors(form, 'vatCode')">Обязательное поле</tk-error>
			</tk-form-field>

			<tk-button-group>
				<tk-button theme="secondary" class="submit" (click)="cancel()">Отмена</tk-button>
				<tk-button class="submit" (click)="submit()">{{
					dialogData.formValue ? 'Сохранить' : 'Подключить'
				}}</tk-button>
			</tk-button-group>
		</form>
	</ng-container>
</tg-dialog-body>
