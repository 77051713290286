/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Импортированная категория
 */
export type CategoryImportResponse = {
	/**
	 * Идентификатор
	 */
	id?: string;
	/**
	 * Наименование категории
	 */
	categoryName?: string;
	/**
	 * Идентификатор из фид файла
	 */
	externalId?: string;
	/**
	 * Статус импорта
	 */
	importState?: CategoryImportResponse.importState;
	/**
	 * Статус импорта
	 */
	importStateDescription?: string;
	/**
	 * Идентификатор категории
	 */
	categoryId?: string;
	/**
	 * Ошибка
	 */
	errorMessage?: string;
};

export namespace CategoryImportResponse {
	/**
	 * Статус импорта
	 */
	export enum importState {
		READ = 'READ',
		INVALID = 'INVALID',
		SAVED = 'SAVED',
		WARN = 'WARN',
		FAILED = 'FAILED',
	}
}
