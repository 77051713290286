/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Поле оформления заказа
 */
export type ShopOrderFieldResponse = {
	/**
	 * Идентификатор
	 */
	id?: string;
	/**
	 * Название поля
	 */
	name?: string;
	/**
	 * Подсказка
	 */
	placeholder?: string;
	/**
	 * Флаг обязательности
	 */
	required?: boolean;
	/**
	 * Тип валидации
	 */
	validationType?: ShopOrderFieldResponse.validationType;
	/**
	 * Минимальная длина
	 */
	minLength?: number;
	/**
	 * Максимальная длина
	 */
	maxLength?: number;
	/**
	 * Признак активности поля
	 */
	enabled?: boolean;
};

export namespace ShopOrderFieldResponse {
	/**
	 * Тип валидации
	 */
	export enum validationType {
		EMAIL = 'EMAIL',
		PHONE = 'PHONE',
		NOT_SPECIFIED = 'NOT_SPECIFIED',
	}
}
