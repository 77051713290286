<ng-container *transloco="let t">
	<form [formGroup]="form" (ngSubmit)="$event.preventDefault(); onSave()">
		<tg-dialog-header>
			{{ dialogData?.link ? t('beautiful_link_modal.title_edit') : t('beautiful_link_modal.title_add') }}
		</tg-dialog-header>

		<tg-dialog-body>
			<div class="instruction">
				<tk-icon icon="info"></tk-icon>
				<div class="instruction__content">
					{{ t('beautiful_link_modal.instruction') }}
					<ol>
						<li>{{ t('beautiful_link_modal.step_1') }}</li>
						<li>{{ t('beautiful_link_modal.step_2') }}</li>
						<li>{{ t('beautiful_link_modal.step_3') }}</li>
						<li>{{ t('beautiful_link_modal.step_4') }}</li>
						<li>{{ t('beautiful_link_modal.step_5') }}</li>
					</ol>
				</div>
			</div>

			<tk-form-field>
				<tk-label>{{ t('beautiful_link_modal.link_placeholder') }}</tk-label>
				<input tkInput formControlName="link" />
				<tk-error *ngIf="hasError('link')">
					{{ t(getErrorMessage('link')) }}
				</tk-error>
			</tk-form-field>
		</tg-dialog-body>

		<tg-dialog-footer>
			<button tkButton size="xl" theme="secondary" type="button" (click)="onCancel()">
				{{ t('global.cancel') }}
			</button>
			<button tkButton size="xl" theme="primary" type="submit" [disabled]="!form.valid">
				{{ t('global.save') }}
			</button>
		</tg-dialog-footer>
	</form>
</ng-container>
