<tg-dialog-header>
	<div>Свой способ оплаты</div>
</tg-dialog-header>

<tg-dialog-body>
	<ng-container *transloco="let t">
		<ng-container *ngIf="shopService.shopMoneySign | async">
			<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
				<tg-info-plate>
					Настройте дополнительные способы оплаты, чтобы предложить вашим клиентам больше гибкости при
					оформлении заказа.
				</tg-info-plate>
			</ng-container>
		</ng-container>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<tk-form-field>
				<tk-label>Название, например “Оплата наличными”</tk-label>

				<input type="text" tkInput formControlName="name" tkInputTrim />
				<tk-error *ngIf="errors(form, 'name') as errorField" tkCommonError [errors]="errorField"></tk-error>
			</tk-form-field>

			<div>Скидка:</div>
			<tk-radio-group direction="horizontal" formControlName="discountType">
				<tk-radio-button value="DISCOUNT"> В рублях (₽) </tk-radio-button>
				<tk-radio-button value="PERCENT"> В процентах (%) </tk-radio-button>
			</tk-radio-group>

			<tk-form-field>
				<tk-label>Размер скидки</tk-label>

				<input type="number" tkInput formControlName="discountValue" tkInputTrim />
				<tk-error
					*ngIf="errors(form, 'discountValue') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
				<tk-suffix>{{ discountType === 'DISCOUNT' ? '₽' : '%' }}</tk-suffix>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Описание</tk-label>

				<input type="text" tkInput formControlName="description" tkInputTrim />
				<tk-error
					*ngIf="errors(form, 'description') as errorField"
					tkCommonError
					[errors]="errorField"
				></tk-error>
			</tk-form-field>
			<!--			<tk-form-field>-->
			<!--				<tk-label>Пароль</tk-label>-->

			<!--				<input type="text" tkInput formControlName="password" tkInputTrim />-->
			<!--				<tk-error *ngIf="errors(form, 'password') as errorField" tkCommonError [errors]="errorField"></tk-error>-->
			<!--			</tk-form-field>-->

			<tk-button-group>
				<tk-button theme="secondary" class="submit" (click)="cancel()">Отмена</tk-button>
				<tk-button class="submit" (click)="submit()">{{
					dialogData.formValue ? 'Сохранить' : 'Подключить'
				}}</tk-button>
			</tk-button-group>
		</form>
	</ng-container>
</tg-dialog-body>
