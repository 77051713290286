<ng-content></ng-content>
<ng-template
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="elementRef"
	[cdkConnectedOverlayOpen]="overlayOpened"
	cdkConnectedOverlayHasBackdrop
	cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
	[cdkConnectedOverlayPositions]="overlayPositions"
	(detach)="close()"
	(backdropClick)="close()"
>
	<tk-panel class="tk-date-input__panel" *ngIf="startDateControl && endDateControl">
		<tk-calendar
			[startDate]="startDate"
			[selectionStart]="startDateControl!.value"
			[selectionEnd]="endDateControl!.value"
			(dateSelected)="dateSelected($event)"
		></tk-calendar>
	</tk-panel>
</ng-template>
