/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PromocodeResponse } from './PromocodeResponse';

/**
 * Краткая информация по заказу
 */
export type OrderShortInfoResponse = {
	/**
	 * Идентификатор заказа
	 */
	orderId?: string;
	/**
	 * Номер заказа
	 */
	orderNumber?: string;
	/**
	 * Дата  заказа
	 */
	orderDate?: string;
	/**
	 * Сумма заказа
	 */
	cost?: number;
	/**
	 * Сумма заказа до вычета скидок
	 */
	price?: number;
	/**
	 * Размер скидки
	 */
	discount?: number;
	/**
	 * ФИО покупателя
	 */
	receiverName?: string;
	/**
	 * Статус оплаты
	 */
	paymentState?: OrderShortInfoResponse.paymentState;
	/**
	 * Статус оплаты
	 */
	paymentStateDescription?: string;
	/**
	 * Статус заказа
	 */
	orderProcessingState?: OrderShortInfoResponse.orderProcessingState;
	/**
	 * Статус заказа
	 */
	orderProcessingStateDescription?: string;
	/**
	 * Способ доставки
	 */
	deliveryType?: OrderShortInfoResponse.deliveryType;
	/**
	 * Способ доставки
	 */
	deliveryTypeDescription?: string;
	/**
	 * Флаг для обозначения новых заказов
	 */
	isViewed?: boolean;
	promocode?: PromocodeResponse;
};

export namespace OrderShortInfoResponse {
	/**
	 * Статус оплаты
	 */
	export enum paymentState {
		NOT_PAID = 'NOT_PAID',
		PAID = 'PAID',
		FAILED = 'FAILED',
		ISSUED = 'ISSUED',
	}

	/**
	 * Статус заказа
	 */
	export enum orderProcessingState {
		CREATED = 'CREATED',
		SENT = 'SENT',
		DELIVERED = 'DELIVERED',
		CANCELED = 'CANCELED',
	}

	/**
	 * Способ доставки
	 */
	export enum deliveryType {
		PICK_UP = 'PICK_UP',
		DELIVERY = 'DELIVERY',
		APISHIP = 'APISHIP',
		NONE = 'NONE',
	}
}
