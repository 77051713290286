/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Атол Информация
 */
export type AtolInfoResponse = {
	/**
	 * Логин от системы Атол
	 */
	login?: string;
	/**
	 * Пароль от системы Атол
	 */
	pass?: string;
	/**
	 * Идентификатор группы ККТ
	 */
	groupCode?: string;
	/**
	 * Электронный адрес покупателя
	 */
	companyEmail?: string;
	/**
	 * Система налогообложения
	 */
	companySno?: AtolInfoResponse.companySno;
	/**
	 * ИНН организации
	 */
	companyInn?: string;
	/**
	 * Место расчетов
	 */
	companyPaymentAddress?: string;
};

export namespace AtolInfoResponse {
	/**
	 * Система налогообложения
	 */
	export enum companySno {
		OSN = 'OSN',
		USN_INCOME = 'USN_INCOME',
		USN_INCOME_OUTCOME = 'USN_INCOME_OUTCOME',
		ENVD = 'ENVD',
		ESN = 'ESN',
		PATENT = 'PATENT',
	}
}
