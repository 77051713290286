import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PaginationService {
	currentOrderPage = new BehaviorSubject<number>(0);
	currentProductPage = new BehaviorSubject<number>(0);
	currentShopId = new BehaviorSubject<string>('');
}
