import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { ShopCreateRequest, ShopResponse } from '@models/generated/api';
import { FormControl } from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import { filter, switchMap, takeUntil } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { ShopCreateComponent } from '@components/modals/shop-create/shop-create.component';
import { Dialog } from '@angular/cdk/dialog';
import { ShopDeleteComponent } from '@components/modals/shop-delete/shop-delete.component';
import { LoadingService } from '@services/loading.service';
import shopState = ShopResponse.shopState;
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-shop-detail',
	templateUrl: './shop-detail.component.html',
	styleUrls: ['./shop-detail.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class ShopDetailComponent implements OnInit {
	shop?: ShopResponse;
	statusControl = new FormControl();

	constructor(
		public route: ActivatedRoute,
		private destroy$: DestroyService,
		private shopService: ShopService,
		private toast: ToastService,
		private cdr: ChangeDetectorRef,
		private dialog: Dialog,
		private router: Router,
		public loading$: LoadingService,
		private clipboard: Clipboard,
	) {
		this.statusControl.valueChanges
			.pipe(
				switchMap((value: shopState | string) => this.shopService.updateStatus(this.shop?.id, value)),
				takeUntil(this.destroy$),
			)
			.subscribe(
				res => {
					this.refresh();
					this.statusControl.patchValue(res.shopState, {
						emitEvent: false,
					});
					if (res.shopState === shopState.ACTIVE) {
						// @ts-ignore
						ym(environment.metricId, 'reachGoal', 'activate_store');
					}
					this.toast.success(
						res.shopState === shopState.STOPPING
							? 'Ваш запрос на остановку принят, бот остановится в течение нескольких минут'
							: 'Статус успешно изменен',
					);
				},
				err => {
					this.refresh();
					this.toast.error(
						err.error?.message === 'There must only one active shop with that token'
							? 'Для активации данного магазина необходимо остановить другой магазин, к которому привязан текущий бот'
							: err.error?.message,
					);
				},
			);
	}

	refresh() {
		this.loading$.next(true);
		this.shopService
			.getShop(this.route.snapshot.params?.['id'])
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.shop = res;
				this.shopService.shopInfo.next(res);
				this.shopService.setMoneySign(res);

				this.statusControl.patchValue(this.shop?.shopState, {
					emitEvent: false,
				});
				this.cdr.markForCheck();
				this.loading$.next(false);
			});
	}

	ngOnInit(): void {
		this.refresh();
	}

	editShop(): void {
		this.dialog
			.open<ShopCreateRequest>(ShopCreateComponent, {
				data: {
					token: this.shop.botToken,
					shopName: this.shop.shopName,
					authorTelegramUserName: this.shop.authorTelegramUserName,
					currency: this.shop.currency,
					minimumOrderSum: this.shop.minimumOrderSum,
					productType: this.shop.productType,
				},
			})
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(v => !!v),
				switchMap(data => this.shopService.editShop(this.shop.id, data)),
			)
			.subscribe({
				next: () => {
					this.toast.success('Магазин успешно обновлен');
					this.refresh();
				},
				error: err => {
					this.toast.error(err?.error?.message);
					this.refresh();
				},
				complete: () => {},
			});
	}

	deleteShop(): void {
		this.dialog
			.open(ShopDeleteComponent)
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(Boolean),
				switchMap(() => this.shopService.deleteShop(this.shop.id)),
			)
			.subscribe({
				next: () => {
					this.router.navigate(['/shop']);
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'store_deleted');
					this.toast.success('Магазин успешно удален');
				},
				error: err => {
					this.toast.error(err?.error?.message);
				},
				complete: () => {
					this.refresh();
				},
			});
	}

	copyShopLink() {
		this.clipboard.copy(environment.frontUrl + this.route.snapshot.params?.['id']);
		this.toast.success('Ссылка на магазин скопирована');
	}

	openGuide(): void {
		window.open('https://tgshop.io/blog/instrukciya-kak-sozdat-magazin-v-telegram-po-shagam', '_blank');
	}
}
