import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
	selector: '[tgLocationBack]',
})
export class LocationBackDirective {
	constructor(private location: Location) {}

	@HostListener('click') onClick(): void {
		this.location.back();
	}
}
