<ng-container *transloco="let t">
	<div class="header">
		<tg-breadcrumbs [exactMatch]="false"></tg-breadcrumbs>
	</div>

	<ng-container *ngIf="orderData$ | async">
		<div class="info-block">
			<div>
				<div class="info-block__name">
					{{ t('order_history.order_short') }} № {{ orderData$?.value.orderNumber }}
				</div>
				<div class="info-block__desc">
					{{ shopName }}
				</div>

				<div class="info-block__row">
					<div
						class="order-state"
						[class.order-state--not-paid]="orderData$.value.paymentState === 'NOT_PAID'"
						[class.order-state--paid]="orderData$.value.paymentState === 'PAID'"
					>
						{{ orderData$.value.paymentStateDescription }}
					</div>
				</div>
			</div>
			<div class="info-block__wrap">
				<div class="info-block__line">
					<div class="info-block__text">Дата оформления</div>
					<div>{{ orderData$?.value.orderDate + 'Z' | date : 'dd.MM.yyyy HH:mm' }}</div>
				</div>
				<div class="info-block__line">
					<div class="info-block__text">Тип получения</div>
					<div>{{ orderData$?.value.deliveryTypeDescription }}</div>
				</div>
				<div *ngIf="orderData$?.value.deliveryCost" class="info-block__line">
					<div class="info-block__text">Стоимость доставки</div>
					<div>{{ orderData$.value.deliveryCost + ' ' + shopService.shopMoneySign.value }}</div>
				</div>
				<div
					*ngIf="
						orderData$?.value.deliveryCost === 0 && orderData$?.value.deliveryType !== deliveryType.PICK_UP
					"
					class="info-block__line"
				>
					<div class="info-block__text">Стоимость доставки</div>
					<div>Бесплатно</div>
				</div>
				<div *ngIf="orderData$?.value.paymentType" class="info-block__line">
					<div class="info-block__text">Способ оплаты</div>
					<div>{{ orderData$?.value.paymentTypeDescription }}</div>
				</div>
				<div *ngIf="orderData$?.value.discount" class="info-block__line">
					<div class="info-block__text">Скидка</div>
					<div>{{ orderData$.value.discount }}</div>
				</div>
				<div *ngIf="orderData$?.value.promocode?.promocodeName" class="info-block__line">
					<div class="info-block__text">Промокод</div>
					<div>{{ orderData$.value.promocode.promocodeName }}</div>
				</div>
				<div class="info-block__line info-block__line--total">
					<div class="info-block__total">Итого</div>
					<div>{{ orderData$.value.cost?.toLocaleString() + ' ' + shopService.shopMoneySign.value }}</div>
				</div>
			</div>
		</div>

		<tg-tabs>
			<button (click)="changeTab('detail')" [class.active]="!infoTabActive">
				{{ t('order_history.order_items') }}
			</button>
			<button (click)="changeTab('info')" [class.active]="infoTabActive">
				{{ t('order_history.customer_info') }}
			</button>
		</tg-tabs>

		<div *ngIf="!infoTabActive" class="order__table">
			<tk-form-field class="order__search">
				<tk-label>{{ t('global.search') }}</tk-label>

				<input type="text" tkInput [formControl]="searchControl" />

				<tk-icon tkSuffix icon="search"></tk-icon>
			</tk-form-field>

			<div class="table-wrap">
				<cdk-table class="table" [dataSource]="orderData$.value.items | search : 'name' : searchControl.value">
					<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

					<ng-container cdkColumnDef="photo">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.photo') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							<img
								[src]="
									row.file ? (row.file?.id | getFile : 240 | async) : '/assets/images/default-img.jpg'
								"
								[alt]="row.file ? row.file.filename : 'Нет фото'"
							/>
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="name">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.name') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.productName }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="vendorCode">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.sku') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.vendorCodeValue }}
						</cdk-cell>
					</ng-container>

					<ng-container *ngIf="this.shopService.shopInfo.value?.productType === 'GOODS'" cdkColumnDef="size">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.size') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.size?.sizeValue === 'Без размера' ? '-' : row.size?.sizeValue }}
						</cdk-cell>
					</ng-container>

					<ng-container *ngIf="this.shopService.shopInfo.value?.productType === 'GOODS'" cdkColumnDef="color">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.color') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.color?.colorName === 'По умолчанию' ? '-' : row.color?.colorName }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="amount">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.quantity') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.amount }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="price">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.price') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							<ng-container *ngIf="row.realPrice !== row.price">
								<span class="price"
									>{{ row.price?.toLocaleString() }} {{ shopService.shopMoneySign.value }}</span
								>
								-
							</ng-container>
							{{ row.realPrice?.toLocaleString() }} {{ shopService.shopMoneySign.value }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="cost">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('order_history.price') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.cost?.toLocaleString() }} {{ shopService.shopMoneySign.value }}
						</cdk-cell>
					</ng-container>

					<cdk-row *cdkRowDef="let row; columns: columns"></cdk-row>
				</cdk-table>
			</div>
		</div>

		<div *ngIf="infoTabActive && orderData$.value?.customerResponse" class="info-block info-block--customer">
			<div class="row" *tgLet="orderData$.value?.customerResponse as customer">
				<div class="info-block__name">{{ customer.name }}</div>
				<div *ngIf="customer.phone" class="info-block__desc">{{ '+' }}{{ customer.phone }}</div>
				<div *ngIf="customer.email" class="info-block__desc">
					{{ customer.email }}
				</div>
				<a
					*ngIf="customer.telegram"
					[href]="'https://t.me/' + customer.telegram"
					target="_blank"
					class="info-block__desc"
				>
					@{{ customer.telegram }}
				</a>
			</div>
			<div class="">
				<div *ngIf="this.shopService.shopInfo.value?.productType === 'GOODS'" class="column">
					<div class="label">
						{{
							orderData$.value?.deliveryType === deliveryType.DELIVERY
								? t('order_history.delivery_address')
								: t('order_history.pickup_point')
						}}
					</div>
					<div class="value">{{ orderData$.value?.address ?? '-' }}</div>
				</div>
				<div *ngIf="this.shopService.shopInfo.value?.productType === 'DIGITAL'" class="column">
					<div class="label">Профиль в VK</div>
					<div class="value">{{ orderData$.value?.customerResponse['vkId'] ?? '-' }}</div>
				</div>
				<div class="column">
					<div class="label">{{ t('order_history.comment') }}</div>
					<div class="value">{{ orderData$.value?.comment ?? '-' }}</div>
				</div>
			</div>
		</div>

		<div *ngIf="infoTabActive && !orderData$.value?.customerResponse">Нет информации о покупателе</div>
	</ng-container>
</ng-container>
