import { Injectable } from '@angular/core';
import { ProductImportShortResponse } from '@models/generated/api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse } from '@models/pagination.model';
import { WithPagingParams } from '@models/paging.interface';

@Injectable({
	providedIn: 'root',
})
export class ImportService {
	constructor(private http: HttpClient) {}

	getImports(shopId: string, params: WithPagingParams): Observable<PaginatedResponse<ProductImportShortResponse[]>> {
		//@ts-ignore
		return this.http.get<PaginatedResponse<ProductImportShortResponse[]>>(`shop/${shopId}/imports`, { params });
	}

	getImportDetail(shopId: string, importId: string): Observable<ProductImportShortResponse[]> {
		return this.http.get<ProductImportShortResponse[]>(`shop/${shopId}/imports/${importId}`);
	}

	uploadImportFile(shopId: string, file: File): Observable<ProductImportShortResponse[]> {
		let fd = new FormData();
		fd.append('file', file);
		fd.append('fileType', 'FEED_FILE');

		return this.http.post<ProductImportShortResponse[]>(`shop/${shopId}/imports`, fd);
	}
}
