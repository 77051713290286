/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Данные для подключения к Atol
 */
export type AtolInfoRequest = {
	/**
	 * Логин
	 */
	login: string;
	/**
	 * Пароль
	 */
	pass: string;
	/**
	 * код ККТ
	 */
	groupCode: string;
	/**
	 * Место расчетов
	 */
	companyEmail: string;
	/**
	 * Система налогообложения компании
	 */
	companySno: AtolInfoRequest.companySno;
	/**
	 * ИНН компании
	 */
	companyInn: string;
	/**
	 * Место расчетов
	 */
	companyPaymentAddress: string;
};

export namespace AtolInfoRequest {
	/**
	 * Система налогообложения компании
	 */
	export enum companySno {
		OSN = 'OSN',
		USN_INCOME = 'USN_INCOME',
		USN_INCOME_OUTCOME = 'USN_INCOME_OUTCOME',
		ENVD = 'ENVD',
		ESN = 'ESN',
		PATENT = 'PATENT',
	}
}
