import { Injectable } from '@angular/core';
import { OrderResponse, OrderShortInfoResponse } from '@models/generated/api';
import { WithPagingParams } from '@models/paging.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class OrdersService {
	constructor(private http: HttpClient) {}

	getOrders(shopId: string, params: WithPagingParams): Observable<OrderShortInfoResponse[]> {
		return this.http.get<OrderShortInfoResponse[]>(`shops/${shopId}/orders`, { params: { ...params } });
	}

	getOrder(shopId: string, orderId: string): Observable<OrderResponse> {
		return this.http.get<OrderResponse>(`shops/${shopId}/orders/${orderId}`);
	}

	getOrderHistory(shopId: string) {
		return this.http.get(`shops/${shopId}/orders/export`, { responseType: 'blob' as 'text' });
	}
}
