import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { SubscribeHistoryComponent } from '@components/modals/subscribe-history/subscribe-history.component';
import { SubscriptionService } from '@services/subscription.service';
import { SubscriptionResponse } from '@models/generated/api';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tg-home',
	templateUrl: './subscriptions.component.html',
	styleUrls: ['./subscriptions.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsComponent {
	subscriptionData: SubscriptionResponse[] = [];

	constructor(
		private dialog: Dialog,
		private subscriptionService: SubscriptionService,
		private cdr: ChangeDetectorRef,
		private toast: ToastService,
	) {
		this.refresh();
	}

	refresh(): void {
		this.subscriptionService.getActiveSubscriptions().subscribe({
			next: res => {
				this.subscriptionData = res;
				this.cdr.markForCheck();
			},
			error: err => {
				console.log('err');
			},
		});
		this.cdr.markForCheck();
	}

	openHistory(): void {
		this.dialog.open(SubscribeHistoryComponent);
	}

	cancelSubscription(id: string) {
		return this.subscriptionService.cancelSubscription(id).subscribe({
			next: () => {
				this.toast.success('Подписка отменена');
				this.refresh();
			},
			error: err => {
				this.toast.error(err?.error?.message);
			},
		});
	}
}
