import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	ColorResponse,
	ProductCreateRequest,
	ProductResponse,
	SizeResponse,
	SizeTypeResponse,
} from '@models/generated/api';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	constructor(private http: HttpClient) {}

	getProductTypes(): Observable<SizeTypeResponse[]> {
		return this.http.get<SizeTypeResponse[]>('products/types');
	}

	getTypeSizes(typeId: string): Observable<SizeResponse[]> {
		return this.http.get<SizeResponse[]>('products/types/' + typeId + '/sizes');
	}

	getColors(): Observable<ColorResponse[]> {
		return this.http.get<ColorResponse[]>('products/colors');
	}

	createProduct(data: ProductCreateRequest, shopId: string): Observable<ProductResponse> {
		return this.http.post<ProductResponse>(`shops/${shopId}/products`, data);
	}

	editProduct(data: ProductCreateRequest, shopId: string, productId: string): Observable<ProductResponse> {
		return this.http.put<ProductResponse>(`shops/${shopId}/products/${productId}`, data);
	}

	deleteProduct(shopId: string, productId: string): Observable<ProductResponse> {
		return this.http.delete<ProductResponse>(`shops/${shopId}/products/${productId}`);
	}

	deleteProducts(shopId: string, productList: Array<string>): Observable<any> {
		return this.http.delete<any>(`shops/${shopId}/products`, {
			body: productList,
		});
	}

	getProductData(shopId: string, productId: string): Observable<ProductResponse> {
		return this.http.get<ProductResponse>(`shops/${shopId}/products/${productId}`);
	}

	changeProductVisibility(shopId: string, productId: string): Observable<boolean> {
		return this.http.put<boolean>(`shops/${shopId}/products/${productId}/visible-switch`, {});
	}

	changeProductListVisibility(
		shopId: string,
		data: { isVisible: boolean; productIds: string[] },
	): Observable<boolean> {
		return this.http.put<boolean>(`shops/${shopId}/products/visible-switch`, data);
	}
}
