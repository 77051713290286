import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateTime, Interval } from 'luxon';
import { ShopService } from '@services/shop.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, debounceTime, Subject } from 'rxjs';
import { LoadingService } from '@services/loading.service';
import { distinctUntilChanged } from 'rxjs/operators';

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

@Component({
	selector: 'tg-analytics',
	templateUrl: './analytics.component.html',
	styleUrls: ['./analytics.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent {
	@ViewChild('date') public dateRef?: ElementRef;
	form!: UntypedFormGroup;
	data$ = new BehaviorSubject<any>(null);
	periodData$ = new BehaviorSubject<any>(null);
	formData: Subject<{ dateTo: string; dateFrom: string }> = new Subject<{ dateTo: string; dateFrom: string }>();

	constructor(
		private fb: UntypedFormBuilder,
		public shopService: ShopService,
		private route: ActivatedRoute,
		public loading$: LoadingService,
	) {
		const now = DateTime.now();
		const defaultEndDate = now.toFormat(ISO_DATE_FORMAT);
		const defaultStartDate = now.minus({ months: 1 }).toFormat(ISO_DATE_FORMAT);
		this.form = this.fb.group({
			dateFrom: [defaultStartDate],
			dateTo: [defaultEndDate],
		});

		const shopId = this.route.parent.snapshot.paramMap.get('id');
		this.shopService.getMetrics(shopId).subscribe({
			next: data => {
				this.data$.next(data);

				this.loading$.next(true);
				this.shopService.getPeriodMetrics(shopId, this.form.value).subscribe({
					next: data => {
						this.periodData$.next(data);
						this.loading$.next(false);
					},
				});
			},
		});

		this.form.valueChanges.subscribe({
			next: data => this.onFormChange(data),
		});

		if (!shopService.shopMoneySign.value) {
			this.shopService.getShop(shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
				},
			});
		}
	}

	onFormChange(data) {
		const shopId = this.route.parent.snapshot.paramMap.get('id');
		if (this.formData.observers.length === 0) {
			this.formData.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
				if (this.checkMonthValidSize(6)) {
					return;
				}

				this.loading$.next(true);
				this.shopService.getPeriodMetrics(shopId, this.form.value).subscribe({
					next: data => {
						this.periodData$.next(data);
						this.loading$.next(false);
					},
				});
			});
		}
		this.formData.next(data);
	}

	checkMonthValidSize(number: number) {
		if (
			Interval.fromDateTimes(
				DateTime.fromISO(this.form.value['dateTo']),
				DateTime.fromISO(this.form.value['dateFrom']),
			).length('months') > 0
		) {
			return false;
		}

		return (
			Interval.fromDateTimes(
				DateTime.fromISO(this.form.value['dateFrom']),
				DateTime.fromISO(this.form.value['dateTo']),
			).length('months') > number
		);
	}
}
