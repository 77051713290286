import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EmailValidators, errors, isValidINN, noWhitespaceValidator } from '@utils/validations';
import { ShopService } from '@services/shop.service';
import { ToastService } from '@services/toast.service';
import { map, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'tg-payment-atol',
	templateUrl: './payment-atol.component.html',
	styleUrls: ['./payment-atol.component.sass', '../shop-settings-payment.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentAtolComponent {
	shopId: string;
	update: boolean = false;
	snoTypes = [
		{
			name: 'Общая СН',
			value: 'OSN',
		},
		{
			name: 'Упрощенная СН (доходы)',
			value: 'USN_INCOME',
		},
		{
			name: 'Упрощенная СН (доходы минус расходы)',
			value: 'USN_INCOME_OUTCOME',
		},
		{
			name: 'Единый налог на вмененный доход',
			value: 'ENVD',
		},
		{
			name: 'Единый сельскохозяйственный налог',
			value: 'ESN',
		},
		{
			name: 'Патентная СН',
			value: 'PATENT',
		},
	];

	form = new FormGroup({
		login: new FormControl('', [Validators.required, noWhitespaceValidator, Validators.maxLength(100)]),
		pass: new FormControl('', [Validators.required, noWhitespaceValidator, Validators.maxLength(100)]),
		groupCode: new FormControl('', [Validators.required, noWhitespaceValidator, Validators.maxLength(256)]),
		companyEmail: new FormControl('', [Validators.required, Validators.maxLength(256), ...EmailValidators]),
		companySno: new FormControl('', [Validators.required]),
		companyInn: new FormControl('', [
			Validators.required,
			noWhitespaceValidator,
			isValidINN,
			Validators.maxLength(256),
		]),
		companyPaymentAddress: new FormControl('', [
			Validators.required,
			noWhitespaceValidator,
			Validators.maxLength(256),
		]),
	});

	constructor(private route: ActivatedRoute, private shopService: ShopService, private toast: ToastService) {
		this.route.parent.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
			});
	}

	refresh() {
		this.shopService.getShopOptionsAtol(this.shopId).subscribe({
			next: res => {
				this.update = !!res;
				res && this.form.patchValue(res);
			},
		});
	}

	submit() {
		this.form.markAllAsTouched();

		if (this.form.invalid) {
			return;
		}
		if (this.update) {
			this.shopService.updateShopOptionsAtol(this.shopId, this.form.value as any).subscribe({
				next: res => {
					this.toast.success('Данные успешно сохранены');
				},
				error: err => {
					switch (err.status) {
						case 400:
							this.toast.error(
								err.error?.exceptionName === 'AdminBadRequestException'
									? 'Неверный логин или пароль'
									: err.error?.message,
							);
							break;
						case 404:
							this.toast.error('Что-то пошло не так');
							break;
						default:
							this.toast.error(err.error?.message);
					}
				},
			});
		} else {
			this.shopService.postShopOptionsAtol(this.shopId, this.form.value as any).subscribe({
				next: () => {
					this.update = true;
					this.toast.success('Данные успешно сохранены');
				},
				error: err => {
					switch (err.status) {
						case 400:
							this.toast.error(
								err.error?.exceptionName === 'AdminBadRequestException'
									? 'Неверный логин или пароль'
									: err.error?.message,
							);
							break;
						case 404:
							this.toast.error('Что-то пошло не так');
							break;
						default:
							this.toast.error(err.error?.message);
					}
				},
			});
		}
	}

	errors(form: UntypedFormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}
}
