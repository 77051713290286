import { Directive, Injector, Input, TemplateRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@services/destroy.service';
import { AdjustiveViewDirective } from '@directives/adjustive-view.directive';
import { breakpoints } from '@app/constants/breakpoints';
import { AdaptiveService } from '@services/adaptive-service.service';

type ConditionType = 'xs' | 'sm' | 'md' | 'xsm' | 'xmd' | 'lg' | 'xls' | 'xl' | string;

@Directive({
	standalone: true,
	selector: '[tkAdaptive]',
	providers: [DestroyService],
})
export class AdaptiveDirective extends AdjustiveViewDirective {
	private width: number = 0;

	constructor(
		private adaptiveService: AdaptiveService,
		private destroy$: DestroyService,
		protected override injector: Injector,
	) {
		super(injector);
		this.adaptiveService.onResize$.pipe(takeUntil(this.destroy$)).subscribe(w => {
			this.width = w;
			this.checkCondition();
		});
	}

	@Input()
	set tkAdaptive(condition: ConditionType) {
		this.setTkAdjustiveView(condition);
	}

	@Input()
	set tkAdaptiveElse(template: TemplateRef<unknown> | undefined) {
		this.setTkAdjustiveViewElse(template);
	}

	protected override checkCondition() {
		super.checkCondition();

		const dirtyValue = this.dirtyViewValue;
		const dirtyNum = +dirtyValue;
		const breakpoint = isNaN(dirtyNum) ? breakpoints[dirtyValue] : null;
		const condition = this.condition;

		let requiredState: number | undefined;

		switch (condition) {
			case '>':
				requiredState = breakpoint ? breakpoint['to'] : dirtyNum;
				break;
			case '>=':
			case '<':
				requiredState = breakpoint ? breakpoint['from'] : dirtyNum;
				break;
			case '<=':
				requiredState = breakpoint ? breakpoint?.['to'] ?? breakpoint['from'] : dirtyNum;
				break;
		}

		if (condition === '=') {
			if (breakpoint) {
				this.showing = breakpoint['from'] <= this.width && this.width <= breakpoint['to'];
			} else {
				this.showing = this.width === dirtyNum;
			}
		} else {
			this.compareValuesWithCondition(condition, this.width, requiredState);
		}

		this._updateView();
	}
}
