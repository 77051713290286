import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { breakpoints } from '@app/constants/breakpoints';

@Injectable({
	providedIn: 'root',
})
export class AdaptiveService implements OnDestroy {
	map: any;
	observables: any;
	private resizeSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);
	private destroy$ = new Subject();
	private readonly subjects: any;

	constructor(private eventManager: EventManager, @Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformBrowser(this.platformId)) {
			this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
		}

		this.subjects = {};
		this.observables = {};
		this.map = {};

		Object.keys(breakpoints).forEach(i => {
			this.subjects[i] = new BehaviorSubject(false);
			this.observables[i] = this.subjects[i].asObservable().pipe(distinctUntilChanged());
			this.map[i] = false;
		});

		this.onResize$.pipe(takeUntil(this.destroy$)).subscribe(w => this.calculate(w));
		this.resizeSubject.next(null);
	}

	get onResize$(): Observable<number> {
		return this.resizeSubject.pipe(
			map(() => {
				if (isPlatformServer(this.platformId)) {
					return 1920;
				}

				return window.innerWidth;
			}),
		);
	}

	ngOnDestroy(): void {
		// @ts-ignore
		this.destroy$.next();
		this.destroy$.complete();
	}

	private calculate(w: number) {
		Object.keys(breakpoints).forEach(i => {
			const breakpoint = breakpoints[i];
			if (breakpoint['from'] && !breakpoint['to']) {
				this.map[i] = breakpoint['from'] <= w;
			} else if (!breakpoint['from'] && breakpoint['to']) {
				this.map[i] = w <= breakpoint['to'];
			} else if (breakpoint['from'] && breakpoint['to']) {
				this.map[i] = breakpoint['from'] <= w && w <= breakpoint['to'];
			}

			this.subjects[i].next(this.map[i]);
		});
	}

	private onResize() {
		this.resizeSubject.next(null);
	}
}
