<ng-container *ngFor="let category of categories">
	<tk-option
		[value]="category.categoryId"
		(click)="selectCategory(category)"
		[class.selected]="category.categoryId === selectedCategoryId"
	>
		<div class="option-content" [style.padding-left.px]="level * 24">
			<div class="radio-button">
				<div class="radio-outer" [class.selected]="category.categoryId === selectedCategoryId">
					<div class="radio-inner"></div>
				</div>
			</div>
			<span>{{ category.categoryName }}</span>
		</div>
	</tk-option>
	<tg-product-select-category
		*ngIf="category.children?.length"
		[categories]="category.children"
		[level]="level + 1"
		[selectedCategoryId]="selectedCategoryId"
		(categorySelected)="onChildCategorySelected($event)"
	></tg-product-select-category>
</ng-container>
