<div class="actions">
	<div></div>

	<tk-button-group>
		<a (click)="clickCreateBtn()" tkButton theme="secondary" size="xl">
			<tk-icon icon="plus"></tk-icon>
			Создать рассылку
		</a>
	</tk-button-group>
</div>

<div class="table-wrap">
	<cdk-table class="table" [dataSource]="(newsletterList$ | async).content">
		<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

		<ng-container cdkColumnDef="name">
			<cdk-header-cell *cdkHeaderCellDef> Название </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				{{ row.name }}
			</cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="createdAt">
			<cdk-header-cell *cdkHeaderCellDef> Количество получателей </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row"> {{ row.actualSent ?? '-' }}/{{ row.totalCount ?? '-' }} </cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="scheduledDatetime">
			<cdk-header-cell *cdkHeaderCellDef> Дата и время отправки </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				{{ row.scheduledDatetime | date : 'dd.MM.yyyy HH:mm' }}
			</cdk-cell>
		</ng-container>

		<ng-container cdkColumnDef="status">
			<cdk-header-cell *cdkHeaderCellDef> Статус </cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				<ng-container [ngSwitch]="row.status">
					<span class="green" *ngSwitchCase="'SENT'">Отправлена</span>
					<span class="yellow" *ngSwitchCase="'PLANNED'">Запланирована</span>
					<span class="red" *ngSwitchCase="'CANCELLED'">Отменена</span>
				</ng-container>
			</cdk-cell>
		</ng-container>
		<ng-container cdkColumnDef="actions">
			<cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>

			<cdk-cell *cdkCellDef="let row">
				<button
					[class.hidden]="row.status === 'SENT'"
					class="options"
					cdkOverlayOrigin
					#origin
					(click)="openMenu(row.id, $event)"
				>
					<tk-icon icon="options-vertical"></tk-icon>
				</button>

				<ng-template
					cdkConnectedOverlay
					cdkConnectedOverlayHasBackdrop
					[cdkConnectedOverlayOrigin]="origin"
					[cdkConnectedOverlayOpen]="menuIsOpen === row.id"
					(backdropClick)="closeMenu()"
					cdkConnectedOverlayBackdropClass="backdrop--transparent"
					[cdkConnectedOverlayPositions]="positions"
				>
					<div class="panel">
						<button (click)="deleteNewsletter(row.id)">Удалить</button>
					</div>
				</ng-template>
			</cdk-cell>
		</ng-container>

		<cdk-row *cdkRowDef="let row; columns: columns" class="cdk-row-hover"></cdk-row>
	</cdk-table>
</div>

<tk-paging-controls
	[pages]="pages(newsletterList$ | async)"
	[page]="currentSettings.page ?? 0"
	(amountChange)="setAmount($event)"
	(pageChange)="changePage($event)"
></tk-paging-controls>
