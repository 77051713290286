<tg-nav-panel></tg-nav-panel>
<div class="page-container__main">
	<div
		*ngIf="showTrialBanner"
		class="subscription-status"
		[ngClass]="{ expired: isExpired }"
		(click)="isExpired && navigateToPlans()"
	>
		<ng-container *ngIf="!isExpired">
			Осталось {{ counter }} дней бесплатного доступа

			<tk-button routerLink="/tarifs" [queryParams]="shopService.utmQuery.value" size="s" theme="primary"
				>Оформить подписку</tk-button
			>
		</ng-container>

		<ng-container *ngIf="isExpired">
			Пробный период закончился. Оформите подписку и откройте доступ 🎉

			<tk-button routerLink="/tarifs" [queryParams]="shopService.utmQuery.value" size="s" theme="primary"
				>Оформить подписку</tk-button
			>
		</ng-container>
	</div>
	<ng-content></ng-content>
</div>
