import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
	name: 'search',
})
export class SearchPipe implements PipeTransform {
	transform(arr: Array<any>, field: string | Array<string>, search: string): Array<any> {
		if (!arr) {
			return null;
		}
		if (!search) {
			return arr;
		}

		const searchable = typeof field === 'string' ? [field] : field;

		return arr.filter(i => {
			return searchable.some(f => get(i, f, '').toLocaleLowerCase().includes(search.toLocaleLowerCase()));
		});
	}
}
