<ng-container *transloco="let t">
	<form class="form" [formGroup]="form" (submit)="submitForm()">
		<h2 class="form__title">{{ t('auth.sign_in_text') }}</h2>
		<tk-form-field>
			<tk-label>{{ t('auth.email') }}</tk-label>

			<input formControlName="email" tkInput />
			<tk-error *ngIf="errors(form, 'email') as errorField" tkEmailError [errors]="errorField"></tk-error>
		</tk-form-field>

		<tk-form-field>
			<tk-label>{{ t('auth.pass') }}</tk-label>

			<input
				#password
				type="password"
				formControlName="password"
				tkInput
				tkInputTrim
				autocomplete="off"
				aria-labelledby="password"
			/>
			<tk-eye [input]="password" tkSuffix data-test-id="btn-password-eye"></tk-eye>
			<tk-error *ngIf="errors(form, 'password') as errorField" tkPasswordError [errors]="errorField"></tk-error>
		</tk-form-field>

		<div class="reset-pass">
			<a routerLink="/forgot-pass">{{ t('auth.forgot_pass') }}</a>
		</div>

		<tk-button-group class="tk-button-group">
			<button
				*ngIf="!(loading$ | async)"
				tkButton
				size="xl"
				theme="primary"
				type="submit"
				[disabled]="isDisabled"
			>
				{{ t('auth.sign_in') }}
			</button>
			<ng-container *ngIf="loading$ | async">
				<ng-container *ngTemplateOutlet="loader"></ng-container>
			</ng-container>
		</tk-button-group>

		<div class="privacy">
			{{ t('global.agree.agree_1') }}
			<a href="/assets/TGShop_Политика_конфиденциальности_27_07_23.docx">
				{{ t('global.agree.agree_2') }}
			</a>
			{{ t('global.agree.agree_3') }}
		</div>
		<div class="sign-in">
			{{ t('auth.new_text') }} <a routerLink="/sign-up">{{ t('auth.new_href') }}</a>
		</div>
	</form>

	<ng-template #loader>
		<tk-loader width="FULL"></tk-loader>
	</ng-template>
</ng-container>
