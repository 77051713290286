/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма для фильтрации подсказок адресов
 */
export type AddressFilterRequest = {
	/**
	 * Строка для поиска адреса
	 */
	query: string;
	/**
	 * Нижняя граница поиска адреса
	 */
	level?: AddressFilterRequest.level;
	/**
	 * Идентификатор города ФИАС
	 */
	fiasId?: string;
};

export namespace AddressFilterRequest {
	/**
	 * Нижняя граница поиска адреса
	 */
	export enum level {
		CITY = 'CITY',
	}
}
