import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SubscriptionService } from '@services/subscription.service';
import { BehaviorSubject } from 'rxjs';
import { PaginatedResponse, PaginationModel } from '@models/pagination.model';
import { SubscriptionPaymentHistoryResponse } from '@models/generated/api';

@Component({
	selector: 'tg-start-video',
	templateUrl: './subscribe-history.component.html',
	styleUrls: ['./subscribe-history.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeHistoryComponent extends PaginationModel {
	columns = ['subscriptionName', 'paymentDate', 'paymentSum', 'paymentStatus'];
	pos$ = new BehaviorSubject<PaginatedResponse<SubscriptionPaymentHistoryResponse[]>>({});

	paymentStatusName = {
		'NEW': 'Новая',
		'DONE': 'Прошла',
		'EXPIRED': 'Закончилась',
		'FAILED': 'Не прошла',
		'AWAIT_CONFIRM': 'Ожидает подтверждения',
		'CONFIRM_FAILED': 'Нет подтверждения оплаты',
	};

	constructor(private subscriptionService: SubscriptionService) {
		super();

		this.refresh();
	}

	override changePage(page: number): void {
		super.changePage(page);
		this.refresh();
	}

	refresh() {
		this.subscriptionService.getPaymentsHistory(this.currentSettings).subscribe({
			next: res => {
				this.pos$.next(res);
			},
		});
	}
}
