import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from '@models/breadcrumb.model';

@Injectable({
	providedIn: 'root',
})
export class BreadcrumbService implements OnDestroy {
	private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
	readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

	private destroy$ = new Subject();

	constructor(private router: Router) {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.pipe(takeUntil(this.destroy$))
			.subscribe(event => {
				const root = this.router.routerState.snapshot.root;
				let breadcrumbs: Breadcrumb[] = [];
				this.addBreadcrumb(root, [], breadcrumbs);

				breadcrumbs = breadcrumbs.filter(
					(value, index, self) =>
						index === self.findIndex(t => t.label === value.label && t.url === value.url),
				);
				this._breadcrumbs$.next(breadcrumbs);
			});
	}

	private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
		if (route) {
			const routeUrl = parentUrl.concat(route.url.map(url => url.path));

			if (route.data['breadcrumb']) {
				const breadcrumb = {
					label: this.getLabel(route.data),
					url: '/' + routeUrl.join('/'),
				};
				breadcrumbs.push(breadcrumb);
			}

			if (route.data['breadcrumb_tree']) {
				route.data['breadcrumb_tree'].map((breadcrumb: any) => {
					const bc = {
						label: this.getLabel({ breadcrumb: breadcrumb }),
						url: '/' + routeUrl.join('/'),
					};
					breadcrumbs.push(bc);
				});
			}

			this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
		}
	}

	private getLabel(data: any) {
		return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
