<ng-container *transloco="let t; read: 'main'">
	<div class="header">
		<div>
			<tg-page-title>{{ t('stores') }}</tg-page-title>
		</div>

		<div>
			<button class="info" tkButton theme="dashed" (click)="isSubscriptionActive && openGuide()">
				<tk-icon icon="info-clear"></tk-icon>
				Как создать магазин
			</button>
			<button tkButton theme="secondary" (click)="isSubscriptionActive && createShop()">
				<tk-icon icon="plus"></tk-icon>
				{{ t('create_btn') }}
			</button>
		</div>
	</div>

	<ng-container>
		<div class="content" *ngIf="!(loading$ | async)" [class.content--empty]="(shopList?.totalElements ?? 0) < 1">
			<ng-container *ngIf="shopList?.totalElements < 1">
				<div class="empty">
					<img src="/assets/images/cart-with-basket.png" alt="" />

					<h4>Магазины еще не&nbsp;созданы</h4>
					<p>
						Для создания нового магазина нажмите кнопку &laquo;Создать магазин&raquo; и&nbsp;заполните
						данные
					</p>
				</div>
			</ng-container>
			<ng-container *ngIf="shopList?.totalElements > 0">
				<div class="shop__list">
					<tg-shop-card
						*ngFor="let item of shopList?.content"
						[shop]="item"
						(onDelete)="isSubscriptionActive && deleteShop(item)"
						(onEdit)="isSubscriptionActive && editShop(item)"
					></tg-shop-card>
				</div>
			</ng-container>
		</div>

		<div class="content" *ngIf="loading$ | async">Загрузка...</div>
	</ng-container>
</ng-container>
