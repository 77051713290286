<ng-container *transloco="let t">
	<tg-dialog-header>Выберите склад</tg-dialog-header>

	<tg-dialog-body>
		<form [formGroup]="form">
			<ng-container *ngIf="step === 0">
				<h3>Укажите город</h3>

				<tk-form-field tkAutocompleteOrigin>
					<tk-label>Город</tk-label>
					<input
						class="input"
						(ngModelChange)="onChangeSearch()"
						autocomplete="off"
						type="text"
						tkInput
						formControlName="city"
						[tkAutocomplete]="autocomplete"
					/>
					<tk-error *ngIf="address.hasError('fias_id')">Выберите адрес из списка</tk-error>
					<tk-autocomplete #autocomplete="tkAutocomplete">
						<tk-option *ngFor="let item of fiasList" [value]="item.address" (click)="selectEvent(item)">{{
							item.address
						}}</tk-option>
					</tk-autocomplete>
				</tk-form-field>
			</ng-container>

			<ng-container *ngIf="step === 1">
				<div class="container">
					<div class="points">
						<ul [style.height.px]="selectedPoint.value ? 576 : 640">
							<li
								(click)="selectPoint(point)"
								class="points__item"
								*ngFor="let point of pointsList$ | async"
								[id]="point.pickPointId"
							>
								<tk-checkbox
									[ngModel]="selectedPoint.value?.pickPointId === point.pickPointId"
									disabled
									[ngModelOptions]="{ standalone: true }"
								></tk-checkbox>
								<div class="wrap">
									<div class="name">{{ point.name }}</div>
									<div class="address">{{ point.address }}</div>
									<div class="time" *ngIf="point.timetable">{{ point.timetable.join(' ') }}</div>
								</div>
							</li>
						</ul>
						<button type="button" *ngIf="selectedPoint.value" class="submit" (click)="submit()">
							Выбрать
						</button>
					</div>
					<div class="map">
						<ya-map [center]="[55.751952, 37.600739]" [zoom]="12" (ready)="onMapReady($event)">
							<ya-placemark
								*ngFor="let point of mapPoints"
								[geometry]="point.geometry!"
								[properties]="point.properties!"
								[options]="point.options!"
							></ya-placemark>
						</ya-map>
					</div>
				</div>
			</ng-container>
		</form>

		<tk-button-group *ngIf="step !== 1">
			<button tkButton theme="primary" (click)="next()">Далее</button>
		</tk-button-group>
	</tg-dialog-body>
</ng-container>
