import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorage, storageService } from '@utils/local-storage';
import {
	NewPasswordRequest,
	RecoveryPasswordRequest,
	SignInRequest,
	SignUpRequest,
	TokenCoupleDto,
	TokenCoupleResponse,
} from '@models/generated/api';
import { TranslocoService } from '@ngneat/transloco';
import * as jwt_decode from 'jwt-decode';

export type NullableString = string | undefined | null;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	@LocalStorage() token: NullableString;
	@LocalStorage() refreshToken: NullableString;
	@LocalStorage() expTime: NullableString;
	@LocalStorage() lastUrl: string | undefined;

	constructor(private http: HttpClient, private translocoService: TranslocoService) {}

	signUp(data: SignUpRequest, params?: string) {
		let headers = new HttpHeaders();
		headers = headers.append('Accept-Language', this.translocoService.getActiveLang());
		if (params && params !== '{}') {
			headers = headers.append('Utm-Query', params);
		}

		return this.http.post('admin/sign-up', data, {
			headers,
		});
	}

	signIn(data: SignInRequest) {
		return this.http.post('admin/sign-in', data);
	}

	refresh(data: TokenCoupleDto) {
		return this.http.post('admin/token/refresh', data);
	}

	confirm(code: string) {
		return this.http.post('admin/confirm/' + code, code);
	}

	passwordRecovery(data: RecoveryPasswordRequest) {
		return this.http.post('admin/password/recovery', data, {
			headers: {
				'Accept-Language': this.translocoService.getActiveLang(),
			},
		});
	}

	passwordReset(data: NewPasswordRequest) {
		return this.http.put('admin/password', data);
	}

	authorize(data: TokenCoupleResponse): void {
		this.token = data.accessToken;
		this.refreshToken = data.refreshToken;
		this.expTime = data.accessTokenExpirationDate;
	}

	subscriptionInfo(): { status: boolean; counter: number; isTrial: boolean } {
		if (!this.token) {
			return null;
		}
		const userData = jwt_decode.default(this.token?.split(' ')[1]);

		if (userData['END_DATE'] === null) {
			return {
				status: false,
				counter: 0,
				isTrial: false,
			};
		}

		const [date, time] = userData['END_DATE'].split(' ');
		const [day, month, year] = date.split('.');
		const [hour, minute] = time.split(':');

		const expDate = new Date(year, month - 1, day, hour, minute, 0);
		const diffInDays = +((+expDate - +new Date()) / (1000 * 60 * 60 * 24)).toFixed();

		return {
			status: !(diffInDays < 0),
			counter: diffInDays,
			isTrial: userData['IS_TRIAL'],
		};
	}

	getUserEmail(): string | null {
		if (!this.token) {
			return null;
		}
		const userData = jwt_decode.default(this.token?.split(' ')[1]);

		return userData['sub'] ?? null;
	}

	logout(): void {
		storageService.clearStorageByKey('token');
		storageService.clearStorageByKey('user');
	}
}
