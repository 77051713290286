/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OrderItemResponse } from './OrderItemResponse';

/**
 * Оформленный заказ
 */
export type OrderCreatedResponse = {
	/**
	 * Идентификатор заказа
	 */
	id?: string;
	/**
	 * Номер заказа
	 */
	orderNumber?: string;
	/**
	 * Ссылка на оплату (оплата через телеграм)
	 */
	paymentLink?: string;
	/**
	 * Тип платежа
	 */
	paymentType?: OrderCreatedResponse.paymentType;
	/**
	 * Признак заказа без оплаты
	 */
	isWithoutPayment?: boolean;
	/**
	 * Статус заказа
	 */
	orderProcessingState?: OrderCreatedResponse.orderProcessingState;
	/**
	 * Статус оплаты
	 */
	paymentState?: OrderCreatedResponse.paymentState;
	/**
	 * Товары в заказе
	 */
	items?: Array<OrderItemResponse>;
	/**
	 * Стоимость доставки
	 */
	deliveryCost?: number;
	/**
	 * Цена до вычета всех скидок
	 */
	price?: number;
	/**
	 * Цена после вычета всех скидок
	 */
	realPrice?: number;
	/**
	 * Скидка
	 */
	discount?: number;
};

export namespace OrderCreatedResponse {
	/**
	 * Тип платежа
	 */
	export enum paymentType {
		NONE = 'NONE',
		TELEGRAM = 'TELEGRAM',
		TINKOFF = 'TINKOFF',
		CUSTOM = 'CUSTOM',
	}

	/**
	 * Статус заказа
	 */
	export enum orderProcessingState {
		CREATED = 'CREATED',
		SENT = 'SENT',
		DELIVERED = 'DELIVERED',
		CANCELED = 'CANCELED',
	}

	/**
	 * Статус оплаты
	 */
	export enum paymentState {
		NOT_PAID = 'NOT_PAID',
		PAID = 'PAID',
		FAILED = 'FAILED',
		ISSUED = 'ISSUED',
	}
}
