import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderShortInfoResponse, ShopResponse } from '@models/generated/api';
import { FormControl } from '@angular/forms';
import { ShopService } from '@services/shop.service';
import { ToastService } from '@services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedResponse, PaginationModel } from '@models/pagination.model';
import { LoadingService } from '@services/loading.service';
import { PaginationService } from '@services/pagination.service';
import { OrdersService } from '@services/orders.service';
import productType = ShopResponse.productType;
import { environment } from '@environments/environment';

@Component({
	selector: 'tg-order-history',
	templateUrl: './order-history.component.html',
	styleUrls: ['./order-history.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderHistoryComponent extends PaginationModel {
	columns;

	orderList$ = new BehaviorSubject<PaginatedResponse<OrderShortInfoResponse[]>>({});
	shopId: string | null = null;
	searchControl = new FormControl();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public shopService: ShopService,
		private toast: ToastService,
		public loading$: LoadingService,
		private pagination: PaginationService,
		public ordersService: OrdersService,
	) {
		super();

		this.shopId = this.route.parent.snapshot.paramMap.get('id');

		if (
			this.pagination.currentOrderPage.value !== this.currentSettings.page &&
			this.pagination.currentShopId.value === this.shopId
		) {
			this.currentSettings.page = this.pagination.currentOrderPage.value;
		}

		if (!shopService.shopMoneySign.value) {
			this.shopService.getShop(this.shopId).subscribe({
				next: res => {
					this.shopService.setMoneySign(res);
					this.shopService.shopInfo.next(res);
					this.columns = ['isViewed', 'orderNumber', 'orderDate', 'cost', 'receiverName', 'paymentState'];
					if (res.productType === productType.GOODS) {
						this.columns.push('orderProcessingState');
					}
				},
			});
		} else {
			this.columns = ['isViewed', 'orderNumber', 'orderDate', 'cost', 'receiverName', 'paymentState'];
			if (this.shopService.shopInfo.value.productType === productType.GOODS) {
				this.columns.push('orderProcessingState');
			}
		}

		this.refresh();
	}

	refresh() {
		this.loading$.next(true);

		this.ordersService
			.getOrders(this.shopId, this.currentSettings)
			.subscribe({
				next: (res: any) => {
					this.orderList$.next(res);
					this.pagination.currentOrderPage.next(this.currentSettings.page);
					this.pagination.currentShopId.next(this.shopId);
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
			})
			.add(() => {
				this.loading$.next(false);
			});
	}

	openOrder(row) {
		this.router.navigate(['/shop/' + this.shopId + '/orders/' + row.orderId]);
	}

	override changePage(page: number): void {
		super.changePage(page);
		this.refresh();
	}

	getHistory() {
		// @ts-ignore
		ym(environment.metricId, 'reachGoal', 'orders_export');
		this.ordersService.getOrderHistory(this.shopId).subscribe({
			next: res => {
				const element = document.createElement('a');
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const url = URL.createObjectURL(blob);
				element.href = url;
				element.download = 'order-history.xlsx';

				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			},
			error: err => {
				console.log(err);
			},
		});
	}
}
