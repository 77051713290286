import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DadataService } from '@services/dadata.service';
import {
	AddressResponse,
	AddressSuggestionResponse,
	ColorResponse,
	DictionaryCodeValueResponse,
	ProductDimensionsTypeResponse,
} from '@models/generated/api';
import { dotValidator, errors, stepValidator } from '@app/utils/validations';
import { DictionariesService } from '@services/dictionaries.service';
import { BehaviorSubject, map, tap } from 'rxjs';
import { DeliverySettingsService } from '@services/delivery-setting.service';
import { ToastService } from '@services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash-es';
import { ProductSelectColorsComponent } from '@components/modals/product-select-colors/product-select-colors.component';
import { Dialog } from '@angular/cdk/dialog';
import { ProviderPointComponent } from '@components/modals/provider-point/provider-point.component';
import { AuthService } from '@services/auth.service';

@Component({
	selector: 'tg-shop-delivery-apiship',
	templateUrl: './shop-delivery-apiship.component.html',
	styleUrls: ['./shop-delivery-apiship.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopDeliveryApishipComponent {
	data = new BehaviorSubject(null);
	setPrevAddress: boolean = false;
	shopId: string | null = null;
	fiasList: AddressSuggestionResponse[] = [];
	fiasSelected: AddressSuggestionResponse | AddressResponse | null = null;

	dimensionList: ProductDimensionsTypeResponse[];
	transferList = new BehaviorSubject<DictionaryCodeValueResponse[]>([]);

	providers = [];

	apishipForm: FormGroup = new FormGroup({
		'token': new FormControl(null, [Validators.required, Validators.minLength(32), Validators.maxLength(32)]),
		'shopInfo': new FormGroup({
			'responsiblePerson': new FormControl(null, Validators.required),
			'contactPhone': new FormControl(null, Validators.required),
			'address': new FormControl(null, Validators.required),
		}),
		'sizeType': new FormControl(null, Validators.required),
		'defaultLength': new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.min(0),
			dotValidator,
		]),
		'defaultWidth': new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.min(0),
			dotValidator,
		]),
		'defaultHeight': new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.min(0),
			dotValidator,
		]),
		'defaultWeight': new FormControl(null, [
			Validators.required,
			Validators.maxLength(8),
			Validators.min(0),
			dotValidator,
		]),
		'apishipDeliveryServices': new FormArray([]),
	});

	constructor(
		private dadataService: DadataService,
		private cdr: ChangeDetectorRef,
		private dictionariesService: DictionariesService,
		private deliverySettingsService: DeliverySettingsService,
		private toast: ToastService,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private dialog: Dialog,
		private authService: AuthService,
	) {
		this.route.parent.params
			.pipe(
				map(d => d['id']),
				tap(id => (this.shopId = id)),
			)
			.subscribe(() => {
				this.refresh();
			});

		dictionariesService.getProductDimensions().subscribe({
			next: res => {
				this.dimensionList = res;
			},
		});

		dictionariesService.getProductTransfers().subscribe({
			next: res => {
				this.transferList.next(res);
			},
		});

		this.apishipForm.get('sizeType').valueChanges.subscribe(sizeType => {
			if (sizeType === 'Другое') {
				this.apishipForm.get('defaultLength').enable();
				this.apishipForm.get('defaultWidth').enable();
				this.apishipForm.get('defaultHeight').enable();
			} else {
				this.apishipForm.get('defaultLength').disable();
				this.apishipForm.get('defaultWidth').disable();
				this.apishipForm.get('defaultHeight').disable();
			}
		});

		this.apishipForm.get('token').valueChanges.subscribe(token => {
			const formProviders = this.apishipForm.controls['apishipDeliveryServices'] as FormArray;

			if (token.length !== 32) {
				formProviders.clear();
				this.providers = [];
				return;
			}

			this.deliverySettingsService.getApishipProviders(token, this.shopId).subscribe({
				next: res => {
					formProviders.clear();
					this.providers = res;
					this.providers.forEach(provider => {
						formProviders.push(
							this.fb.group({
								'providerKey': provider.providerKey,
								'productTransferType': provider.productTransferType ?? 'FROM_WAREHOUSE',
								'pickPointId': provider.pickPointId ?? null,
								'pickPointAddress': provider.pickPointAddress ?? null,
								'pickPointName': provider.pickPointName ?? null,
								'pickPointTime': provider.pickPointTime ?? null,
							}),
						);
					});
					this.cdr.markForCheck();
				},
				error: () => {
					formProviders.clear();
					this.providers = [];
				},
			});
		});
	}

	refresh = () => {
		this.deliverySettingsService.getApiship(this.shopId).subscribe({
			next: res => {
				this.data.next(res);
				this.setPrevAddress = true;
				this.apishipForm.patchValue({
					...res,
					shopInfo: {
						...res.shopInfo,
						address: res.shopInfo.address.addressShort,
					},
				});

				if (this.dimensionList) {
					this.apishipForm.patchValue({
						sizeType: this.checkDimensionType(this.dimensionList)
							? this.checkDimensionType(this.dimensionList).productDimensionType
							: 'Другое',
					});
				} else {
					this.dictionariesService.getProductDimensions().subscribe({
						next: list => {
							this.dimensionList = list;
							this.apishipForm.patchValue({
								sizeType: this.checkDimensionType(this.dimensionList)
									? this.checkDimensionType(this.dimensionList).productDimensionType
									: 'Другое',
							});
						},
					});
				}
			},
		});
	};

	getProviderFieldValue(index: number, fieldName: string) {
		return ((this.apishipForm.controls['apishipDeliveryServices'] as FormArray).controls[index] as FormGroup)
			?.controls[fieldName]?.value;
	}

	getProviderFieldName(providerKey: string): string {
		if (providerKey === 'rupost') {
			return 'Почта России';
		}

		if (providerKey === 'rutaxi') {
			return 'Я.Доставка';
		}

		if (providerKey === 'cdek') {
			return 'CДЭК';
		}

		return providerKey;
	}

	setProviderFieldValue(index: number, fieldName: string, newValue: string): void {
		((this.apishipForm.controls['apishipDeliveryServices'] as FormArray).controls[index] as FormGroup)?.controls[
			fieldName
		].setValue(newValue);
	}

	openPickupPointMap(city: string, providerKey: string, index: number) {
		this.dialog
			.open(ProviderPointComponent, {
				data: {
					city,
					providerKey,
					token: this.apishipForm.get('token').value,
				},
			})
			.closed.subscribe({
				next: (data: any) => {
					this.setProviderFieldValue(index, 'pickPointId', data.pickPointId);
					this.setProviderFieldValue(index, 'pickPointName', data.name);
					this.setProviderFieldValue(index, 'pickPointAddress', data.address);
					if (data.timetable) {
						this.setProviderFieldValue(index, 'pickPointTime', data.timetable.join(' '));
					}
					this.cdr.detectChanges();
				},
			});
	}

	checkDimensionType(list) {
		return (
			list.find(
				item =>
					item.length === this.data.value.defaultLength &&
					item.width === this.data.value.defaultWidth &&
					item.height === this.data.value.defaultHeight,
			) || null
		);
	}

	get address() {
		return this.apishipForm.get('shopInfo.address');
	}

	getDadata(s: string) {
		this.dadataService.getDadata(s).subscribe({
			next: res => {
				this.fiasList = res;
				this.cdr.markForCheck();
			},
		});
	}

	errors(form: FormGroup, field: string): ValidationErrors | null | undefined {
		return errors(form, field);
	}

	selectEvent(item) {
		this.fiasSelected = item;
		this.address.setErrors({ 'fias': null });
		this.address.updateValueAndValidity();
		this.cdr.markForCheck();
	}

	onChangeSearch() {
		if (this.address.value.length < 3) {
			this.fiasList = [];
			return;
		}
		if (this.setPrevAddress) {
			this.fiasSelected = this.data.value.shopInfo.address;
			this.setPrevAddress = false;
		} else {
			this.fiasSelected = null;
		}

		this.address.setErrors({ 'fias': null });
		this.address.updateValueAndValidity();
		this.cdr.markForCheck();
		this.getDadata(this.address.value);
	}

	save = () => {
		this.apishipForm.markAllAsTouched();
		if (!this.fiasSelected) {
			this.address.setErrors({ 'fias_id': true });
			return;
		}
		if (!(this.fiasSelected['isHouse'] || this.fiasSelected['house'])) {
			this.address.setErrors({ 'fias_house': true });
			return;
		}
		if (this.apishipForm.invalid) {
			return;
		}

		this.deliverySettingsService
			.setApiship(
				this.shopId,
				this.apishipForm.value,
				this.fiasSelected,
				this.dimensionList,
				this.fiasSelected['id'] ?? this.data.value?.shopInfo?.address?.id ?? null,
			)
			.subscribe({
				next: () => {
					this.toast.success('Настройки сохранены!');
					this.refresh();
					this.cdr.markForCheck();
				},
				error: err => {
					this.toast.error(err.error?.message);
					this.cdr.markForCheck();
				},
			});
	};
}
