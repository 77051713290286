<ng-container *transloco="let t">
	<tg-dialog-header>{{ data.point ? 'Редактирование' : 'Создание' }} пункта выдачи</tg-dialog-header>
	<tg-dialog-body>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<tk-form-field>
				<tk-label>{{ t('delivery.pickup_name') }}</tk-label>

				<input type="text" tkInput formControlName="pickUpPointName" />
				<tk-error *ngIf="errors(form, 'pickUpPointName')">Обязательное поле</tk-error>
			</tk-form-field>

			<tk-form-field tkAutocompleteOrigin>
				<tk-label>{{ t('delivery.address') }}</tk-label>
				<input
					(ngModelChange)="onChangeSearch()"
					autocomplete="off"
					type="text"
					tkInput
					formControlName="address"
					[tkAutocomplete]="autocomplete"
				/>
				<tk-error *ngIf="errors(form, 'address') && form.controls.address.hasError('required')"
					>Обязательное поле</tk-error
				>
				<tk-error *ngIf="form.controls.address.hasError('fias_id')">Выберите адрес из списка</tk-error>
				<tk-error *ngIf="form.controls.address.hasError('fias_house')"
					>Адрес должен содержать номер дома</tk-error
				>
				<tk-autocomplete #autocomplete="tkAutocomplete">
					<tk-option *ngFor="let item of fiasList" [value]="item.address" (click)="selectEvent(item)">{{
						item.address
					}}</tk-option>
				</tk-autocomplete>
			</tk-form-field>

			<tk-button-group>
				<button *ngIf="data.point" tkButton theme="secondary" tkDialogClose type="button">Отмена</button>
				<ng-container [ngSwitch]="loading">
					<button tkButton theme="primary" (click)="submit()" *ngSwitchCase="false" [disabled]="form.invalid">
						{{ data.point ? t('global.save') : t('delivery.create') }}
					</button>
					<tk-loader *ngSwitchCase="true"></tk-loader>
				</ng-container>
			</tk-button-group>
		</form>
	</tg-dialog-body>
</ng-container>
