import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs';
import { ColorResponse, ShopCreateRequest, ShopResponse } from '@models/generated/api';
import { Dialog } from '@angular/cdk/dialog';
import { ShopCreateComponent } from '@components/modals/shop-create/shop-create.component';
import { ShopDeleteComponent } from '@components/modals/shop-delete/shop-delete.component';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';
import { PaginatedResponse } from '@models/pagination.model';
import { ToastService } from '@services/toast.service';
import { StartVideoComponent } from '@components/modals/start-video/start-video.component';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TrialOverComponent } from '@components/modals/trial-over/trial-over.component';

@Component({
	selector: 'tg-shop-list',
	templateUrl: './shop-list.component.html',
	styleUrls: ['./shop-list.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
	host: { '(click)': 'onClick()' },
})
export class ShopListComponent implements OnInit {
	shopList?: PaginatedResponse<ShopResponse>;
	isSubscriptionActive: boolean;

	queryParams: any;

	constructor(
		private shopService: ShopService,
		private dialog: Dialog,
		private cdr: ChangeDetectorRef,
		public loading$: LoadingService,
		private destroy$: DestroyService,
		private toast: ToastService,
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.refresh();
		this.isSubscriptionActive = this.authService.subscriptionInfo().status;
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.shopService.utmQuery.next(params);
		});
	}

	private onClick() {
		if (this.authService.subscriptionInfo().status === false) {
			this.dialog.open(TrialOverComponent).closed.subscribe({
				next: (data: ColorResponse[]) => {
					data && this.router.navigate(['/tarifs']);
				},
			});
		}
	}

	refresh(): void {
		this.loading$.next(true);
		this.shopService
			.getShopList()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.shopList = res;
				},
			})
			.add(() => {
				this.cdr.markForCheck();
				this.loading$.next(false);
			});
	}

	createShop(): void {
		this.dialog
			.open<ShopCreateRequest>(ShopCreateComponent)
			.closed.pipe(
				filter(v => !!v),
				switchMap(data => this.shopService.createShop(data).pipe(tap(() => this.loading$.next(true)))),
			)
			.subscribe({
				next: () => {
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'store_created');
					this.toast.success('Магазин успешно создан!');
					this.refresh();
				},
				error: err => {
					this.toast.error(err.error?.message);
				},
				complete: () => {
					this.loading$.next(false);
				},
			});
	}

	editShop(shop: ShopResponse): void {
		this.dialog
			.open<ShopCreateRequest>(ShopCreateComponent, {
				data: {
					token: shop.botToken,
					shopName: shop.shopName,
					authorTelegramUserName: shop.authorTelegramUserName,
					minimumOrderSum: shop.minimumOrderSum,
					currency: shop.currency,
					productType: shop.productType,
				},
			})
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(v => !!v),
				switchMap(data => this.shopService.editShop(shop.id, data).pipe(tap(() => this.loading$.next(true)))),
			)
			.subscribe({
				next: () => {
					this.toast.success('Магазин успешно обновлен');
					this.refresh();
				},
				error: err => {
					this.toast.error(err?.error?.message);
				},
				complete: () => {
					this.loading$.next(false);
				},
			});
	}

	deleteShop(shop: ShopResponse): void {
		this.dialog
			.open(ShopDeleteComponent)
			.closed.pipe(
				takeUntil(this.destroy$),
				filter(Boolean),
				switchMap(() => this.shopService.deleteShop(shop.id).pipe(tap(() => this.loading$.next(true)))),
			)
			.subscribe({
				next: () => {
					this.refresh();
					// @ts-ignore
					ym(environment.metricId, 'reachGoal', 'store_deleted');
					this.toast.success('Магазин успешно удален');
				},
				error: err => {
					this.toast.error(err?.error?.message);
				},
				complete: () => {
					this.loading$.next(false);
				},
			});
	}

	openGuide(): void {
		window.open('https://tgshop.io/blog/instrukciya-kak-sozdat-magazin-v-telegram-po-shagam', '_blank');
	}
}
