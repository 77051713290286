import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormErrorModel } from '@components/ui/errors/form-error.model';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'tk-error[tkPasswordError]',
	templateUrl: './password-error.component.html',
	styleUrls: ['./password-error.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	inputs: ['errors'],
	imports: [CommonModule],
})
export class PasswordErrorComponent extends FormErrorModel {}
