/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Модель изменения состояния магазина
 */
export type StateShopRequest = {
	/**
	 * Новое состояние
	 */
	shopState: StateShopRequest.shopState;
};

export namespace StateShopRequest {
	/**
	 * Новое состояние
	 */
	export enum shopState {
		ACTIVE = 'ACTIVE',
		STOPPING = 'STOPPING',
		DISABLE = 'DISABLE',
	}
}
