import { HttpClient } from '@angular/common/http';
import {
	TRANSLOCO_LOADER,
	Translation,
	TranslocoLoader,
	TRANSLOCO_CONFIG,
	translocoConfig,
	TranslocoModule,
	TranslocoService,
} from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { LangService } from '@services/language.service';
import { AuthInterceptorService } from '@services/auth-interceptor.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private http: HttpClient, private langService: LangService) {}

	getTranslation(lang: string) {
		return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
	}
}

@NgModule({
	exports: [TranslocoModule],
	providers: [
		{
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: ['ru', 'en', 'uz'],
				defaultLang: 'ru',
				reRenderOnLangChange: true,
				prodMode: true,
			}),
		},
		{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
	],
})
export class TranslocoRootModule {
	constructor(
		private langService: LangService,
		private translocoService: TranslocoService,
		private authInterceptorService: AuthInterceptorService,
	) {
		if (this.langService.defaultLang) {
			this.authInterceptorService.lang = this.langService.defaultLang;
			this.translocoService.setActiveLang(this.langService.defaultLang);
		}
	}
}
