<ng-container *transloco="let t">
	<div class="header">
		<tg-breadcrumbs [exactMatch]="false"></tg-breadcrumbs>
	</div>

	<h2>{{ t('delivery.store_settings') }}</h2>

	<tg-tabs>
		<a *ngIf="!shopService.isDigitalShop()" routerLink="./delivery" routerLinkActive="active">
			{{ t('order_history.delivery') }}*
		</a>

		<a routerLink="./payment" [relativeTo]="route" routerLinkActive="active"> {{ t('order_history.payment') }}</a>

		<a routerLink="./atol" [relativeTo]="route" routerLinkActive="active"> Онлайн-касса </a>

		<ng-container *ngIf="!shopService.isDigitalShop()">
			<ng-container *ngIf="shopService.shopMoneySign | async">
				<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
					<a routerLink="./crm" [relativeTo]="route" routerLinkActive="active"> Интеграция с CRM </a>
				</ng-container>
			</ng-container>
		</ng-container>

		<a routerLink="./newsletter" routerLinkActive="active"> Мои рассылки </a>

		<a routerLink="./promo" routerLinkActive="active"> Промокоды </a>

		<a routerLink="./hello" routerLinkActive="active"> Приветственное сообщение </a>

		<a routerLink="./order-fields" routerLinkActive="active"> Заказ </a>
	</tg-tabs>

	<router-outlet></router-outlet>
</ng-container>
