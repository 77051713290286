import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { SizeTypeResponse } from '@models/generated/api';
import { BehaviorSubject } from 'rxjs';
import { ProductService } from '@services/product.service';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tg-shop-create',
	templateUrl: './product-select-type.component.html',
	styleUrls: ['./product-select-type.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectTypeComponent {
	sizeTypeList$ = new BehaviorSubject<SizeTypeResponse[]>([]);

	constructor(private dialogRef: DialogRef, private productService: ProductService, private toast: ToastService) {
		this.productService.getProductTypes().subscribe({
			next: data => {
				this.sizeTypeList$.next(data);
			},
			error: err => {
				this.toast.error(err.error?.message);
			},
		});
	}

	selectType(typeId: SizeTypeResponse): void {
		this.dialogRef.close(typeId);
	}
}
