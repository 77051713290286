import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	AddressSuggestionResponse,
	AtolInfoRequest,
	AtolInfoResponse,
	DeliveryOptionRequest,
	FlagRequest,
	PickUpPointRequest,
	PickUpPointResponse,
	ProductResponse,
	RetailInfoRequest,
	RetailInfoResponse,
	ShopCreateRequest,
	ShopResponse,
	MetricResponse,
	ProductTypeResponse,
	PaymentTbankDtoResponse,
	PaymentTelegramDtoResponse,
} from '@models/generated/api';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse } from '@models/pagination.model';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ShopDeliveryOptions } from '@models/ShopDeliveryOptions.interface';
import shopState = ShopResponse.shopState;
import { WithPagingParams } from '@models/paging.interface';
import productType = ProductTypeResponse.productType;

@Injectable({
	providedIn: 'root',
})
export class ShopService implements Resolve<ShopResponse> {
	shopMoneySign = new BehaviorSubject<string | null>(null);
	shopInfo = new BehaviorSubject<any>(null);
	utmQuery = new BehaviorSubject<any>(null);
	constructor(private http: HttpClient) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): ShopResponse | Observable<ShopResponse> | Promise<ShopResponse> {
		const id = route.params['id'];
		return this.getShop(id);
	}

	getShopList(): Observable<PaginatedResponse<ShopResponse>> {
		return this.http.get<PaginatedResponse<ShopResponse>>('shops');
	}

	setMoneySign(shop: ShopResponse) {
		switch (shop.currency) {
			case ShopResponse.currency.RUB: {
				this.shopMoneySign.next('₽');
				break;
			}
			case ShopResponse.currency.USD: {
				this.shopMoneySign.next('$');
				break;
			}
			case ShopResponse.currency.UZS: {
				this.shopMoneySign.next('Sʻ');
				break;
			}
		}
	}

	createShop(data: ShopCreateRequest | undefined): Observable<unknown> {
		return this.http.post('shops', data);
	}

	getShop(id: string): Observable<ShopResponse> {
		return this.http.get(`shops/${id}`);
	}

	isDigitalShop(): boolean {
		return this.shopInfo.value?.productType === productType.DIGITAL;
	}

	updateStatus(id: string | undefined, status: shopState | string): Observable<ShopResponse> {
		return this.http.put(`shops/${id}/state`, {
			shopState: status,
		});
	}

	editShop(id: string | undefined, shop: ShopCreateRequest | undefined): Observable<unknown> {
		return this.http.put(`shops/${id}`, {
			...shop,
		});
	}

	deleteShop(id: string | undefined): Observable<unknown> {
		return this.http.delete(`shops/${id}`);
	}

	getProducts(id: string | undefined, params: WithPagingParams, search: string): Observable<ProductResponse[]> {
		const opts: any = {
			...params,
		};

		if (search) {
			opts.search = search;
		}

		return this.http.get<ProductResponse[]>(`shops/${id}/products`, { params: opts });
	}

	getShopDeliveryOptions(shopId: string): Observable<ShopDeliveryOptions> {
		return this.http.get<ShopDeliveryOptions>(`shops/${shopId}/deliveries`);
	}

	updateShopDeliveryOption(
		shopId: string,
		deliveryOption: DeliveryOptionRequest.deliveryOption,
		isEnabled: boolean,
	): Observable<void> {
		return this.http.put<void>(`shops/${shopId}/deliveries`, {
			deliveryOption,
			isEnabled,
		});
	}

	setDeliveryData(shopId: string, deliveryOption: DeliveryOptionRequest.deliveryOption, data: any): Observable<void> {
		return this.http.put<void>(`shops/${shopId}/deliveries`, {
			deliveryOption,
			isEnabled: true,
			...data,
		});
	}

	getShopPickupPoints(shopId: string): Observable<PickUpPointResponse[]> {
		return this.http.get<PickUpPointResponse[]>(`shops/${shopId}/deliveries/pick-up-points`);
	}

	getShopOptionsAtol(shopId: string): Observable<AtolInfoResponse> {
		return this.http.get<AtolInfoResponse>(`shops/${shopId}/settings/atol`);
	}

	updateShopOptionsAtol(shopId: string, data: AtolInfoRequest): Observable<void> {
		return this.http.put<void>(`shops/${shopId}/settings/atol`, {
			...data,
		});
	}

	postShopOptionsAtol(shopId: string, data: AtolInfoRequest): Observable<void> {
		return this.http.post<void>(`shops/${shopId}/settings/atol`, {
			...data,
		});
	}

	getShopCrmRetail(shopId: string): Observable<RetailInfoResponse> {
		return this.http.get<RetailInfoResponse>(`shops/${shopId}/settings/retail`);
	}

	updateShopCrmRetail(shopId: string, data: RetailInfoRequest): Observable<void> {
		return this.http.put<void>(`shops/${shopId}/settings/retail`, {
			...data,
		});
	}

	postShopCrmRetail(shopId: string, data: RetailInfoRequest): Observable<void> {
		return this.http.post<void>(`shops/${shopId}/settings/retail`, {
			...data,
		});
	}

	updateShopCrmRetailActive(shopId: string, data: FlagRequest): Observable<void> {
		return this.http.put<void>(`shops/${shopId}/settings/retail/active`, {
			...data,
		});
	}

	addShopPickUpPoint(shopId: string, data: PickUpPointRequest, fiasData: AddressSuggestionResponse) {
		return this.http.post<void>(`shops/${shopId}/deliveries/pick-up-points`, {
			pickUpPointName: data.pickUpPointName,
			address: {
				fiasId: fiasData.fiasId,
				address: fiasData.address,
			},
		});
	}

	editShopPickUpPoint(
		shopId: string,
		pointId: string,
		data: PickUpPointRequest,
		fiasData: AddressSuggestionResponse,
	) {
		return this.http.put<void>(`shops/${shopId}/deliveries/pick-up-points/${pointId}`, {
			pickUpPointName: data.pickUpPointName,
			address: {
				addressId: pointId,
				fiasId: fiasData.fiasId,
				address: fiasData.address,
			},
		});
	}

	removeShopPickUpPoint(shopId: string, pointId: string) {
		return this.http.delete(`shops/${shopId}/deliveries/pick-up-points/${pointId}`);
	}

	getShopPaymentToken(shopId: string): Observable<PaymentTelegramDtoResponse> {
		return this.http.get<PaymentTelegramDtoResponse>(`shops/${shopId}/settings/payments/telegram`);
	}

	updateShopPaymentToken(
		shopId: string,
		vatCode: string,
		paymentToken?: string,
	): Observable<PaymentTelegramDtoResponse> {
		const data: any = {
			vatCode,
			paymentToken,
		};

		return this.http.put<PaymentTelegramDtoResponse>(`shops/${shopId}/settings/payments/telegram`, data);
	}

	deleteUkassaPaymentData(shopId: string): Observable<any> {
		return this.http.delete<any>(`shops/${shopId}/settings/payments/telegram`);
	}

	getTinkoffPaymentData(shopId: string): Observable<any> {
		return this.http.get<any>(`shops/${shopId}/settings/payments/tinkoff`);
	}

	setTinkoffPaymentData(shopId: string, data: any): Observable<PaymentTbankDtoResponse> {
		return this.http.put<PaymentTbankDtoResponse>(`shops/${shopId}/settings/payments/tinkoff`, data);
	}

	deleteTinkoffPaymentData(shopId: string): Observable<any> {
		return this.http.delete<any>(`shops/${shopId}/settings/payments/tinkoff`);
	}

	createCustomPayment(shopId: string, data: any) {
		return this.http.post<void>(`shops/${shopId}/settings/payments/custom`, data);
	}

	editCustomPayment(shopId: string, paymentId: string, data: any) {
		return this.http.put<void>(`shops/${shopId}/settings/payments/custom/${paymentId}`, data);
	}

	getCustomPaymentList(shopId: string): Observable<any> {
		return this.http.get<void>(`shops/${shopId}/settings/payments/custom`);
	}

	deleteCustomPayment(shopId: string, paymentId: string) {
		return this.http.delete<void>(`shops/${shopId}/settings/payments/custom/${paymentId}`);
	}

	checkToken(data: { token: string }) {
		return this.http.post<void>(`shops/token`, data);
	}

	getMetrics(shopId: string) {
		return this.http.get<MetricResponse>(`shops/${shopId}/metrics`);
	}

	getPeriodMetrics(shopId: string, data: { dateFrom: string; dateTo: string }) {
		return this.http.get<MetricResponse>(`shops/${shopId}/metrics/search`, { params: { ...data } });
	}

	getOrderFields(shopId: string) {
		return this.http.get<MetricResponse>(`shops/${shopId}/settings/order-fields`);
	}

	postOrderField(shopId: string, fieldType: string) {
		return this.http.post<void>(`shops/${shopId}/settings/order-fields`, {
			shopOrderFieldType: fieldType,
			required: true,
			minLength: 3,
			maxLength: 75,
		});
	}

	editOrderField(shopId: string, orderFieldId: string, fieldType: string, enabled: boolean, required: boolean) {
		return this.http.put<void>(`shops/${shopId}/settings/order-fields/${orderFieldId}`, {
			shopOrderFieldType: fieldType,
			enabled: enabled,
			required: required,
			minLength: 3,
			maxLength: 75,
		});
	}

	deleteOrderField(shopId: string, orderFieldId: string) {
		return this.http.delete(`shops/${shopId}/settings/order-fields/${orderFieldId}`);
	}
}
