<h2>Мои подписки</h2>
<ng-container *ngFor="let subscription of subscriptionData">
	<div class="subscription">
		<div class="subscription__head">
			<h3>{{ subscription.planName }}</h3>
			<div
				class="status"
				[ngClass]="[
					subscription.status === 'ACTIVE' ? 'status--active' : '',
					subscription.status === 'FINISHED' ? 'status--disable' : '',
					subscription.status === 'AWAIT_PAY' ? 'status--stopping' : ''
				]"
			>
				{{ subscription.status === 'ACTIVE' ? 'Активная' : '' }}
				{{ subscription.status === 'FINISHED' ? 'Завершена' : '' }}
				{{ subscription.status === 'AWAIT_PAY' ? 'Ожидает оплаты' : '' }}
			</div>
		</div>
		<p>Подписка активна с {{ subscription.startDate | date : 'dd.MM.yyyy HH:mm' }}</p>
		<p>Действует до {{ subscription.finishDate | date : 'dd.MM.yyyy HH:mm' }}</p>
		<p>Сумма списания {{ subscription.paymentSum }} руб.</p>

		<div class="controls">
			<div (click)="openHistory()" class="controls__history">История оплат</div>
			<div *ngIf="subscription.recurrent" (click)="cancelSubscription(subscription.id)" class="controls__cansel">
				Отменить подписку
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="subscriptionData.length === 0"> Нет активных подписок </ng-container>
