<ng-container *transloco="let t; read: 'global'">
	<div class="pagination">
		<ng-container *ngIf="showPagination">
			<button
				(click)="emitStep(currentPage - 1)"
				class="pagination__navigation"
				[class.pagination__navigation--disabled]="!page"
				data-test-id="btn-pagination-prev"
			>
				<tk-icon icon="chevron-right" size="16" [class.flip]="true"></tk-icon>
				{{ t('prev') }}
			</button>

			<div class="page-list">
				<button
					*ngFor="let pageStep of pages"
					(click)="emitStep(pageStep - 1)"
					class="page-list__item"
					[class.active]="pageStep - 1 === page"
					[attr.data-test-id]="'btn-page-' + pageStep"
				>
					{{ pageStep }}
				</button>
			</div>

			<button
				(click)="emitStep(currentPage + 1)"
				class="pagination__navigation"
				[class.pagination__navigation--disabled]="!lastStep"
				data-test-id="btn-next"
			>
				{{ t('next') }}
				<tk-icon icon="chevron-right" size="16"></tk-icon>
			</button>
		</ng-container>
	</div>
</ng-container>
