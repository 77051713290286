<ng-container *transloco="let t; read: 'categories'">
	<tg-dialog-header>
		{{ t(DIALOG_TITLE_KEY[dialogData.mode]) }}
	</tg-dialog-header>
	<tg-dialog-body #dialogBody>
		<ng-container [ngSwitch]="dialogData.mode">
			<!-- new or edit root -->
			<tk-form-field
				*ngSwitchCase="dialogData.mode === 'new-root' || dialogData.mode === 'edit-root' ? dialogData.mode : ''"
			>
				<tk-label>
					{{ t('edit_modal_edit_root_label') }}
				</tk-label>

				<input type="text" height="48" tkInput [formControl]="formControl" />
			</tk-form-field>
			<!-- new or edit child -->
			<ng-container
				*ngSwitchCase="
					dialogData.mode === 'new-child' || dialogData.mode === 'edit-child' ? dialogData.mode : ''
				"
			>
				<tk-form-field *ngFor="let parentCategory of dialogData.parentCategories; index as idx">
					<tk-label *ngIf="idx === 0">
						{{ t('edit_modal_edit_root_label_second') }}
					</tk-label>
					<tk-label *ngIf="idx !== 0">
						{{ t('edit_modal_edit_child_label', { number: idx }) }}
					</tk-label>

					<input type="text" height="48" tkInput [disabled]="true" [value]="parentCategory.categoryName" />
				</tk-form-field>
				<tk-form-field>
					<tk-label>
						{{ t('edit_modal_edit_child_label', { number: dialogData.parentCategories.length }) }}
					</tk-label>

					<input #subcategoryInput type="text" height="48" tkInput [formControl]="formControl" />
				</tk-form-field>
			</ng-container>
		</ng-container>
	</tg-dialog-body>
	<tg-dialog-footer>
		<tk-button-group>
			<button tkButton theme="secondary" size="xl" tk-dialog-close>
				{{ t('edit_modal_cancel_btn') }}
			</button>
			<button tkButton size="xl" [disabled]="!formControl.value" (click)="submit()">
				{{ t(DIALOG_SUBMIT_TEXT_KEY[dialogData.mode]) }}
			</button>
		</tk-button-group>
	</tg-dialog-footer>
</ng-container>
